import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  ProductInput,
  ProductType,
  StatusEnum,
  SubTariffType,
  useEventQuery,
  useSubTariffsListQuery,
} from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  useModal,
} from '@/shared';
import { RouterName } from '@app/router';
import { EventForm, EventInputSteps, useUpdateEvent } from '@entities/Event';
import { Divider, LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';
import { EventTariffsController } from '@widgets/Tariff';

export interface EventEditViewProps {
  params: {
    uuid: string;
  };
  query: {
    readonly?: boolean;
  };
}

export const EventEditView: FC<EventEditViewProps> = ({
  params: { uuid },
  query,
}) => {
  const openPublishModal = useModal('publishEvent');
  const { navigate } = useNavigate();
  const [updateEvent, { error, loading: saveLoading }] = useUpdateEvent();
  const [eventData, setEventData] = useState<ProductInput>();

  const { data, loading, refetch } = useEventQuery({
    variables: {
      uuid,
    },
  });
  const { data: subTariffs, loading: loadingTariffs } = useSubTariffsListQuery({
    fetchPolicy: 'network-only',
    variables: {
      productUuid: uuid,
      status: [StatusEnum.Draft, StatusEnum.Active],
    },
  });
  const product = data?.product;
  const isDraft = product?.status === StatusEnum.Draft;
  const isArchive = product?.status === StatusEnum.Archive;
  const isReadonly = !!query.readonly || isArchive;

  const onClose = () => {
    navigate(RouterName.AdminEvents);
  };
  const onPublish = () => {
    openPublishModal({ uuid });
  };
  const onSave = async () => {
    if (!eventData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateEvent({
      input: eventData,
      uuid,
    });
    refetch();
  };

  const actions: ActionButton[] = isReadonly
    ? [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: () => navigate(RouterName.AdminEventEdit, { uuid }),
          label: 'Редактировать',
        },
      ]
    : [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: onSave,
          label: isDraft ? 'Сохранить черновик' : 'Сохранить изменения',
        },
        ...(isDraft
          ? [
              {
                handler: onPublish,
                label: 'Опубликовать',
              },
            ]
          : []),
      ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title={product?.name || ''} onBack={onClose} />
        <EventInputSteps data={product} />
      </Container>
      {isArchive ? (
        <Divider />
      ) : (
        <>
          <Spacer size={8} />
          <ActionButtons actions={actions} />
        </>
      )}
      <Spacer size={20} />
      <Container slim>
        {data && (
          <EventForm
            initValues={data.product as ProductType}
            onChange={setEventData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
            readonly={isReadonly}
            tariffsSlot={
              !loadingTariffs && (
                <EventTariffsController
                  eventData={data.product}
                  disabled={isReadonly}
                  initValues={
                    (subTariffs?.subTariffsList.rows || []) as SubTariffType[]
                  }
                />
              )
            }
          />
        )}
      </Container>
    </>
  );
};
