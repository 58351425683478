import React, { FC, useEffect, useMemo, useState } from 'react';

import {
  UserGroupType,
  UserType,
  useUsersByGroupListQuery,
} from '@/apolloGenerated';
import { ActionButtons, FormSection, useModal, useOrganizerId } from '@/shared';
import {
  ContactListTabs,
  ContactTabs,
  ContactTabsEnum,
} from '@entities/Contact';
import { ContactTable, ContactTableHeader } from '@features/Contact';
import { useUsersList } from '@features/Contact/libs/hooks/useUsersList';
import { Spacer, TextField } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export interface GroupSettings {
  name: string;
  users: UserGroupType['uuid'][];
}

interface ContactGroupEditProps {
  curName: UserGroupType['name'];
  uuid: UserGroupType['uuid'];
  onClose: () => void;
  onSave: (args: GroupSettings) => void;
}

export const ContactGroupEdit: FC<ContactGroupEditProps> = ({
  curName,
  onClose,
  onSave,
  uuid,
}) => {
  const organizerId = useOrganizerId()!;
  const [name, setName] = useState<string>(curName);
  const [tab, setTab] = useState<ContactTabs>(ContactTabsEnum.All);
  const [value, setValue] = useState<UserType[]>([]);

  const valueUuids = useMemo(() => value.map((el) => el.uuid), [value]);

  const openRemoveGroupModal = useModal('removeUserGroup');

  const {
    data: usersByGroup,
    error: usersByGroupError,
    refetch: refetchUsersByGroup,
  } = useUsersByGroupListQuery({
    skip: !organizerId,
    variables: {
      filters: {},
      groupUuid: uuid,
      organizerId,
    },
  });

  const { data, error, fetchAll, loading, onUpdatePayload, payload, refetch } =
    useUsersList({
      tab,
    });

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: () => openRemoveGroupModal({ name, onSuccess: onClose, uuid }),
      label: 'Удалить группу',
    },
    {
      handler: () => onSave({ name, users: valueUuids }),
      label: 'Сохранить группу',
    },
  ];

  const onRefetch = () => {
    error && refetch();
    usersByGroupError && refetchUsersByGroup();
  };

  useEffect(() => {
    setValue((usersByGroup?.usersByGroup?.rows as UserType[]) || []);
  }, [usersByGroup]);

  return (
    <div>
      <Head title="Редактирование группы" onBack={onClose} />
      <ActionButtons actions={actions} />
      <Spacer size={8} />
      <TextField
        label="Название группы"
        size="large"
        fullWidth
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Spacer size={8} />
      <FormSection title={`Список пользователей (${valueUuids.length})`}>
        <ContactListTabs
          initTab={tab}
          onChange={setTab}
          hideBlocked
          hideGroups
          hideCreateGroupButton
        />
        <Spacer size={8} />
        <ContactTable
          hasError={!!error || !!usersByGroupError}
          data={(data?.rows as UserType[]) || []}
          total={data?.count || 0}
          payload={payload}
          tab={tab}
          headers={[
            ContactTableHeader.Check,
            ContactTableHeader.Icon,
            ContactTableHeader.Name,
            ContactTableHeader.Data,
          ]}
          fetchAllUsers={() => fetchAll()}
          loading={loading}
          onChangePayload={onUpdatePayload}
          selected={value}
          onChangeSelect={setValue}
          onRefetch={onRefetch}
        />
      </FormSection>
    </div>
  );
};
