import React, { FC, useState } from 'react';

import {
  BaseTariffType,
  ProductType,
  SubTariffInput,
  SubTariffType,
} from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService } from '@/shared';
import {
  BaseTariffPicker,
  getTariffInitState,
  SubTariffForm,
  useCreateTariff,
} from '@entities/Tariff';
import { LoaderOverlay, Maybe, Spacer } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface TariffCreateProps {
  eventData: ProductType;
  onClose(): void;
  onSuccess(val: SubTariffType): void;
}
export const TariffCreate: FC<TariffCreateProps> = ({
  eventData,
  onClose,
  onSuccess,
}) => {
  const [createTariff, { error, loading: saveLoading }] = useCreateTariff(
    (tariffData) => onSuccess(tariffData),
  );
  const [parentTariff, setParentTariff] = useState<Maybe<BaseTariffType>>();
  const [tariffData, setTariffData] = useState<SubTariffInput>();

  const handleSubmit = () => {
    if (parentTariff?.uuid) {
      createTariff(
        {
          input: tariffData!,
        },
        parentTariff.uuid,
        eventData.uuid,
      );
    }
  };

  const handleClose = () => {
    if (parentTariff) {
      return setParentTariff(null);
    }
    onClose();
  };

  return (
    <div>
      <LoaderOverlay show={saveLoading} />
      <Head
        title={parentTariff ? 'Новый тариф' : 'Выберите базовый тариф'}
        onBack={handleClose}
      />
      {parentTariff && (
        <ActionButtons
          actions={[
            { handler: handleClose, label: 'Выбрать базовый тариф' },
            { handler: handleSubmit, label: 'Создать тариф' },
          ]}
        />
      )}
      <Spacer size={10} />
      {!parentTariff && <BaseTariffPicker onChange={setParentTariff} />}
      {parentTariff && (
        <SubTariffForm
          eventData={eventData}
          initValues={getTariffInitState() as SubTariffType}
          baseValues={parentTariff}
          onChange={setTariffData}
          errors={
            ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
              ?.validationErrors
          }
        />
      )}
    </div>
  );
};
