import { FC } from 'react';

import { Container } from '@/shared';
import { RouterName } from '@app/router';
import { Button, PlusDenseIcon } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';
import { BaseTariffsLists } from '@widgets/Tariff';

export const BaseTariffsView: FC = () => (
  <div>
    <Container>
      <Head
        title="Базовые тарифы"
        rightSlot={
          <Button
            to={RouterName.AdminBaseTariffCreate}
            prependIcon={PlusDenseIcon}>
            Создать тариф
          </Button>
        }
      />
    </Container>
    <BaseTariffsLists />
  </div>
);
