import React, { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import { useDuplicateProductMutation } from '@/apolloGenerated';
import { RouterName } from '@app/router';
import { toaster, Typography } from '@letsdance/ui-kit';

export const useDuplicateEvent = () => {
  const { navigate } = useNavigate();
  const [duplicateProduct, { client, data, error }] =
    useDuplicateProductMutation();

  useEffect(() => {
    if (data?.duplicateProduct) {
      toaster.success({
        contentSlot: (
          <Typography variant="body-14" color="on-surface-primary-2">
            Дублированное событие находится в списке черновиков
          </Typography>
        ),
        title: 'Событие успешно дублировано',
      });
      client.cache.evict({ fieldName: 'productsCount' });
      client.cache.evict({ fieldName: 'productsList' });
      navigate(RouterName.AdminEventEdit, { uuid: data.duplicateProduct.uuid });
    }
    if (error) {
      toaster.error({ title: 'Ошибка дублирования события' });
    }
  }, [data, error]);

  return (uuid: string) => duplicateProduct({ variables: { uuid } });
};
