import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';
import moment from 'moment-timezone';

import {
  Maybe,
  OrdersListQuery,
  OrderSortKeys,
  OrderStatusEnum,
  OrderType,
  useOrdersListQuery,
} from '@/apolloGenerated';
import {
  ActiveIcon,
  calculateProfit,
  formatAmount,
  formatSort,
  renderCellItemValue,
  timezone,
  useOrganizerId,
} from '@/shared';
import { RouterName } from '@app/router';
import { OrderEventInfoCell } from '@entities/Purchases';
import { isSuccessPayment } from '@entities/Purchases/libs/utils';
import { OrderControl } from '@features/Orders';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

const headers = [
  { hide: true, key: true, value: 'id' },
  { sortable: true, title: 'Покупка', value: 'createdAt' },
  { title: 'Событие', value: 'event' },
  { title: 'Пользователь', value: 'user' },
  { title: 'Парный билет', value: 'pairUser' },
  { title: 'Потрачено, ₽ ', value: 'price' },
  { title: 'Потрачено, Баллы ', value: 'bonus' },
  { sortable: true, title: 'Активация', value: 'visitedAt' },
  { title: '', value: 'settings', width: 64 },
];
const pageSize = 10;
const defaultPage = 1;

export interface PurchasesOrdersTableProps {
  uuidProduct: Maybe<string>;
  status: OrderStatusEnum[];
}
export const PurchasesOrdersTable: FC<PurchasesOrdersTableProps> = ({
  status,
  uuidProduct,
}) => {
  const { urlFor } = useNavigate();
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(defaultPage);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrderSortKeys>(OrderSortKeys.CreatedAt);

  useEffect(() => {
    setPage(defaultPage);
  }, [status, uuidProduct]);

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useOrdersListQuery({
    skip: !organizerId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      organizerId,
      status,
      uuidProduct,
    },
  });
  const data = curData || previousData;

  const rowTemplate = (order: OrdersListQuery['ordersList']['rows'][0]) => {
    const paymentAmounts = isSuccessPayment(order.payment?.status)
      ? calculateProfit(order.payment?.price, order.fixedHoldPercentage)
      : null;
    const bonusAmounts = order.userBalanceWithdrawal?.amount
      ? calculateProfit(
          order.userBalanceWithdrawal?.amount,
          order.fixedHoldPercentage,
        )
      : null;
    const pairUser = order.pairOrder?.user;

    return {
      bonus: bonusAmounts?.profit ? formatAmount(bonusAmounts.profit) : '-',
      createdAt: renderCellItemValue({
        label: `ID: ${order.id}`,
        value: moment(order.createdAt).tz(timezone).format('DD MMM YYYY HH:mm'),
      }),
      event: <OrderEventInfoCell order={order as OrderType} />,
      id: String(order.id),
      pairUser: pairUser
        ? renderCellItemValue({
            label: `${pairUser.last_name || ''} ${pairUser.first_name}`,
            onClick: () =>
              window.open(
                urlFor(RouterName.AdminContactEdit, { uuid: pairUser.uuid }),
                '_blank',
              ),
            value: pairUser.phone || pairUser.email,
          })
        : '-',
      price: paymentAmounts?.profit ? formatAmount(paymentAmounts.profit) : '-',
      settings:
        order.status === OrderStatusEnum.Paid ? (
          <OrderControl id={order.id} />
        ) : (
          ' '
        ),
      user: renderCellItemValue({
        label: `${order.user.last_name || ''} ${order.user.first_name}`,
        onClick: () =>
          window.open(
            urlFor(RouterName.AdminContactEdit, { uuid: order.user.uuid }),
            '_blank',
          ),
        value: order.user.phone || order.user.email,
      }),
      visitedAt: (
        <ActiveIcon
          style={{
            opacity:
              order.status === OrderStatusEnum.CompletedAndVisited ? 1 : 0,
          }}
        />
      ),
    };
  };
  const items = useMemo(
    () => (data?.ordersList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        total={data?.ordersList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrderSortKeys);
        }}
        onRefetch={error && refetch}
      />
    </div>
  );
};
