import { useEffect, useMemo, useState } from 'react';

import {
  UserType,
  useUsersBlockedListLazyQuery,
  useUsersBlockedListQuery,
  useUsersByGroupListLazyQuery,
  useUsersByGroupListQuery,
  useUsersListLazyQuery,
  useUsersListQuery,
  useUsersRegisteredListLazyQuery,
  useUsersRegisteredListQuery,
  useUsersSpecialListLazyQuery,
  useUsersSpecialListQuery,
} from '@/apolloGenerated';
import { formatSort, useOrganizerId } from '@/shared';
import { ApolloError } from '@apollo/client';
import { ContactTabs, ContactTabsEnum } from '@entities/Contact';
import { ContactTablePayload, defaultContactPayload } from '@features/Contact';
import { Maybe, MaybeNotDefined } from '@letsdance/ui-kit';

interface FetchInfo {
  error: MaybeNotDefined<ApolloError>;
  loading: boolean;
  refetch: () => void;
}

export const useUsersList = (
  { productUuid, tab }: { tab: ContactTabs; productUuid?: Maybe<string> },
  initPayload?: Partial<ContactTablePayload>,
) => {
  const [payload, setPayload] = useState<ContactTablePayload>({
    ...defaultContactPayload,
    ...initPayload,
  });

  useEffect(() => {
    setPayload({ ...defaultContactPayload, ...initPayload });
  }, [tab]);

  const organizerId = useOrganizerId()!;
  const filters = useMemo(
    () => ({
      order: { sort: formatSort(payload.sort), sortBy: payload.sortBy },
      pagination: { page: payload.page, pageSize: payload.pageSize },
      search: payload.filter.search,
    }),
    [payload],
  );
  const getBaseSkip = (...tabs: ContactTabs[]) =>
    !organizerId || !tabs.includes(tab!);
  const baseParams = useMemo(
    () => ({ organizerId, productUuid }),
    [organizerId, productUuid],
  );

  // Lazy
  const [fetchUsers] = useUsersListLazyQuery();
  const [fetchSpecialUsers] = useUsersSpecialListLazyQuery();
  const [fetchRegisteredUsers] = useUsersRegisteredListLazyQuery();
  const [fetchBlockedUsers] = useUsersBlockedListLazyQuery();
  const [fetchGroupUsers] = useUsersByGroupListLazyQuery();

  // Data query
  const resultUsers = useUsersListQuery({
    skip: getBaseSkip(ContactTabsEnum.All, ContactTabsEnum.Active),
    variables: {
      filters,
      isActive: tab === ContactTabsEnum.Active,
      ...baseParams,
    },
  });

  const resultSpecial = useUsersSpecialListQuery({
    skip: getBaseSkip(ContactTabsEnum.Special),
    variables: {
      filters,
      ...baseParams,
    },
  });
  const resultRegistered = useUsersRegisteredListQuery({
    skip: getBaseSkip(ContactTabsEnum.Registered),
    variables: {
      filters,
      ...baseParams,
    },
  });
  const resultBlocked = useUsersBlockedListQuery({
    skip: getBaseSkip(ContactTabsEnum.Blocked),
    variables: {
      filters,
      ...baseParams,
    },
  });
  const resultGroup = useUsersByGroupListQuery({
    skip: !organizerId || Object.values(ContactTabsEnum).includes(tab!),
    variables: {
      filters,
      groupUuid: tab!,
      ...baseParams,
    },
  });

  const data = useMemo(() => {
    if (!getBaseSkip(ContactTabsEnum.Special)) {
      return (
        resultSpecial.data?.specialUsers ||
        resultSpecial.previousData?.specialUsers
      );
    }
    if (!getBaseSkip(ContactTabsEnum.Blocked)) {
      return (
        resultBlocked.data?.blacklist || resultBlocked.previousData?.blacklist
      );
    }
    if (!getBaseSkip(ContactTabsEnum.Registered)) {
      return (
        resultRegistered.data?.users || resultRegistered.previousData?.users
      );
    }
    if (!getBaseSkip(ContactTabsEnum.Active, ContactTabsEnum.All)) {
      return resultUsers.data?.users || resultUsers.previousData?.users;
    }

    return (
      resultGroup.data?.usersByGroup || resultGroup.previousData?.usersByGroup
    );
  }, [
    tab,
    resultSpecial,
    resultUsers,
    resultBlocked,
    resultGroup,
    resultRegistered,
  ]);

  const fetchAll = async (): Promise<UserType[]> => {
    if (!getBaseSkip(ContactTabsEnum.Special)) {
      return ((await fetchSpecialUsers({ variables: baseParams })).data
        ?.specialUsers.rows || []) as UserType[];
    }
    if (!getBaseSkip(ContactTabsEnum.Blocked)) {
      return ((await fetchBlockedUsers({ variables: baseParams })).data
        ?.blacklist.rows || []) as UserType[];
    }
    if (!getBaseSkip(ContactTabsEnum.Registered)) {
      return ((await fetchRegisteredUsers({ variables: baseParams })).data
        ?.users.rows || []) as UserType[];
    }
    if (!getBaseSkip(ContactTabsEnum.Active)) {
      return ((
        await fetchUsers({
          variables: { isActive: true, ...baseParams },
        })
      ).data?.users.rows || []) as UserType[];
    }
    if (!getBaseSkip(ContactTabsEnum.All)) {
      return ((
        await fetchUsers({
          variables: { isActive: false, ...baseParams },
        })
      ).data?.users.rows || []) as UserType[];
    }

    return ((
      await fetchGroupUsers({
        variables: { filters: {}, groupUuid: tab!, ...baseParams },
      })
    ).data?.usersByGroup.rows || []) as UserType[];
  };

  const getFetchInfo = (): FetchInfo => {
    switch (tab) {
      case ContactTabsEnum.All || ContactTabsEnum.Active: {
        return {
          error: resultUsers.error,
          loading: resultUsers.loading,
          refetch: resultUsers.refetch,
        };
      }
      case ContactTabsEnum.Registered: {
        return {
          error: resultRegistered.error,
          loading: resultRegistered.loading,
          refetch: resultRegistered.refetch,
        };
      }
      case ContactTabsEnum.Special: {
        return {
          error: resultSpecial.error,
          loading: resultSpecial.loading,
          refetch: resultSpecial.refetch,
        };
      }
      case ContactTabsEnum.Blocked: {
        return {
          error: resultBlocked.error,
          loading: resultBlocked.loading,
          refetch: resultBlocked.refetch,
        };
      }
      default: {
        return {
          error: resultGroup.error,
          loading: resultGroup.loading,
          refetch: resultGroup.refetch,
        };
      }
    }
  };

  const curActions = getFetchInfo();

  return {
    data,
    fetchAll,
    onUpdatePayload: setPayload,
    payload,
    ...curActions,
  };
};
