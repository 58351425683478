import { FC, PropsWithChildren } from 'react';

import { MeQuery, RoleEnum, useMeQuery } from '@/apolloGenerated';
import { isOrganizer, LogoIcon } from '@/shared';
import { HomeRouteName, RouterName } from '@app/router';
import {
  BagIcon,
  BookmarkIcon,
  DocumentIcon,
  EditIcon,
  Header as HeaderUI,
  NavItem,
  useRouterPath,
  UserSquareIcon,
  WalletIcon,
} from '@letsdance/ui-kit';

import { ProfileControl } from '../ProfileControl/ProfileControl';

export interface HeaderProps {
  user?: MeQuery['me'];
  dense?: boolean;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ dense, user }) => {
  const { checkActivePath } = useRouterPath();
  const { data } = useMeQuery();

  return (
    <HeaderUI
      dense={dense}
      homeLink={HomeRouteName[data?.me.role || RoleEnum.Organizer]}
      logoSlot={<LogoIcon color="var(--neutrals-1)" />}
      navigationSlot={
        <>
          <NavItem
            to={RouterName.AdminProfile}
            icon={EditIcon}
            active={checkActivePath(RouterName.AdminProfile)}
            fullWidth
            hideLabel={dense}>
            Профиль
          </NavItem>
          <NavItem
            to={RouterName.AdminEvents}
            icon={BookmarkIcon}
            active={checkActivePath(RouterName.AdminEvents)}
            fullWidth
            hideLabel={dense}>
            Вечеринки
          </NavItem>
          <NavItem
            to={RouterName.AdminBaseTariffs}
            icon={DocumentIcon}
            active={checkActivePath(RouterName.AdminBaseTariffs)}
            fullWidth
            hideLabel={dense}>
            Тарифы
          </NavItem>
          <NavItem
            to={RouterName.AdminContact}
            icon={UserSquareIcon}
            active={checkActivePath(RouterName.AdminContact)}
            fullWidth
            hideLabel={dense}>
            Контакты
          </NavItem>
          <NavItem
            to={RouterName.AdminPurchases}
            icon={BagIcon}
            active={checkActivePath(RouterName.AdminPurchases)}
            fullWidth
            hideLabel={dense}>
            Билеты
          </NavItem>
          {isOrganizer(user?.role) && (
            <NavItem
              to={RouterName.AdminFinance}
              icon={WalletIcon}
              active={checkActivePath(RouterName.AdminFinance)}
              fullWidth
              hideLabel={dense}>
              Финансы
            </NavItem>
          )}
        </>
      }
      bottomSlot={<ProfileControl />}
    />
  );
};
