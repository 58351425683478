import React, { FC, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { StatusEnum } from '@/apolloGenerated';
import { useModal } from '@/shared';
import { RouterName } from '@app/router';
import { useDuplicateBaseTariff } from '@entities/Tariff';
import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  MoreIcon,
  Paper,
  stopEvent,
} from '@letsdance/ui-kit';

interface EventControlProps {
  uuid: string;
  status: StatusEnum;
}

export const BaseTariffControl: FC<EventControlProps> = ({ status, uuid }) => {
  const { navigate } = useNavigate();
  const openRemoveModal = useModal('removeBaseTariff');
  const duplicateTariff = useDuplicateBaseTariff();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChangeDropdown = (val: boolean) => {
    setIsOpen(val);
  };

  const eventCallback = (callback: () => void) => (e: MouseEvent) => {
    setIsOpen(false);
    stopEvent(e);
    callback();
  };

  const onEdit = () => {
    navigate(RouterName.AdminBaseTariffEdit, { uuid });
  };

  return (
    <Dropdown
      value={isOpen}
      targetSlot={
        <Button
          icon
          prependIcon={MoreIcon}
          variant="text"
          size="large"
          onClick={stopEvent}
        />
      }
      placement="bottom-end"
      width={260}
      zIndex={1000}
      onChange={handleChangeDropdown}>
      <Paper px={4} py={4} radius={6} className="paper-list">
        <DropdownItem
          fullWidth
          disabled={status === StatusEnum.Archive}
          onClick={eventCallback(onEdit)}
          label="Редактировать"
        />
        <DropdownItem
          fullWidth
          onClick={eventCallback(() => duplicateTariff(uuid))}
          label="Дублировать"
        />
        {status === StatusEnum.Active && (
          <>
            <Divider className="divider-list" />
            <DropdownItem
              fullWidth
              onClick={eventCallback(() => openRemoveModal({ uuid }))}
              label="Удалить"
            />
          </>
        )}
      </Paper>
    </Dropdown>
  );
};
