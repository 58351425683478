import { ProductType, StatusEnum } from '@/apolloGenerated';

export const sortEventsByStartDate = <
  T extends Pick<ProductType, 'startedDate' | 'status'>,
>(
  events: T[],
) => {
  const currentDate = Date.now();
  const sortEvents = (status: StatusEnum) =>
    [...events]
      .filter((el) => el.status === status)
      .sort((a, b) => {
        const diffA = Math.abs(+a.startedDate - currentDate);
        const diffB = Math.abs(+b.startedDate - currentDate);

        return diffA - diffB;
      });

  return [
    ...sortEvents(StatusEnum.Active).reverse(),
    ...sortEvents(StatusEnum.Archive),
  ];
};
