import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { BaseTariffInput } from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService, Container } from '@/shared';
import { RouterName } from '@app/router';
import {
  BaseTariffForm,
  BaseTariffInputSteps,
  getBaseTariffInitState,
  useAccessTariffUsers,
  useCreateBaseTariff,
} from '@entities/Tariff';
import { ContactTariffAccessSelect } from '@features/Contact';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export const BaseTariffCreateView: FC = () => {
  const { navigate } = useNavigate();
  const [createBaseTariff, { data: createData, error, loading }] =
    useCreateBaseTariff();
  const [updateUserAccessTariff, { loading: saveAccessLoading }] =
    useAccessTariffUsers();
  const [tariffData, setTariffData] = useState<BaseTariffInput>();
  const [usersAccessUuids, setUserAccessUuids] = useState<string[]>([]);

  const onClose = () => {
    navigate(RouterName.AdminBaseTariffs);
  };
  const onCreate = async () => {
    if (!tariffData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await createBaseTariff({
      input: tariffData,
    });
  };

  useEffect(() => {
    if (createData) {
      updateUserAccessTariff({
        userUuids: usersAccessUuids,
        uuid: createData.createBaseTariff.uuid,
      });
    }
  }, [createData]);

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: () => onCreate(),
      label: 'Создать тариф',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading || saveAccessLoading} />
      <Container slim>
        <Head title="Новая вечеринка" onBack={onClose} />
        <BaseTariffInputSteps data={tariffData} />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        <BaseTariffForm
          initValues={getBaseTariffInitState()}
          onChange={setTariffData}
          errors={
            ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
              ?.validationErrors
          }
        />
        <ContactTariffAccessSelect onChange={setUserAccessUuids} />
      </Container>
    </>
  );
};
