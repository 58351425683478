import { FC, useEffect } from 'react';

import { ProductInviteTextsInput } from '@/apolloGenerated';
import { TextareaField } from '@/shared';
import { useForm } from '@letsdance/ui-kit';

export interface EventInviteTextsFormProps {
  initData: ProductInviteTextsInput;
  onChange: (data: ProductInviteTextsInput) => void;
}
export const EventInviteTextsForm: FC<EventInviteTextsFormProps> = ({
  initData,
  onChange,
}) => {
  const { onChange: onChangeValue, values } = useForm<ProductInviteTextsInput>(
    console.log,
    initData,
  );

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div className="grid-2">
      <TextareaField
        label="Текст пришедшего приглашения"
        name="inviteOrderText"
        initValue={values.inviteOrderText!}
        onChange={onChangeValue}
      />
      <TextareaField
        label="Текст успешного принятия приглашения"
        name="inviteOrderSuccessText"
        initValue={values.inviteOrderSuccessText!}
        onChange={onChangeValue}
      />
    </div>
  );
};
