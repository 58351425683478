import { Redirect } from 'react-justanother-router';

import { RoleEnum } from '@/apolloGenerated';
import { loadUser } from '@shared/libs/utils/auth';

import { Role, RouterName } from './types';

export const HomeRouteName: Record<RoleEnum, RouterName> = {
  [RoleEnum.Admin]: RouterName.AdminProfile,
  [RoleEnum.Organizer]: RouterName.AdminProfile,
  [RoleEnum.User]: RouterName.Home,
};
const getUserRole = (): Role => {
  const user = loadUser();

  if (user) {
    return Role.USER;
  }

  return Role.GHOST;
};

export const routeMiddleware = (
  roleAccess: Role | undefined,
): JSX.Element | void => {
  const userRole = getUserRole();

  if (roleAccess === userRole) {
    return;
  }
  switch (roleAccess) {
    case Role.USER: {
      return <Redirect to={RouterName.Authorization} />;
    }
    case Role.GHOST: {
      return <Redirect to={RouterName.AdminEvents} />;
    }
  }
};
