import { useEffect } from 'react';

import {
  CreateTariffMutation,
  CreateTariffMutationVariables,
  useCreateTariffMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

import { getTariffInput } from './getTariffInput';

export const useCreateTariff = (
  onSuccess?: (data: CreateTariffMutation['createTariff']) => void,
): [
  (
    vars: Pick<CreateTariffMutationVariables, 'input'>,
    uuidParentTariff: string,
    uuidProduct?: string,
  ) => Promise<void>,
  MutationResult<CreateTariffMutation>,
] => {
  const [createTariff, result] = useCreateTariffMutation();

  const onCreateTariff = async (
    { input }: Pick<CreateTariffMutationVariables, 'input'>,
    uuidParentTariff: string,
    uuidProduct?: string,
  ) => {
    createTariff({
      variables: {
        input: getTariffInput(input),
        uuidParentTariff,
        uuidProduct,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      const { client } = result;

      client.cache.evict({
        fieldName: 'tariffsList',
      });
      client.cache.evict({ fieldName: 'tariffs' });
      toaster.success({ title: 'Тариф успешно создан' });
      onSuccess && onSuccess(result.data.createTariff);
    }
  }, [result.data, result.error]);

  return [onCreateTariff, result];
};
