import React, { FC } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface ListPickerRemoveModal {
  title: string;
  description?: string;
  onSubmit(): void;
}
declare global {
  interface ModalProps {
    listPickerRemove: ListPickerRemoveModal;
  }
}
export const ListPickerRemoveModal: FC<
  WithCloseModal<ListPickerRemoveModal>
> = ({ description, onClose, onSubmit, title }): JSX.Element => (
  <Dialog
    title={title}
    closed
    width={500}
    footerSlot={
      <DialogFooter
        fullWidth
        endSlot={
          <div className="grid-2">
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
            <Button
              fullWidth
              onClick={() => {
                onSubmit();
                onClose();
              }}>
              Удалить
            </Button>
          </div>
        }
      />
    }>
    {description && (
      <Typography variant="body-16" color="on-surface-primary-1">
        {description}
      </Typography>
    )}
  </Dialog>
);
