import { useEffect } from 'react';

import {
  UpdateEventMutation,
  UpdateEventMutationVariables,
  useUpdateEventMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { getEventInput } from '@entities/Event/libs/hooks/getEventInput';
import { toaster } from '@letsdance/ui-kit';

export const useUpdateEvent = (): [
  (vars: UpdateEventMutationVariables) => Promise<void>,
  MutationResult<UpdateEventMutation>,
] => {
  const [updateEvent, result] = useUpdateEventMutation();

  const onUpdateEvent = async ({
    input,
    uuid,
  }: UpdateEventMutationVariables) => {
    await updateEvent({
      variables: {
        input: getEventInput(input),
        uuid,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      toaster.success({ title: 'Событие успешно обновлено' });
      result.client.cache.evict({
        args: { uuid: result.data.updateProduct.uuid },
        fieldName: 'product',
      });
      result.client.cache.evict({ fieldName: 'productsList' });
    }
  }, [result.data, result.error]);

  return [onUpdateEvent, result];
};
