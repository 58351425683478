import {
  BaseTariffInput,
  BaseTariffType,
  StatusEnum,
  TariffTypeEnum,
} from '@/apolloGenerated';

export const getBaseTariffInput = ({
  description,
  featureFlags: {
    bonusPay,
    refundOrder,
    repeatBuyOrder,
    transferToProductOrder,
    transferToUserOrder,
  },
  name,
  ordersCount,
  price,
  query,
  type,
  visible,
}: BaseTariffInput): BaseTariffInput => ({
  description,
  featureFlags: {
    bonusPay,
    refundOrder,
    repeatBuyOrder,
    transferToProductOrder,
    transferToUserOrder,
  },
  name,
  ordersCount,
  price,
  query,
  type,
  visible,
});

export const getBaseTariffInitState = (): BaseTariffType => ({
  countSubTariffs: 0,
  createdAt: Date.now(),
  description: null,
  featureFlags: {
    bonusPay: false,
    refundOrder: false,
    repeatBuyOrder: false,
    transferToProductOrder: false,
    transferToUserOrder: false,
  },
  name: '',
  ordersCount: null,
  price: 0,
  query: null,
  status: StatusEnum.Active,
  type: TariffTypeEnum.AnyGender,
  updatedAt: Date.now(),
  uuid: '',
  visible: true,
});
