import { FC } from 'react';

import { InputStep, InputSteps } from '@/shared';

export interface BaseTariffInputStepsProps {
  data?: any;
}
export const BaseTariffInputSteps: FC<BaseTariffInputStepsProps> = () => {
  const items: InputStep[] = [
    {
      active: true,
      label: 'Основная информация',
    },
    {
      active: true,
      label: 'Фичи',
    },
    {
      active: true,
      label: 'Доступы',
    },
  ];

  return <InputSteps items={items} />;
};
