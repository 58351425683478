import { FC } from 'react';

import { InputStep, InputSteps } from '@/shared';

export interface OrganizerInputStepsProps {
  data?: any;
}
export const OrganizerInputSteps: FC<OrganizerInputStepsProps> = () => {
  const items: InputStep[] = [
    {
      active: true,
      label: 'Основная информация',
    },
    {
      active: true,
      label: 'Социальные сети',
    },
    {
      active: true,
      label: 'Платёжная информация',
    },
    {
      active: true,
      label: 'Инструкции',
    },
  ];

  return <InputSteps items={items} />;
};
