import {
  BaseTextsKeysEnum,
  ProductInput,
  ProductType,
  useBaseEventTextsQuery,
} from '@/apolloGenerated';

export const getEventInput = ({
  activeAcquiring,
  address,
  description,
  endDate,
  faq,
  featureFlags: {
    allowedNumberForTransferToProductOrder,
    durationBeforeStartForRefundOrder,
    durationBeforeStartForTransferToUserOrder,
  },
  img,
  invoiceLifetime,
  manualBuyText,
  name,
  preview: { active, backButtonText, description: descriptionPreview, url },
  query,
  route: {
    active: activeRoute,
    backButtonText: backButtonTextRoute,
    description: descriptionRoute,
    url: urlRoute,
  },
  salesStartedDate,
  salesStartedText,
  startedDate,
  successBuyText,
  tariffsEmptyText,
  tariffsText,
  tariffsUnavailableText,
  transferOrderToUserSuccessText,
}: ProductInput): ProductInput => ({
  activeAcquiring,
  address,
  description,
  endDate,
  faq,
  featureFlags: {
    allowedNumberForTransferToProductOrder,
    durationBeforeStartForRefundOrder,
    durationBeforeStartForTransferToUserOrder,
  },
  img,
  invoiceLifetime,
  manualBuyText,
  name,
  preview: {
    active,
    backButtonText,
    description: descriptionPreview,
    url,
  },
  query,
  route: {
    active: activeRoute,
    backButtonText: backButtonTextRoute,
    description: descriptionRoute,
    url: urlRoute,
  },
  salesStartedDate,
  salesStartedText,
  startedDate,
  successBuyText,
  tariffsEmptyText,
  tariffsText,
  tariffsUnavailableText,
  transferOrderToUserSuccessText,
});

export const useEventInitState = () => {
  const { data, loading } = useBaseEventTextsQuery();

  const getByKey = (key: BaseTextsKeysEnum) =>
    data?.baseTexts.find((el) => el.key === key)?.text;

  return {
    getEventInitState: (): ProductInput & Pick<ProductType, 'tariffs'> => ({
      activeAcquiring: true,
      address: null,
      description: '',
      endDate: new Date().toISOString(),
      faq: '',
      featureFlags: {
        allowedNumberForTransferToProductOrder: 3,
        durationBeforeStartForRefundOrder: 'P0D',
        durationBeforeStartForTransferToUserOrder: 'P0D',
      },
      img: '',
      invoiceLifetime: 'PT1H',
      manualBuyText: null,
      name: '',
      preview: {
        active: false,
        backButtonText: null,
        description: null,
        url: null,
      },
      query: null,
      route: {
        active: false,
        backButtonText: null,
        description: null,
        url: null,
      },
      salesStartedDate: null,
      salesStartedText: getByKey(BaseTextsKeysEnum.ProductSalesStartedText),
      startedDate: new Date().toISOString(),
      successBuyText: getByKey(BaseTextsKeysEnum.ProductSuccessBuyText),
      tariffs: [],
      tariffsEmptyText: getByKey(BaseTextsKeysEnum.ProductTariffsEmptyText),
      tariffsText: getByKey(BaseTextsKeysEnum.ProductTariffsText),
      tariffsUnavailableText: getByKey(
        BaseTextsKeysEnum.ProductTariffsUnavailableText,
      ),
      transferOrderToUserSuccessText: getByKey(
        BaseTextsKeysEnum.ProductTransferOrderToUserSuccessText,
      ),
    }),
    loading,
  };
};
