import { KeyboardEvent } from 'react';

export const handleNumberInput = (event: KeyboardEvent<HTMLInputElement>) => {
  if (!/\d/.test(event.key)) {
    event.preventDefault();
  }
};

export const handleQueryInput = (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.key !== 'Backspace' && !/^\w$/.test(event.key)) {
    event.preventDefault();
  }
};
