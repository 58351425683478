import { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import {
  ProductSortKeys,
  SortEnum,
  StatusEnum,
  useEventsListQuery,
} from '@/apolloGenerated';
import { timezone, useOrganizerId } from '@/shared';
import { sortEventsByStartDate } from '@features/Event/libs/utils';
import { Chip, Maybe } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventPickerProps {
  initValue?: Maybe<string>;
  onChange(value: Maybe<string>): void;
  status?: StatusEnum[];
  hideAll?: boolean;
  autoSetFirst?: boolean;
  chipPosition?: 'start' | 'end';
}
export const EventPicker: FC<EventPickerProps> = ({
  autoSetFirst,
  chipPosition = 'start',
  hideAll,
  initValue,
  onChange,
  status = [StatusEnum.Active, StatusEnum.Archive],
}) => {
  const organizerId = useOrganizerId()!;
  const { data } = useEventsListQuery({
    skip: !organizerId,
    variables: {
      filters: {
        order: { sort: SortEnum.Desc, sortBy: ProductSortKeys.StartedDate },
      },
      params: {
        organizerId,
      },
      status,
    },
  });
  const [value, setValue] = useState<Maybe<string>>(initValue || null);

  const handleChange = (val: Maybe<string>) => {
    setValue(val);
    onChange(val);
  };

  const items = useMemo(
    () => sortEventsByStartDate(data?.productsList.rows || []),
    [data],
  );

  useEffect(() => {
    if (autoSetFirst && !initValue && items.length > 0) {
      handleChange(items[0].uuid);
    }
  }, [items]);

  return (
    <div className={styles.eventPicker}>
      {chipPosition === 'start' && !hideAll && (
        <Chip onClick={() => handleChange(null)} active={!value}>
          Все вечеринки
        </Chip>
      )}

      {items.map((el) => (
        <Chip
          key={el.uuid}
          onClick={() => handleChange(el.uuid)}
          active={value === el.uuid}>
          {el.name} · {moment(el.startedDate).tz(timezone).format('DD.MM.YYYY')}
        </Chip>
      ))}

      {chipPosition === 'end' && !hideAll && (
        <Chip onClick={() => handleChange(null)} active={!value}>
          Все вечеринки
        </Chip>
      )}
    </div>
  );
};
