import { gql } from '@apollo/client';

export const organizer = gql`
  query organizer($id: Int!) {
    organizer(id: $id) {
      createdAt
      updatedAt
      deletedAt
      id
      image
      name
      query
      description
      telegram
      socials(disableShortcode: true) {
        image
        text
        vk
        telegram
        facebook
        youtube
        instagram
      }
      supplierInfo {
        name
        inn
        phone
      }
      orderInstructions {
        transferToProduct
        transferToUser
        refund
      }
    }
  }
`;

export const organizerFiles = gql`
  query organizerFiles($id: Int!) {
    organizer(id: $id) {
      files {
        oferta
        agent
        policy
      }
    }
  }
`;

export const updateOrganizer = gql`
  mutation updateOrganizer($id: Float!, $input: OrganizerInput!) {
    updateOrganizer(id: $id, input: $input) {
      id
    }
  }
`;

export const organizerShortcodes = gql`
  query organizerShortcodes {
    organizerShortcodes
  }
`;
