import {
  OrdersCountQueryVariables,
  OrderStatusEnum,
  useOrdersCountQuery,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { Maybe } from '@letsdance/ui-kit';

export enum OrdersTabs {
  All = 'All',
  Active = 'Active',
  Expired = 'Expired',
  Transferred = 'Transferred',
  Activated = 'Activated',
  Refund = 'Refund',
  Deactivated = 'Deactivated',
}
export interface UseOrdersCountsParams {
  uuidProduct?: Maybe<string>;
  uuidUser?: Maybe<string>;
}
export const getOrdersStatus = (tab: OrdersTabs) => {
  switch (tab) {
    case OrdersTabs.All: {
      return [
        OrderStatusEnum.Paid,
        OrderStatusEnum.CompletedAndNotVisited,
        OrderStatusEnum.CompletedAndVisited,
        OrderStatusEnum.Refunded,
      ];
    }
    case OrdersTabs.Active: {
      return [OrderStatusEnum.Paid];
    }
    case OrdersTabs.Activated: {
      return [OrderStatusEnum.CompletedAndVisited];
    }
    case OrdersTabs.Expired: {
      return [OrderStatusEnum.CompletedAndNotVisited];
    }
    case OrdersTabs.Transferred: {
      return [OrderStatusEnum.TransferredToProduct];
    }
    case OrdersTabs.Refund: {
      return [OrderStatusEnum.Refunded];
    }
    case OrdersTabs.Deactivated: {
      return [OrderStatusEnum.Deactivated];
    }
  }
};

export const useOrdersCounts = (params?: UseOrdersCountsParams) => {
  const organizerId = useOrganizerId();

  const getReqOptions = (
    status: OrderStatusEnum[],
  ): { variables: OrdersCountQueryVariables; skip: boolean } => ({
    skip: !organizerId,
    variables: {
      organizerId: organizerId!,
      status,
      uuidProduct: params?.uuidProduct,
      uuidUser: params?.uuidUser,
    },
  });
  const { data: ordersList } = useOrdersCountQuery(
    getReqOptions(getOrdersStatus(OrdersTabs.All)),
  );
  const { data: ordersListActive } = useOrdersCountQuery(
    getReqOptions(getOrdersStatus(OrdersTabs.Active)),
  );
  const { data: ordersListExpired } = useOrdersCountQuery(
    getReqOptions(getOrdersStatus(OrdersTabs.Expired)),
  );
  const { data: ordersListRefund } = useOrdersCountQuery(
    getReqOptions(getOrdersStatus(OrdersTabs.Refund)),
  );
  const { data: ordersListActivated } = useOrdersCountQuery(
    getReqOptions(getOrdersStatus(OrdersTabs.Activated)),
  );
  const { data: ordersListTransferred } = useOrdersCountQuery(
    getReqOptions(getOrdersStatus(OrdersTabs.Transferred)),
  );
  const { data: ordersListDeactivated } = useOrdersCountQuery(
    getReqOptions(getOrdersStatus(OrdersTabs.Deactivated)),
  );

  return {
    activated: ordersListActivated?.ordersList.count || 0,
    active: ordersListActive?.ordersList.count || 0,
    all: ordersList?.ordersList.count || 0,
    deactivated: ordersListDeactivated?.ordersList.count || 0,
    expired: ordersListExpired?.ordersList.count || 0,
    refund: ordersListRefund?.ordersList.count || 0,
    transferred: ordersListTransferred?.ordersList.count || 0,
  };
};
