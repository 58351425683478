import { useEffect } from 'react';

import {
  UpdateBaseTariffMutation,
  UpdateBaseTariffMutationVariables,
  useUpdateBaseTariffMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { getBaseTariffInput } from '@entities/Tariff';
import { toaster } from '@letsdance/ui-kit';

export const useUpdateBaseTariff = (): [
  (vars: UpdateBaseTariffMutationVariables) => Promise<void>,
  MutationResult<UpdateBaseTariffMutation>,
] => {
  const [updateBaseTariff, result] = useUpdateBaseTariffMutation();

  const onUpdateTariff = async ({
    input,
    uuid,
  }: UpdateBaseTariffMutationVariables) => {
    await updateBaseTariff({
      variables: {
        input: getBaseTariffInput(input),
        uuid,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      toaster.success({ title: 'Тариф успешно обновлено' });
      result.client.cache.evict({
        args: { uuid: result.data.updateBaseTariff.uuid },
        fieldName: 'baseTariff',
      });
      result.client.cache.evict({
        fieldName: 'baseTariffsList',
      });
      result.client.cache.evict({
        fieldName: 'subTariffsList',
      });
      result.client.cache.evict({
        fieldName: 'subTariff',
      });
    }
  }, [result.data, result.error]);

  return [onUpdateTariff, result];
};
