import { FC, useContext, useEffect } from 'react';

import { useExportOrdersCsvMutation } from '@/apolloGenerated';
import { PurchasesContext, PurchasesContextType } from '@entities/Purchases';
import { Button, ExportDenseIcon, toaster } from '@letsdance/ui-kit';

export const PurchasesExportCsvButton: FC = () => {
  const { productUuid } = useContext<PurchasesContextType>(PurchasesContext);
  const [exportCsv, { data, error, loading }] = useExportOrdersCsvMutation();
  const handleExportCsv = () => {
    if (!productUuid) {
      return;
    }
    exportCsv({
      variables: {
        productUuid,
      },
    });
  };

  useEffect(() => {
    if (data) {
      window.open(data.exportOrdersCSV.url);
    }
    if (error) {
      toaster.error({ title: 'Ошибка выгрузки отчёта' });
    }
  }, [data, error]);

  if (!productUuid) {
    return <></>;
  }

  return (
    <Button
      prependIcon={ExportDenseIcon}
      loading={loading}
      onClick={() => handleExportCsv()}
      style={{ minWidth: 130 }}>
      Экспорт CSV
    </Button>
  );
};
