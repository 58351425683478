import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  BaseTariffsListQuery,
  BaseTariffSortKeysEnum,
  Maybe,
  StatusEnum,
  useBaseTariffsListQuery,
} from '@/apolloGenerated';
import {
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { RouterName } from '@app/router';
import { TariffTypeTranslate } from '@entities/Tariff/const';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

import { BaseTariffControl } from '../BaseTariffControl/BaseTariffControl';

const headers = [
  { hide: true, key: true, value: 'uuid' },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 180 },
  { sortable: true, title: 'Дата обновления', value: 'updatedAt', width: 180 },
  { sortable: true, title: 'Название', value: 'name' },
  { title: 'Стоимость, ₽', value: 'price' },
  { title: 'Кол-во билетов', value: 'ordersCount' },
  { title: ' ', value: 'settings', width: 48 },
];
const pageSize = 15;

export interface BaseTariffsTableProps {
  status: Maybe<StatusEnum>;
}

export const BaseTariffsTable: FC<BaseTariffsTableProps> = ({ status }) => {
  const { navigate } = useNavigate();
  const idOrganizer = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<BaseTariffSortKeysEnum>(
    BaseTariffSortKeysEnum.UpdatedAt,
  );

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useBaseTariffsListQuery({
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      idOrganizer,
      status: status ? [status] : null,
    },
  });
  const data = curData || previousData;
  const rowTemplate = (
    tariff: BaseTariffsListQuery['baseTariffsList']['rows'][0],
  ) => ({
    createdAt: renderCellDate({ timestamp: tariff.createdAt }),
    name: renderCellItemValue({
      label: tariff.name,
      value: `${TariffTypeTranslate[tariff.type]}${tariff.visible ? '' : ' (Скрытый)'}`,
    }),
    ordersCount: String(tariff.ordersCount || '-'),
    price: formatAmount(tariff.price),
    settings: <BaseTariffControl status={tariff.status} uuid={tariff.uuid} />,
    updatedAt: renderCellDate({ timestamp: tariff.updatedAt }),
    uuid: tariff.uuid,
  });

  const items = useMemo(
    () => (data?.baseTariffsList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        total={data?.baseTariffsList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as BaseTariffSortKeysEnum);
        }}
        onClickRow={(e, event) =>
          navigate(RouterName.AdminBaseTariffEdit, { uuid: event.uuid })
        }
        notResetPage
        onRefetch={error && refetch}
      />
    </div>
  );
};
