import { FC } from 'react';

import { useFinanceStatsQuery } from '@/apolloGenerated';
import { StatItem, useOrganizerId } from '@/shared';
import { Divider } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface FinanceStatsProps {
  filterSlot?: JSX.Element;
  exportSlot?: JSX.Element;
}
export const FinanceStats: FC<FinanceStatsProps> = ({
  exportSlot,
  filterSlot,
}) => {
  const organizerId = useOrganizerId()!;
  const { data } = useFinanceStatsQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const stats = data?.financeStats;
  const balance = data?.organizer.balance || 0;
  const orderCount = data?.paymentsList.count || 0;
  const commission = stats?.totalCommission || 0;
  const totalWithdrawals = stats?.totalWithdrawals || 0;
  const totalReceived = stats?.totalReceived || 0;
  const profit = stats?.profit || 0;

  return (
    <div className={styles.financeStats}>
      <div className={styles.financeStats__totalBlock}>
        <StatItem
          label="Текущий баланс, ₽"
          value={balance}
          format
          fixed={false}
        />
        <Divider />
        <StatItem label="Покупок" value={orderCount} />
      </div>
      <div className={styles.financeStats__statsBlock}>
        {filterSlot && (
          <div className={styles.financeStats__filterWrap}>
            {filterSlot}
            {exportSlot}
          </div>
        )}

        <div className={styles.financeStats__statsWrap}>
          <StatItem
            label="Поступило, ₽"
            value={totalReceived}
            format
            fixed={false}
          />
          <Divider vertical />
          <StatItem
            label="В т. ч. комиссия, ₽"
            value={commission}
            format
            fixed={false}
          />
          <Divider vertical />
          <StatItem label="Прибыль, ₽" value={profit} format fixed={false} />
          <Divider vertical />
          <StatItem
            label="Выведено, ₽"
            value={totalWithdrawals}
            format
            fixed={false}
          />
        </div>
      </div>
    </div>
  );
};
