import { GenderEnum } from '@/apolloGenerated';
import { Maybe } from '@letsdance/ui-kit';

export const getReadableGender = (gender: Maybe<GenderEnum | undefined>) => {
  if (gender === GenderEnum.Male) {
    return 'Мужчина';
  }
  if (gender === GenderEnum.Female) {
    return 'Женщина';
  }

  return '-';
};
