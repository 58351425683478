import { FC } from 'react';
import cx from 'classnames';

import { PaymentStatusEnum } from '@/apolloGenerated';

import { PAYMENT_STATUS } from '../../libs/const';

import styles from './styles.module.scss';

export interface PaymentStatusCellProps {
  status: PaymentStatusEnum;
}
export const PaymentStatusCell: FC<PaymentStatusCellProps> = ({ status }) => (
  <div className={cx(styles.paymentStatus, styles[`paymentStatus_${status}`])}>
    {PAYMENT_STATUS[status]}
  </div>
);
