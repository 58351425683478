import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;

/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: number; output: number };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: { input: string; output: string };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian
   * calendar.This scalar is serialized to a string in ISO 8601 format and parsed
   * from a string in ISO 8601 format.
   */
  DateTimeISO: { input: number; output: number };
  /**
   * A field whose value conforms to the standard internet email address format as
   * specified in HTML Spec:
   * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address.
   */
  EmailAddress: { input: string; output: string };
  /**
   * A string representing a duration conforming to the ISO8601 standard,
   * such as: P1W1DT13H23M34S
   * P is the duration designator (for period) placed at the start of the duration representation.
   * Y is the year designator that follows the value for the number of years.
   * M is the month designator that follows the value for the number of months.
   * W is the week designator that follows the value for the number of weeks.
   * D is the day designator that follows the value for the number of days.
   * T is the time designator that precedes the time components of the representation.
   * H is the hour designator that follows the value for the number of hours.
   * M is the minute designator that follows the value for the number of minutes.
   * S is the second designator that follows the value for the number of seconds.
   *
   * Note the time designator, T, that precedes the time value.
   *
   * Matches moment.js, Luxon and DateFns implementations
   * ,/. is valid for decimal places and +/- is a valid prefix
   */
  ISO8601Duration: { input: any; output: any };
  /**
   * A field whose value conforms to the standard E.164 format as specified in:
   * https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234.
   */
  PhoneNumber: { input: string; output: string };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File };
  /** Represents NULL values */
  Void: { input: any; output: any };
}

export interface BaseGraphModel {
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
}

export interface BaseTariffInput {
  description?: InputMaybe<Scalars['String']['input']>;
  featureFlags: TariffFeatureFlagsInput;
  name: Scalars['String']['input'];
  ordersCount?: InputMaybe<Scalars['Float']['input']>;
  price: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  type?: TariffTypeEnum;
  visible: Scalars['Boolean']['input'];
}

export interface BaseTariffListFilterInput {
  order?: InputMaybe<BaseTariffSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<BaseTariffSearchType>;
}

export enum BaseTariffSearchKeysEnum {
  Description = 'description',
  Name = 'name',
  Type = 'type',
}

export interface BaseTariffSearchType {
  search: Scalars['String']['input'];
  searchBy: BaseTariffSearchKeysEnum;
}

export enum BaseTariffSortKeysEnum {
  CreatedAt = 'createdAt',
  Name = 'name',
  OrdersCount = 'ordersCount',
  Price = 'price',
  Query = 'query',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Visible = 'visible',
}

export interface BaseTariffSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: BaseTariffSortKeysEnum;
}

export type BaseTariffType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'BaseTariffType';
    countSubTariffs: Scalars['Int']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    featureFlags: TariffFeatureFlagsType;
    name: Scalars['String']['output'];
    ordersCount?: Maybe<Scalars['Float']['output']>;
    price: Scalars['Float']['output'];
    query?: Maybe<Scalars['String']['output']>;
    status: StatusEnum;
    type: TariffTypeEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
    visible: Scalars['Boolean']['output'];
  };

export interface BaseTariffsResponse {
  __typename?: 'BaseTariffsResponse';
  count: Scalars['Int']['output'];
  rows: BaseTariffType[];
}

export enum BaseTextsKeysEnum {
  OrganizerOrderInstructionsRefundText = 'OrganizerOrderInstructionsRefundText',
  OrganizerOrderInstructionsTransferToProductText = 'OrganizerOrderInstructionsTransferToProductText',
  OrganizerOrderInstructionsTransferToUserText = 'OrganizerOrderInstructionsTransferToUserText',
  OrganizerSocialText = 'OrganizerSocialText',
  ProductInviteOrderSuccessText = 'ProductInviteOrderSuccessText',
  ProductInviteOrderText = 'ProductInviteOrderText',
  ProductSalesStartedText = 'ProductSalesStartedText',
  ProductSuccessBuyText = 'ProductSuccessBuyText',
  ProductTariffsEmptyText = 'ProductTariffsEmptyText',
  ProductTariffsText = 'ProductTariffsText',
  ProductTariffsUnavailableText = 'ProductTariffsUnavailableText',
  ProductTransferOrderToUserSuccessText = 'ProductTransferOrderToUserSuccessText',
}

export interface BaseTextsType {
  __typename?: 'BaseTextsType';
  key: BaseTextsKeysEnum;
  text: Scalars['String']['output'];
}

export interface CreateAdminUserInput {
  organizerId: Scalars['Int']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
}

export interface CreateInvoiceInput {
  uuidPairUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidTariff: Scalars['UUID']['input'];
}

export interface CreateInvoiceType {
  __typename?: 'CreateInvoiceType';
  order: OrderType;
  payment?: Maybe<PaymentType>;
}

export interface ErrorType {
  __typename?: 'ErrorType';
  errors: Errors;
}

export enum Errors {
  AcquiringTurnOff = 'AcquiringTurnOff',
  AlreadyExecuted = 'AlreadyExecuted',
  AlreadyHasInvite = 'AlreadyHasInvite',
  AlreadyPaid = 'AlreadyPaid',
  CanActivateOnlyDraftItem = 'CanActivateOnlyDraftItem',
  CanArchiveOnlyActiveItem = 'CanArchiveOnlyActiveItem',
  CanNotAddArchiveTariffForTransfer = 'CanNotAddArchiveTariffForTransfer',
  CanNotAddLimitedTariffForTransfer = 'CanNotAddLimitedTariffForTransfer',
  CanNotAddNotAnyGenderTariffForTransfer = 'CanNotAddNotAnyGenderTariffForTransfer',
  CanNotChangeUserInfo = 'CanNotChangeUserInfo',
  CanNotClearCurrentUser = 'CanNotClearCurrentUser',
  CanNotCreateOrderWithDifferentGender = 'CanNotCreateOrderWithDifferentGender',
  CanNotEditAdminUser = 'CanNotEditAdminUser',
  CanNotTransferOrderUserWithDifferentGender = 'CanNotTransferOrderUserWithDifferentGender',
  CanRemoveOnlyDraftItem = 'CanRemoveOnlyDraftItem',
  CouldNotRefreshToken = 'CouldNotRefreshToken',
  EmailAlreadyConfirmed = 'EmailAlreadyConfirmed',
  EventNotBelongOrganizer = 'EventNotBelongOrganizer',
  FailedConfirmEmail = 'FailedConfirmEmail',
  FailedGetPayment = 'FailedGetPayment',
  FailedInitPayment = 'FailedInitPayment',
  FailedRemovePaymentAccount = 'FailedRemovePaymentAccount',
  FailedSendMail = 'FailedSendMail',
  FailedUpdatePayment = 'FailedUpdatePayment',
  FailedUploadFile = 'FailedUploadFile',
  FeatureDisabled = 'FeatureDisabled',
  IncorrectOrderStatusForAction = 'IncorrectOrderStatusForAction',
  InsufficientFundsOnBalance = 'InsufficientFundsOnBalance',
  InsufficientFundsOnUserBalance = 'InsufficientFundsOnUserBalance',
  InvalidAccessToken = 'InvalidAccessToken',
  InvalidAuthData = 'InvalidAuthData',
  InvalidCode = 'InvalidCode',
  InvalidFileType = 'InvalidFileType',
  MaxTransfersOrderReached = 'MaxTransfersOrderReached',
  NotFound = 'NotFound',
  NotFoundAccessToken = 'NotFoundAccessToken',
  NotPassedFullSupplierInfo = 'NotPassedFullSupplierInfo',
  NotPassedOrderForDepositUserBalance = 'NotPassedOrderForDepositUserBalance',
  NotSendCode = 'NotSendCode',
  OrderIsNotPaid = 'OrderIsNotPaid',
  OrderNotFound = 'OrderNotFound',
  OrderWithEmptyAmount = 'OrderWithEmptyAmount',
  OrganizerNotFound = 'OrganizerNotFound',
  PairUserNotFound = 'PairUserNotFound',
  PairUserNotPassed = 'PairUserNotPassed',
  PairUserWithSameGender = 'PairUserWithSameGender',
  PaymentAccountNotFound = 'PaymentAccountNotFound',
  PaymentNotActive = 'PaymentNotActive',
  PaymentNotFound = 'PaymentNotFound',
  PermissionDenied = 'PermissionDenied',
  ProductAlreadyHaveThisStatus = 'ProductAlreadyHaveThisStatus',
  ProductIsArchived = 'ProductIsArchived',
  ProductIsNotActive = 'ProductIsNotActive',
  ProductNotFound = 'ProductNotFound',
  RepeatOrderTurnOff = 'RepeatOrderTurnOff',
  SalesNotStarted = 'SalesNotStarted',
  TariffForTransferNotFound = 'TariffForTransferNotFound',
  TariffIsUnavailable = 'TariffIsUnavailable',
  TariffNotActive = 'TariffNotActive',
  TariffNotFound = 'TariffNotFound',
  UnknownError = 'UnknownError',
  UserAlreadyBlocked = 'UserAlreadyBlocked',
  UserAlreadyHaveAccessTariff = 'UserAlreadyHaveAccessTariff',
  UserAlreadyHaveNotAccessTariff = 'UserAlreadyHaveNotAccessTariff',
  UserAlreadyHaveOrder = 'UserAlreadyHaveOrder',
  UserAlreadyMemberInGroup = 'UserAlreadyMemberInGroup',
  UserAlreadySpecial = 'UserAlreadySpecial',
  UserBalanceNotFound = 'UserBalanceNotFound',
  UserGroupNotFound = 'UserGroupNotFound',
  UserNotBlocked = 'UserNotBlocked',
  UserNotFound = 'UserNotFound',
  UserNotFoundInSpecial = 'UserNotFoundInSpecial',
  UserNotMemberInGroup = 'UserNotMemberInGroup',
  UserNotRegistered = 'UserNotRegistered',
  UserWithThisEmailAlreadyExist = 'UserWithThisEmailAlreadyExist',
  UserWithThisTokenNotFound = 'UserWithThisTokenNotFound',
}

export interface ExportOrdersCsvType {
  __typename?: 'ExportOrdersCSVType';
  url: Scalars['String']['output'];
}

export interface FileType {
  __typename?: 'FileType';
  url: Scalars['String']['output'];
}

export interface FilterUsersInput {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isRegistered?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface FinanceStatsType {
  __typename?: 'FinanceStatsType';
  profit: Scalars['Float']['output'];
  totalCommission: Scalars['Float']['output'];
  totalReceived: Scalars['Float']['output'];
  totalWithdrawals: Scalars['Float']['output'];
}

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male',
}

export interface GiveOrderResultType {
  __typename?: 'GiveOrderResultType';
  error?: Maybe<Errors>;
  id?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Boolean']['output'];
  uuidUser: Scalars['UUID']['output'];
}

export interface JwtType {
  __typename?: 'JwtType';
  access_token: Scalars['String']['output'];
  refresh_token: Scalars['String']['output'];
}

export enum LanguageEnum {
  English = 'English',
  Russian = 'Russian',
}

export interface LoginInput {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface LoginTgInput {
  password: Scalars['String']['input'];
  tgId: Scalars['Float']['input'];
}

export interface Mutation {
  __typename?: 'Mutation';
  /** Method for activating an order by organizer on event */
  activateOrder: Scalars['Void']['output'];
  activateProduct: Scalars['Void']['output'];
  addPaymentAccount: PaymentAccountType;
  addUserBalance: Scalars['Boolean']['output'];
  addUserOrganizer: Scalars['Void']['output'];
  addUserToBlacklist: Scalars['Void']['output'];
  addUserToGroup: Scalars['Void']['output'];
  addUserToSpecial: UserSpecialType;
  archiveProduct: Scalars['Void']['output'];
  cancelInvite: Scalars['Void']['output'];
  cancelInvoice: Scalars['Void']['output'];
  cancelWithdrawal: Scalars['Void']['output'];
  changeLanguage: Scalars['Void']['output'];
  clearUserByTg: Scalars['Void']['output'];
  confirmEmail: Scalars['Void']['output'];
  crateUserGroup: UserGroupType;
  createAdminUser: UserType;
  createBaseTariff: BaseTariffType;
  createInviteOrders: OrderInviteResultType[];
  createInvoice: CreateInvoiceType;
  createOrganizer: OrganizerType;
  createProduct: ProductType;
  createTariff: SubTariffType;
  /** Method for deactivating an order by organizer on event */
  deactivateOrder: Scalars['Void']['output'];
  deactivateUserOrganizer?: Maybe<UserType>;
  /** Method for disabling an order and canceling payment with a refund from the teacher’s balance */
  disableOrder: OrderType;
  duplicateBaseTariff: BaseTariffType;
  duplicateProduct: ProductType;
  exportFinanceCSV: ExportOrdersCsvType;
  exportOrdersCSV: ExportOrdersCsvType;
  giveOrders: GiveOrderResultType[];
  login: JwtType;
  loginTg: JwtType;
  logout: Scalars['Void']['output'];
  payBonusInvoice: PaymentType;
  refresh: JwtType;
  refundOrder: UserBalanceDepositType;
  removeBaseTariff: Scalars['Void']['output'];
  removePaymentAccount: Scalars['Void']['output'];
  removeProduct: Scalars['Void']['output'];
  removeTariff: Scalars['Void']['output'];
  removeUserFromBlacklist: Scalars['Void']['output'];
  removeUserFromGroup: Scalars['Void']['output'];
  removeUserFromSpecial: Scalars['Void']['output'];
  removeUserGroup: Scalars['Void']['output'];
  requestWithdrawal: Scalars['Void']['output'];
  saveUserInfo: UserType;
  signUp: UserType;
  submitInvite: OrderType;
  /** A way to transfer your order to another product. The order must not be completed */
  transferOrderToProduct: OrderType;
  /** Method for transferring your order to another user. The order must not be completed */
  transferOrderToUser: OrderType;
  updateBaseTariff: BaseTariffType;
  updateOrganizer: OrganizerType;
  updatePaymentAccount?: Maybe<PaymentAccountType>;
  updateProduct: ProductType;
  updateProductInviteTexts: ProductType;
  updateProductTariffs: Scalars['Void']['output'];
  updateTariff: SubTariffType;
  updateUser: UserType;
  updateUserGroupName: UserGroupType;
  updateUsersBaseTariff: Scalars['Void']['output'];
  updateUsersInGroup: Scalars['Void']['output'];
  uploadFile: FileType;
  uploadImages: FileType[];
  verifyEmail: UserType;
  verifyEmailLater: UserType;
}

export interface MutationActivateOrderArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationActivateProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationAddPaymentAccountArgs {
  idOrganizer: Scalars['Int']['input'];
  input: PaymentAccountInput;
}

export interface MutationAddUserBalanceArgs {
  amount: Scalars['Float']['input'];
  organizerId: Scalars['Int']['input'];
  uuidUser: Scalars['UUID']['input'];
}

export interface MutationAddUserOrganizerArgs {
  idOrganizer: Scalars['Float']['input'];
}

export interface MutationAddUserToBlacklistArgs {
  organizerId: Scalars['Float']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationAddUserToGroupArgs {
  uuidGroup: Scalars['UUID']['input'];
  uuidUser: Scalars['UUID']['input'];
}

export interface MutationAddUserToSpecialArgs {
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}

export interface MutationArchiveProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationCancelInviteArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationCancelInvoiceArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationCancelWithdrawalArgs {
  idOrganizer: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationChangeLanguageArgs {
  language: LanguageEnum;
}

export interface MutationClearUserByTgArgs {
  tgId: Scalars['Float']['input'];
}

export interface MutationConfirmEmailArgs {
  email: Scalars['EmailAddress']['input'];
}

export interface MutationCrateUserGroupArgs {
  idOrganizer: Scalars['Float']['input'];
  name: Scalars['String']['input'];
}

export interface MutationCreateAdminUserArgs {
  input: CreateAdminUserInput;
}

export interface MutationCreateBaseTariffArgs {
  idOrganizer: Scalars['Int']['input'];
  input: BaseTariffInput;
}

export interface MutationCreateInviteOrdersArgs {
  params?: InputMaybe<OrdersActionNotifyParams>;
  productUuid: Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  usersUuids: Scalars['UUID']['input'][];
}

export interface MutationCreateInvoiceArgs {
  input: CreateInvoiceInput;
}

export interface MutationCreateOrganizerArgs {
  input: OrganizerInput;
}

export interface MutationCreateProductArgs {
  input: ProductInput;
  organizerId: Scalars['Float']['input'];
  publish?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MutationCreateTariffArgs {
  input: SubTariffInput;
  uuidParentTariff: Scalars['UUID']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface MutationDeactivateOrderArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationDeactivateUserOrganizerArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationDisableOrderArgs {
  id: Scalars['Int']['input'];
}

export interface MutationDuplicateBaseTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationDuplicateProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationExportFinanceCsvArgs {
  organizerId: Scalars['Float']['input'];
}

export interface MutationExportOrdersCsvArgs {
  productUuid: Scalars['UUID']['input'];
}

export interface MutationGiveOrdersArgs {
  params?: InputMaybe<OrdersActionNotifyParams>;
  productUuid: Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  usersUuids: Scalars['UUID']['input'][];
}

export interface MutationLoginArgs {
  input: LoginInput;
}

export interface MutationLoginTgArgs {
  input: LoginTgInput;
}

export interface MutationPayBonusInvoiceArgs {
  params: PayBonusInvoiceInput;
}

export interface MutationRefreshArgs {
  refresh_token: Scalars['String']['input'];
}

export interface MutationRefundOrderArgs {
  orderId: Scalars['Int']['input'];
}

export interface MutationRemoveBaseTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemovePaymentAccountArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveUserFromBlacklistArgs {
  organizerId: Scalars['Float']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveUserFromGroupArgs {
  uuidGroup: Scalars['UUID']['input'];
  uuidUser: Scalars['UUID']['input'];
}

export interface MutationRemoveUserFromSpecialArgs {
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}

export interface MutationRemoveUserGroupArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRequestWithdrawalArgs {
  input: WithdrawalOrganizerInput;
}

export interface MutationSaveUserInfoArgs {
  input: SaveUserInfoInput;
}

export interface MutationSignUpArgs {
  input: SignUpInput;
}

export interface MutationSubmitInviteArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationTransferOrderToProductArgs {
  orderId: Scalars['Int']['input'];
  productUuid: Scalars['UUID']['input'];
}

export interface MutationTransferOrderToUserArgs {
  orderId: Scalars['Int']['input'];
  transferUserUuid: Scalars['UUID']['input'];
}

export interface MutationUpdateBaseTariffArgs {
  input: BaseTariffInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateOrganizerArgs {
  id: Scalars['Float']['input'];
  input: OrganizerInput;
}

export interface MutationUpdatePaymentAccountArgs {
  input: PaymentAccountInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateProductArgs {
  input: ProductInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateProductInviteTextsArgs {
  input: ProductInviteTextsInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateProductTariffsArgs {
  productUuid: Scalars['UUID']['input'];
  uuids: Scalars['UUID']['input'][];
}

export interface MutationUpdateTariffArgs {
  input: SubTariffInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateUserArgs {
  input: UpdateUserInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateUserGroupNameArgs {
  name: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateUsersBaseTariffArgs {
  usersUuid: Scalars['UUID']['input'][];
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateUsersInGroupArgs {
  uuidGroup: Scalars['UUID']['input'];
  uuidUsers: Scalars['UUID']['input'][];
}

export interface MutationUploadFileArgs {
  file: Scalars['Upload']['input'];
}

export interface MutationUploadImagesArgs {
  image: Scalars['Upload']['input'][];
}

export interface MutationVerifyEmailArgs {
  code: Scalars['String']['input'];
}

export interface MutationVerifyEmailLaterArgs {
  email: Scalars['EmailAddress']['input'];
}

export interface NodeIdType {
  id: Scalars['ID']['output'];
}

export interface NodeUuidType {
  uuid: Scalars['UUID']['output'];
}

export interface OrderFeatureFlagsType {
  __typename?: 'OrderFeatureFlagsType';
  canRefund: Scalars['Boolean']['output'];
  canTransferToProduct: Scalars['Boolean']['output'];
  canTransferToUser: Scalars['Boolean']['output'];
}

export interface OrderInviteResultType {
  __typename?: 'OrderInviteResultType';
  error?: Maybe<Errors>;
  status: Scalars['Boolean']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  uuidUser: Scalars['UUID']['output'];
}

export enum OrderInviteStatusEnum {
  Cancelled = 'Cancelled',
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Withdrawn = 'Withdrawn',
}

export type OrderInviteType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'OrderInviteType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    product: ProductType;
    status: OrderInviteStatusEnum;
    tariff: SubTariffType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export enum OrderSortKeys {
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  Status = 'status',
  VisitedAt = 'visitedAt',
}

export interface OrderSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: OrderSortKeys;
}

export enum OrderStatusEnum {
  CompletedAndNotVisited = 'CompletedAndNotVisited',
  CompletedAndVisited = 'CompletedAndVisited',
  Deactivated = 'Deactivated',
  NotPaid = 'NotPaid',
  Paid = 'Paid',
  Refunded = 'Refunded',
  TransferredToProduct = 'TransferredToProduct',
  TransferredToUser = 'TransferredToUser',
}

export type OrderType = BaseGraphModel &
  NodeIdType & {
    __typename?: 'OrderType';
    amount: Scalars['Float']['output'];
    completedAt?: Maybe<Scalars['DateTime']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    featureFlags: OrderFeatureFlagsType;
    fixedHoldPercentage: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    orderInvite?: Maybe<OrderType>;
    organizer: OrganizerType;
    pairOrder?: Maybe<OrderType>;
    payment?: Maybe<PaymentType>;
    product: ProductType;
    status: OrderStatusEnum;
    tariff: TariffType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    /** Pay by bonus in this order */
    userBalanceWithdrawal?: Maybe<UserBalanceWithdrawalType>;
    uuid: Scalars['UUID']['output'];
    visitedAt?: Maybe<Scalars['DateTime']['output']>;
  };

export interface OrdersActionNotifyParams {
  sendNotifyToBot?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotifyToEmail?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface OrdersFilterInput {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
}

export interface OrdersListFilterInput {
  order?: InputMaybe<OrderSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface OrdersParamsInput {
  status?: InputMaybe<OrderStatusEnum[]>;
}

export interface OrdersResponse {
  __typename?: 'OrdersResponse';
  count: Scalars['Int']['output'];
  rows: OrderType[];
}

export interface OrganizerFilesType {
  __typename?: 'OrganizerFilesType';
  agent?: Maybe<Scalars['String']['output']>;
  oferta?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
}

export interface OrganizerInput {
  description: Scalars['String']['input'];
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orderInstructions: OrganizerOrderInstructionsInput;
  query?: InputMaybe<Scalars['String']['input']>;
  socials: OrganizerSocialInput;
  supplierInfo: OrganizerSupplierInfoInput;
  telegram: Scalars['String']['input'];
}

export interface OrganizerListFilterInput {
  order?: InputMaybe<OrganizerSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<OrganizerSearchType>;
}

export interface OrganizerOrderInstructionsInput {
  refund?: InputMaybe<Scalars['String']['input']>;
  transferToProduct?: InputMaybe<Scalars['String']['input']>;
  transferToUser?: InputMaybe<Scalars['String']['input']>;
}

export interface OrganizerOrderInstructionsType {
  __typename?: 'OrganizerOrderInstructionsType';
  refund: Scalars['String']['output'];
  transferToProduct: Scalars['String']['output'];
  transferToUser: Scalars['String']['output'];
}

export enum OrganizerSearchKeys {
  Description = 'description',
  Name = 'name',
  Telegram = 'telegram',
}

export interface OrganizerSearchType {
  search: Scalars['String']['input'];
  searchBy: OrganizerSearchKeys;
}

export interface OrganizerSocialInput {
  facebook?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  telegram?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vk?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface OrganizerSocialType {
  __typename?: 'OrganizerSocialType';
  facebook?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  telegram?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  vk?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
}

export enum OrganizerSortKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Telegram = 'telegram',
}

export interface OrganizerSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: OrganizerSortKeys;
}

export interface OrganizerSupplierInfoInput {
  inn?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface OrganizerSupplierInfoType {
  __typename?: 'OrganizerSupplierInfoType';
  inn?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
}

export type OrganizerType = BaseGraphModel &
  NodeIdType & {
    __typename?: 'OrganizerType';
    balance: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    files: OrganizerFilesType;
    id: Scalars['ID']['output'];
    image: Scalars['String']['output'];
    name: Scalars['String']['output'];
    orderInstructions: OrganizerOrderInstructionsType;
    paymentAccounts: PaymentAccountType[];
    products: ProductType[];
    productsCount: Scalars['Int']['output'];
    query?: Maybe<Scalars['String']['output']>;
    socials: OrganizerSocialType;
    supplierInfo: OrganizerSupplierInfoType;
    telegram: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    userBalance: Scalars['Float']['output'];
    withdrawals: WithdrawalType[];
  };

export interface OrganizerTypeOrderInstructionsArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface OrganizerTypeSocialsArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface OrganizersResponse {
  __typename?: 'OrganizersResponse';
  count: Scalars['Int']['output'];
  rows: OrganizerType[];
}

export interface PaginationType {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}

export interface ParamsUsersInput {
  organizerId?: InputMaybe<Scalars['Float']['input']>;
}

export interface PayBonusInvoiceInput {
  amount: Scalars['Float']['input'];
  paymentUuid: Scalars['UUID']['input'];
}

export interface PaymentAccountInput {
  accountNumber: Scalars['String']['input'];
  bik: Scalars['String']['input'];
  inn: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export type PaymentAccountType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'PaymentAccountType';
    accountNumber: Scalars['String']['output'];
    bik: Scalars['String']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    inn: Scalars['String']['output'];
    name: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export enum PaymentSortKeys {
  CreatedAt = 'createdAt',
  Price = 'price',
}

export interface PaymentSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: PaymentSortKeys;
}

export enum PaymentStatusEnum {
  Canceled = 'Canceled',
  Created = 'Created',
  Failed = 'Failed',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Success = 'Success',
}

export type PaymentType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'PaymentType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    invoiceQrLink?: Maybe<Scalars['String']['output']>;
    invoice_url?: Maybe<Scalars['String']['output']>;
    order: OrderType;
    payment_uuid?: Maybe<Scalars['String']['output']>;
    price: Scalars['Float']['output'];
    status: PaymentStatusEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export interface PaymentsArgsInput {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
}

export interface PaymentsListFilterInput {
  order?: InputMaybe<PaymentSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface PaymentsResponse {
  __typename?: 'PaymentsResponse';
  count: Scalars['Int']['output'];
  rows: PaymentType[];
}

export interface PlatformProfitStatsType {
  __typename?: 'PlatformProfitStatsType';
  profit: Scalars['Float']['output'];
}

export interface PreviewProductInput {
  active: Scalars['Boolean']['input'];
  backButtonText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface PreviewProductType {
  __typename?: 'PreviewProductType';
  active: Scalars['Boolean']['output'];
  backButtonText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ProductCountsType {
  __typename?: 'ProductCountsType';
  activeCount: Scalars['Float']['output'];
  archiveCount: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  draftCount: Scalars['Float']['output'];
}

export interface ProductFeatureFlagsInput {
  allowedNumberForTransferToProductOrder: Scalars['Float']['input'];
  durationBeforeStartForRefundOrder: Scalars['String']['input'];
  durationBeforeStartForTransferToUserOrder: Scalars['String']['input'];
}

export interface ProductFeatureFlagsType {
  __typename?: 'ProductFeatureFlagsType';
  allowedNumberForTransferToProductOrder: Scalars['Float']['output'];
  durationBeforeStartForRefundOrder: Scalars['ISO8601Duration']['output'];
  durationBeforeStartForTransferToUserOrder: Scalars['ISO8601Duration']['output'];
}

export interface ProductFilter {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProductInput {
  activeAcquiring: Scalars['Boolean']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  faq?: InputMaybe<Scalars['String']['input']>;
  featureFlags: ProductFeatureFlagsInput;
  img: Scalars['String']['input'];
  invoiceLifetime: Scalars['String']['input'];
  manualBuyText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  preview: PreviewProductInput;
  query?: InputMaybe<Scalars['String']['input']>;
  route: RouteProductInput;
  salesStartedDate?: InputMaybe<Scalars['DateTime']['input']>;
  salesStartedText?: InputMaybe<Scalars['String']['input']>;
  startedDate: Scalars['DateTime']['input'];
  successBuyText?: InputMaybe<Scalars['String']['input']>;
  tariffsEmptyText?: InputMaybe<Scalars['String']['input']>;
  tariffsText?: InputMaybe<Scalars['String']['input']>;
  tariffsUnavailableText?: InputMaybe<Scalars['String']['input']>;
  transferOrderToUserSuccessText?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductInviteTextsInput {
  inviteOrderSuccessText?: InputMaybe<Scalars['String']['input']>;
  inviteOrderText?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductListFilterInput {
  order?: InputMaybe<ProductSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<ProductSearchType>;
}

export enum ProductSearchKeysEnum {
  Description = 'description',
  Name = 'name',
}

export interface ProductSearchType {
  search: Scalars['String']['input'];
  searchBy: ProductSearchKeysEnum;
}

export enum ProductSortKeys {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Name = 'name',
  StartedDate = 'startedDate',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export interface ProductSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: ProductSortKeys;
}

export type ProductType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'ProductType';
    activeAcquiring: Scalars['Boolean']['output'];
    address?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    endDate: Scalars['DateTime']['output'];
    faq?: Maybe<Scalars['String']['output']>;
    featureFlags: ProductFeatureFlagsType;
    img: Scalars['String']['output'];
    inviteOrderSuccessText: Scalars['String']['output'];
    inviteOrderText: Scalars['String']['output'];
    invoiceLifetime: Scalars['ISO8601Duration']['output'];
    manualBuyText?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    organizer: OrganizerType;
    preview: PreviewProductType;
    purchasedCount: Scalars['Float']['output'];
    query?: Maybe<Scalars['String']['output']>;
    receipts: Scalars['Float']['output'];
    route: RouteProductType;
    salesStartedDate?: Maybe<Scalars['DateTime']['output']>;
    salesStartedText?: Maybe<Scalars['String']['output']>;
    startedDate: Scalars['DateTime']['output'];
    status: StatusEnum;
    successBuyText: Scalars['String']['output'];
    tariffs: TariffType[];
    tariffsEmptyText: Scalars['String']['output'];
    tariffsText: Scalars['String']['output'];
    tariffsUnavailableText: Scalars['String']['output'];
    transferOrderToUserSuccessText: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
    visitedCount: Scalars['Float']['output'];
  };

export interface ProductTypeFaqArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeInviteOrderSuccessTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeInviteOrderTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeSalesStartedTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeSuccessBuyTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeTariffsEmptyTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeTariffsTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeTariffsUnavailableTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTypeTransferOrderToUserSuccessTextArgs {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductsResponse {
  __typename?: 'ProductsResponse';
  count: Scalars['Int']['output'];
  rows: ProductType[];
}

export interface PurchasesStatsType {
  __typename?: 'PurchasesStatsType';
  profit: Scalars['Float']['output'];
  usersCounts: UsersCountsType;
}

export interface Query {
  __typename?: 'Query';
  allAllowedUsersForBaseTariff: UserType[];
  baseTariff: BaseTariffType;
  baseTariffsList: BaseTariffsResponse;
  baseTexts: BaseTextsType[];
  blacklist: UsersResponse;
  checkHasUserOrderByProduct: Scalars['Boolean']['output'];
  financeStats: FinanceStatsType;
  me: UserType;
  order: OrderType;
  orderByProductTariff: OrderType;
  orderByUuid: OrderType;
  orderInvite: OrderInviteType;
  orderInviteByProduct: OrderInviteType;
  /** Getting a list of orders for the user */
  orders: OrdersResponse;
  ordersList: OrdersResponse;
  organizer: OrganizerType;
  organizerByQuery: OrganizerType;
  organizerByTg: OrganizerType;
  organizerShortcodes: Scalars['String']['output'][];
  organizers: OrganizersResponse;
  payment: PaymentType;
  payments: PaymentsResponse;
  paymentsByOrganizer: PaymentAccountType[];
  paymentsList: PaymentsResponse;
  platformProfitStats: PlatformProfitStatsType;
  product: ProductType;
  productByQuery: ProductType;
  productShortcodes: Scalars['String']['output'][];
  products: ProductsResponse;
  productsCount: ProductCountsType;
  productsForTransfer: ProductType[];
  productsList: ProductsResponse;
  purchasesStats: PurchasesStatsType;
  searchUser?: Maybe<UserType>;
  specialUsers: UsersResponse;
  subTariff: SubTariffType;
  subTariffsList: SubTariffsResponse;
  tariff: TariffType;
  tariffByQuery: TariffType;
  tariffs: TariffsResponse;
  user?: Maybe<UserType>;
  userBalance: UserBalanceType;
  userGroups: UserGroupsResponse;
  users: UsersResponse;
  usersByGroup: UsersResponse;
  usersByProduct: UsersResponse;
  usersStats: UsersStatsType;
  withdrawalsByOrganizer: WithdrawalsResponse;
}

export interface QueryAllAllowedUsersForBaseTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryBaseTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryBaseTariffsListArgs {
  filters?: InputMaybe<BaseTariffListFilterInput>;
  idOrganizer?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusEnum[]>;
}

export interface QueryBaseTextsArgs {
  keys: BaseTextsKeysEnum[];
}

export interface QueryBlacklistArgs {
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Float']['input'];
}

export interface QueryCheckHasUserOrderByProductArgs {
  productUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
}

export interface QueryFinanceStatsArgs {
  organizerId: Scalars['Int']['input'];
}

export interface QueryOrderArgs {
  id: Scalars['Int']['input'];
}

export interface QueryOrderByProductTariffArgs {
  status?: InputMaybe<OrderStatusEnum[]>;
  statusPayment?: InputMaybe<PaymentStatusEnum[]>;
  uuidProduct: Scalars['UUID']['input'];
  uuidTariff?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryOrderByUuidArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryOrderInviteArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryOrderInviteByProductArgs {
  uuidProduct: Scalars['UUID']['input'];
}

export interface QueryOrdersArgs {
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryOrdersListArgs {
  args?: InputMaybe<OrdersFilterInput>;
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
}

export interface QueryOrganizerArgs {
  id: Scalars['Int']['input'];
}

export interface QueryOrganizerByQueryArgs {
  query: Scalars['String']['input'];
}

export interface QueryOrganizerByTgArgs {
  tg: Scalars['String']['input'];
}

export interface QueryOrganizersArgs {
  filters?: InputMaybe<OrganizerListFilterInput>;
}

export interface QueryPaymentArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryPaymentsArgs {
  filters?: InputMaybe<PaymentsListFilterInput>;
}

export interface QueryPaymentsByOrganizerArgs {
  idOrganizer: Scalars['Int']['input'];
}

export interface QueryPaymentsListArgs {
  args?: InputMaybe<PaymentsArgsInput>;
  filters?: InputMaybe<PaymentsListFilterInput>;
  status?: PaymentStatusEnum[];
}

export interface QueryPlatformProfitStatsArgs {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryProductByQueryArgs {
  query: Scalars['String']['input'];
}

export interface QueryProductsArgs {
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
}

export interface QueryProductsCountArgs {
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
}

export interface QueryProductsForTransferArgs {
  currentTariffUuid: Scalars['UUID']['input'];
  organizerId: Scalars['Int']['input'];
}

export interface QueryProductsListArgs {
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
  status?: InputMaybe<StatusEnum[]>;
}

export interface QueryPurchasesStatsArgs {
  organizerId: Scalars['Float']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QuerySearchUserArgs {
  input: Scalars['String']['input'];
  params?: InputMaybe<SearchUserParamsInput>;
}

export interface QuerySpecialUsersArgs {
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Float']['input'];
}

export interface QuerySubTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QuerySubTariffsListArgs {
  filters?: InputMaybe<SubTariffListFilterInput>;
  productUuid: Scalars['UUID']['input'];
  status?: InputMaybe<StatusEnum[]>;
}

export interface QueryTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryTariffByQueryArgs {
  productQuery: Scalars['String']['input'];
  tariffQuery: Scalars['String']['input'];
}

export interface QueryTariffsArgs {
  filters?: InputMaybe<TariffListFilterInput>;
  uuidProduct: Scalars['UUID']['input'];
}

export interface QueryUserArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryUserBalanceArgs {
  organizerId: Scalars['Int']['input'];
}

export interface QueryUserGroupsArgs {
  filters?: InputMaybe<UserGroupsListFilterInput>;
  idOrganizer: Scalars['Float']['input'];
}

export interface QueryUsersArgs {
  args: FilterUsersInput;
  filters?: InputMaybe<UserListFilterInput>;
  params: ParamsUsersInput;
}

export interface QueryUsersByGroupArgs {
  filters?: InputMaybe<UserListFilterInput>;
  groupUuid: Scalars['UUID']['input'];
}

export interface QueryUsersByProductArgs {
  filters?: InputMaybe<UserListFilterInput>;
  uuidProduct: Scalars['UUID']['input'];
}

export interface QueryUsersStatsArgs {
  args: FilterUsersInput;
  params: ParamsUsersInput;
}

export interface QueryWithdrawalsByOrganizerArgs {
  filters?: InputMaybe<WithdrawalListFilterInput>;
  idOrganizer: Scalars['Int']['input'];
}

export enum RoleEnum {
  Admin = 'Admin',
  Organizer = 'Organizer',
  User = 'User',
}

export interface RouteProductInput {
  active: Scalars['Boolean']['input'];
  backButtonText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface RouteProductType {
  __typename?: 'RouteProductType';
  active: Scalars['Boolean']['output'];
  backButtonText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface SaveUserInfoInput {
  first_name: Scalars['String']['input'];
  gender: GenderEnum;
  last_name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
}

export interface SearchUserParamsInput {
  fullUsernameMatch?: InputMaybe<Scalars['Boolean']['input']>;
  offSearchByNames?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SignUpInput {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  language?: LanguageEnum;
  last_name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  tgId: Scalars['Float']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
}

export enum SortEnum {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum StatusEnum {
  Active = 'Active',
  Archive = 'Archive',
  Draft = 'Draft',
}

export interface SubTariffInput {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordersCount?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  specialPrice?: InputMaybe<Scalars['Float']['input']>;
  startedDate?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SubTariffListFilterInput {
  order?: InputMaybe<SubTariffSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<SubTariffSearchType>;
}

export enum SubTariffSearchKeys {
  Description = 'description',
  Name = 'name',
  Type = 'type',
}

export interface SubTariffSearchType {
  search: Scalars['String']['input'];
  searchBy: SubTariffSearchKeys;
}

export enum SubTariffSortKeys {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Name = 'name',
  OrdersCount = 'ordersCount',
  Price = 'price',
  Query = 'query',
  SpecialPrice = 'specialPrice',
  StartedDate = 'startedDate',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Visible = 'visible',
}

export interface SubTariffSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: SubTariffSortKeys;
}

export type SubTariffType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'SubTariffType';
    availableOrdersCount?: Maybe<Scalars['Float']['output']>;
    baseTariff?: Maybe<BaseTariffType>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    endDate?: Maybe<Scalars['DateTime']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    ordersCount?: Maybe<Scalars['Float']['output']>;
    paidOrdersCount: Scalars['Float']['output'];
    price?: Maybe<Scalars['Float']['output']>;
    query?: Maybe<Scalars['String']['output']>;
    specialPrice?: Maybe<Scalars['Float']['output']>;
    startedDate?: Maybe<Scalars['DateTime']['output']>;
    status: StatusEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface SubTariffsResponse {
  __typename?: 'SubTariffsResponse';
  count: Scalars['Int']['output'];
  rows: SubTariffType[];
}

export interface TariffFeatureFlagsInput {
  bonusPay: Scalars['Boolean']['input'];
  refundOrder: Scalars['Boolean']['input'];
  repeatBuyOrder: Scalars['Boolean']['input'];
  transferToProductOrder: Scalars['Boolean']['input'];
  transferToUserOrder: Scalars['Boolean']['input'];
}

export interface TariffFeatureFlagsType {
  __typename?: 'TariffFeatureFlagsType';
  bonusPay: Scalars['Boolean']['output'];
  refundOrder: Scalars['Boolean']['output'];
  repeatBuyOrder: Scalars['Boolean']['output'];
  transferToProductOrder: Scalars['Boolean']['output'];
  transferToUserOrder: Scalars['Boolean']['output'];
}

export interface TariffListFilterInput {
  pagination?: InputMaybe<PaginationType>;
}

export type TariffType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'TariffType';
    availableOrdersCount?: Maybe<Scalars['Float']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    endDate?: Maybe<Scalars['DateTime']['output']>;
    featureFlags: TariffFeatureFlagsType;
    name: Scalars['String']['output'];
    ordersCount?: Maybe<Scalars['Float']['output']>;
    paidOrdersCount: Scalars['Float']['output'];
    price: Scalars['Float']['output'];
    query: Scalars['Int']['output'];
    specialPrice?: Maybe<Scalars['Float']['output']>;
    startedDate?: Maybe<Scalars['DateTime']['output']>;
    type: TariffTypeEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
    visible: Scalars['Boolean']['output'];
  };

export enum TariffTypeEnum {
  AnyGender = 'AnyGender',
  Female = 'Female',
  Male = 'Male',
  Pair = 'Pair',
}

export interface TariffsResponse {
  __typename?: 'TariffsResponse';
  count: Scalars['Int']['output'];
  rows: TariffType[];
}

export interface UpdateUserInput {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}

export type UserBalanceDepositType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserBalanceDepositType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export type UserBalanceType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserBalanceType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export type UserBalanceWithdrawalType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserBalanceWithdrawalType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export type UserGroupType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserGroupType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    name: Scalars['String']['output'];
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    usersCount: Scalars['Int']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface UserGroupsListFilterInput {
  order?: InputMaybe<UserGroupsSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<UserGroupsSearchType>;
}

export interface UserGroupsResponse {
  __typename?: 'UserGroupsResponse';
  count: Scalars['Int']['output'];
  rows: UserGroupType[];
}

export enum UserGroupsSearchKeys {
  Name = 'name',
}

export interface UserGroupsSearchType {
  search: Scalars['String']['input'];
  searchBy: UserGroupsSearchKeys;
}

export enum UserGroupsSortKeys {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
}

export interface UserGroupsSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: UserGroupsSortKeys;
}

export interface UserListFilterInput {
  order?: InputMaybe<UserSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export enum UserSortKeys {
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'first_name',
  Gender = 'gender',
  LastName = 'last_name',
  Phone = 'phone',
  TgId = 'tgId',
  Username = 'username',
}

export interface UserSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: UserSortKeys;
}

export type UserSpecialType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserSpecialType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export type UserType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserType';
    activatedOrdersCount: Scalars['Int']['output'];
    balance: Scalars['Float']['output'];
    controlOrganizerId?: Maybe<Scalars['Float']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    email?: Maybe<Scalars['EmailAddress']['output']>;
    first_name: Scalars['String']['output'];
    gender?: Maybe<GenderEnum>;
    hasInviteByProduct: Scalars['Boolean']['output'];
    hasOrderByProduct: Scalars['Boolean']['output'];
    hasTariffAccess?: Maybe<Scalars['Boolean']['output']>;
    isBlocked: Scalars['Boolean']['output'];
    isSpecial: Scalars['Boolean']['output'];
    language: LanguageEnum;
    last_name?: Maybe<Scalars['String']['output']>;
    ordersCount: Scalars['Int']['output'];
    organizers: OrganizerType[];
    paymentsCount: Scalars['Int']['output'];
    phone?: Maybe<Scalars['PhoneNumber']['output']>;
    receipts: Scalars['Float']['output'];
    role: RoleEnum;
    submittedEmail: Scalars['Boolean']['output'];
    tgId?: Maybe<Scalars['Float']['output']>;
    updatedAt: Scalars['DateTimeISO']['output'];
    username?: Maybe<Scalars['String']['output']>;
    uuid: Scalars['UUID']['output'];
  };

export interface UserTypeBalanceArgs {
  idOrganizer: Scalars['Float']['input'];
}

export interface UserTypeHasInviteByProductArgs {
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}

export interface UserTypeHasOrderByProductArgs {
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}

export interface UserTypeIsBlockedArgs {
  organizerId: Scalars['Float']['input'];
}

export interface UserTypeIsSpecialArgs {
  idOrganizer: Scalars['Float']['input'];
}

export interface UsersCountsType {
  __typename?: 'UsersCountsType';
  all: Scalars['Float']['output'];
  female: Scalars['Float']['output'];
  male: Scalars['Float']['output'];
}

export interface UsersResponse {
  __typename?: 'UsersResponse';
  count: Scalars['Int']['output'];
  rows: UserType[];
}

export interface UsersStatsType {
  __typename?: 'UsersStatsType';
  count: Scalars['Int']['output'];
  femaleCount: Scalars['Int']['output'];
  maleCount: Scalars['Int']['output'];
}

export interface WithdrawalListFilterInput {
  order?: InputMaybe<WithdrawalSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface WithdrawalOrganizerInput {
  amount: Scalars['Float']['input'];
  organizerId: Scalars['Int']['input'];
  uuidPaymentAccount: Scalars['UUID']['input'];
}

export enum WithdrawalSortKeys {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export interface WithdrawalSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: WithdrawalSortKeys;
}

export enum WithdrawalStatusEnum {
  Cancelled = 'Cancelled',
  Executed = 'Executed',
  Failed = 'Failed',
  InProgress = 'InProgress',
}

export type WithdrawalType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'WithdrawalType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    idOrganizer: Scalars['Int']['output'];
    organizer: OrganizerType;
    paymentAccount: PaymentAccountType;
    status: WithdrawalStatusEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface WithdrawalsResponse {
  __typename?: 'WithdrawalsResponse';
  count: Scalars['Int']['output'];
  rows: WithdrawalType[];
}

export interface JwtFieldsFragment {
  __typename?: 'JwtType';
  access_token: string;
  refresh_token: string;
}

export type LoginMutationVariables = Exact<{
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export interface LoginMutation {
  __typename?: 'Mutation';
  login: {
    __typename?: 'JwtType';
    access_token: string;
    refresh_token: string;
  };
}

export type RefreshMutationVariables = Exact<{
  refresh_token: Scalars['String']['input'];
}>;

export interface RefreshMutation {
  __typename?: 'Mutation';
  refresh: {
    __typename?: 'JwtType';
    access_token: string;
    refresh_token: string;
  };
}

export type MeQueryVariables = Exact<Record<string, never>>;

export interface MeQuery {
  __typename?: 'Query';
  me: {
    __typename?: 'UserType';
    uuid: string;
    role: RoleEnum;
    username?: string | null;
    controlOrganizerId?: number | null;
  };
}

export interface BaseTariffFieldsFragment {
  __typename?: 'BaseTariffType';
  createdAt: number;
  updatedAt: number;
  uuid: string;
  name: string;
  description?: string | null;
  query?: string | null;
  ordersCount?: number | null;
  price: number;
  visible: boolean;
  status: StatusEnum;
  type: TariffTypeEnum;
  countSubTariffs: number;
  featureFlags: {
    __typename?: 'TariffFeatureFlagsType';
    bonusPay: boolean;
    refundOrder: boolean;
    transferToUserOrder: boolean;
    transferToProductOrder: boolean;
    repeatBuyOrder: boolean;
  };
}

export type BaseTariffsListQueryVariables = Exact<{
  filters?: InputMaybe<BaseTariffListFilterInput>;
  status?: InputMaybe<StatusEnum[] | StatusEnum>;
  idOrganizer: Scalars['Int']['input'];
}>;

export interface BaseTariffsListQuery {
  __typename?: 'Query';
  baseTariffsList: {
    __typename?: 'BaseTariffsResponse';
    count: number;
    rows: {
      __typename?: 'BaseTariffType';
      createdAt: number;
      updatedAt: number;
      uuid: string;
      name: string;
      description?: string | null;
      query?: string | null;
      ordersCount?: number | null;
      price: number;
      visible: boolean;
      status: StatusEnum;
      type: TariffTypeEnum;
      countSubTariffs: number;
      featureFlags: {
        __typename?: 'TariffFeatureFlagsType';
        bonusPay: boolean;
        refundOrder: boolean;
        transferToUserOrder: boolean;
        transferToProductOrder: boolean;
        repeatBuyOrder: boolean;
      };
    }[];
  };
}

export type BaseTariffQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface BaseTariffQuery {
  __typename?: 'Query';
  baseTariff: {
    __typename?: 'BaseTariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name: string;
    description?: string | null;
    query?: string | null;
    ordersCount?: number | null;
    price: number;
    visible: boolean;
    status: StatusEnum;
    type: TariffTypeEnum;
    countSubTariffs: number;
    featureFlags: {
      __typename?: 'TariffFeatureFlagsType';
      bonusPay: boolean;
      refundOrder: boolean;
      transferToUserOrder: boolean;
      transferToProductOrder: boolean;
      repeatBuyOrder: boolean;
    };
  };
}

export type AllAllowedUsersForBaseTariffQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface AllAllowedUsersForBaseTariffQuery {
  __typename?: 'Query';
  allAllowedUsersForBaseTariff: {
    __typename?: 'UserType';
    uuid: string;
  }[];
}

export type CreateBaseTariffMutationVariables = Exact<{
  input: BaseTariffInput;
  idOrganizer: Scalars['Int']['input'];
}>;

export interface CreateBaseTariffMutation {
  __typename?: 'Mutation';
  createBaseTariff: {
    __typename?: 'BaseTariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name: string;
    description?: string | null;
    query?: string | null;
    ordersCount?: number | null;
    price: number;
    visible: boolean;
    status: StatusEnum;
    type: TariffTypeEnum;
    countSubTariffs: number;
    featureFlags: {
      __typename?: 'TariffFeatureFlagsType';
      bonusPay: boolean;
      refundOrder: boolean;
      transferToUserOrder: boolean;
      transferToProductOrder: boolean;
      repeatBuyOrder: boolean;
    };
  };
}

export type UpdateBaseTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: BaseTariffInput;
}>;

export interface UpdateBaseTariffMutation {
  __typename?: 'Mutation';
  updateBaseTariff: {
    __typename?: 'BaseTariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name: string;
    description?: string | null;
    query?: string | null;
    ordersCount?: number | null;
    price: number;
    visible: boolean;
    status: StatusEnum;
    type: TariffTypeEnum;
    countSubTariffs: number;
    featureFlags: {
      __typename?: 'TariffFeatureFlagsType';
      bonusPay: boolean;
      refundOrder: boolean;
      transferToUserOrder: boolean;
      transferToProductOrder: boolean;
      repeatBuyOrder: boolean;
    };
  };
}

export type DuplicateBaseTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface DuplicateBaseTariffMutation {
  __typename?: 'Mutation';
  duplicateBaseTariff: {
    __typename?: 'BaseTariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name: string;
    description?: string | null;
    query?: string | null;
    ordersCount?: number | null;
    price: number;
    visible: boolean;
    status: StatusEnum;
    type: TariffTypeEnum;
    countSubTariffs: number;
    featureFlags: {
      __typename?: 'TariffFeatureFlagsType';
      bonusPay: boolean;
      refundOrder: boolean;
      transferToUserOrder: boolean;
      transferToProductOrder: boolean;
      repeatBuyOrder: boolean;
    };
  };
}

export type RemoveBaseTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface RemoveBaseTariffMutation {
  __typename?: 'Mutation';
  removeBaseTariff: any;
}

export type UpdateUsersBaseTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  userUuids: Scalars['UUID']['input'][] | Scalars['UUID']['input'];
}>;

export interface UpdateUsersBaseTariffMutation {
  __typename?: 'Mutation';
  updateUsersBaseTariff: any;
}

export type UploadImagesMutationVariables = Exact<{
  images: Scalars['Upload']['input'][] | Scalars['Upload']['input'];
}>;

export interface UploadImagesMutation {
  __typename?: 'Mutation';
  uploadImages: { __typename?: 'FileType'; url: string }[];
}

export type FinanceStatsQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
}>;

export interface FinanceStatsQuery {
  __typename?: 'Query';
  paymentsList: { __typename?: 'PaymentsResponse'; count: number };
  financeStats: {
    __typename?: 'FinanceStatsType';
    profit: number;
    totalCommission: number;
    totalReceived: number;
    totalWithdrawals: number;
  };
  organizer: { __typename?: 'OrganizerType'; balance: number };
}

export type PurchaseStatsQueryVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface PurchaseStatsQuery {
  __typename?: 'Query';
  purchasesStats: {
    __typename?: 'PurchasesStatsType';
    profit: number;
    usersCounts: {
      __typename?: 'UsersCountsType';
      all: number;
      female: number;
      male: number;
    };
  };
}

export type PaymentsListQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsListFilterInput>;
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<PaymentStatusEnum[] | PaymentStatusEnum>;
}>;

export interface PaymentsListQuery {
  __typename?: 'Query';
  paymentsList: {
    __typename?: 'PaymentsResponse';
    count: number;
    rows: {
      __typename?: 'PaymentType';
      uuid: string;
      price: number;
      createdAt: number;
      payment_uuid?: string | null;
      status: PaymentStatusEnum;
      user: {
        __typename?: 'UserType';
        first_name: string;
        last_name?: string | null;
        email?: string | null;
        username?: string | null;
        uuid: string;
      };
      order: {
        __typename?: 'OrderType';
        id: number;
        status: OrderStatusEnum;
        fixedHoldPercentage: number;
        product: {
          __typename?: 'ProductType';
          name: string;
          startedDate: string;
          endDate: string;
        };
        userBalanceWithdrawal?: {
          __typename?: 'UserBalanceWithdrawalType';
          amount: number;
        } | null;
        tariff: { __typename?: 'TariffType'; name: string };
        pairOrder?: {
          __typename?: 'OrderType';
          user: {
            __typename?: 'UserType';
            first_name: string;
            last_name?: string | null;
            email?: string | null;
            username?: string | null;
            uuid: string;
            phone?: string | null;
          };
        } | null;
      };
    }[];
  };
}

export type FinanceListsCountQueryVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
}>;

export interface FinanceListsCountQuery {
  __typename?: 'Query';
  paymentsList: { __typename?: 'PaymentsResponse'; count: number };
  withdrawalsByOrganizer: { __typename?: 'WithdrawalsResponse'; count: number };
}

export type OrdersListQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<OrderStatusEnum[] | OrderStatusEnum>;
}>;

export interface OrdersListQuery {
  __typename?: 'Query';
  ordersList: {
    __typename?: 'OrdersResponse';
    count: number;
    rows: {
      __typename?: 'OrderType';
      createdAt: number;
      id: number;
      status: OrderStatusEnum;
      fixedHoldPercentage: number;
      payment?: {
        __typename?: 'PaymentType';
        price: number;
        status: PaymentStatusEnum;
      } | null;
      user: {
        __typename?: 'UserType';
        first_name: string;
        last_name?: string | null;
        email?: string | null;
        username?: string | null;
        uuid: string;
        phone?: string | null;
      };
      product: {
        __typename?: 'ProductType';
        name: string;
        startedDate: string;
        endDate: string;
      };
      userBalanceWithdrawal?: {
        __typename?: 'UserBalanceWithdrawalType';
        amount: number;
      } | null;
      tariff: { __typename?: 'TariffType'; name: string };
      pairOrder?: {
        __typename?: 'OrderType';
        user: {
          __typename?: 'UserType';
          first_name: string;
          last_name?: string | null;
          email?: string | null;
          username?: string | null;
          uuid: string;
          phone?: string | null;
        };
      } | null;
    }[];
  };
}

export type OrdersCountQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<OrderStatusEnum[] | OrderStatusEnum>;
}>;

export interface OrdersCountQuery {
  __typename?: 'Query';
  ordersList: { __typename?: 'OrdersResponse'; count: number };
}

export type GiveOrdersMutationVariables = Exact<{
  usersUuids: Scalars['UUID']['input'][] | Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  productUuid: Scalars['UUID']['input'];
  params?: InputMaybe<OrdersActionNotifyParams>;
}>;

export interface GiveOrdersMutation {
  __typename?: 'Mutation';
  giveOrders: {
    __typename?: 'GiveOrderResultType';
    status: boolean;
    uuidUser: string;
    error?: Errors | null;
  }[];
}

export type EventInviteTextsQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface EventInviteTextsQuery {
  __typename?: 'Query';
  product: {
    __typename?: 'ProductType';
    inviteOrderSuccessText: string;
    inviteOrderText: string;
  };
}

export type CreateInvitesOrdersMutationVariables = Exact<{
  usersUuids: Scalars['UUID']['input'][] | Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  productUuid: Scalars['UUID']['input'];
  params?: InputMaybe<OrdersActionNotifyParams>;
}>;

export interface CreateInvitesOrdersMutation {
  __typename?: 'Mutation';
  createInviteOrders: {
    __typename?: 'OrderInviteResultType';
    status: boolean;
    uuidUser: string;
    error?: Errors | null;
  }[];
}

export type ExportOrdersCsvMutationVariables = Exact<{
  productUuid: Scalars['UUID']['input'];
}>;

export interface ExportOrdersCsvMutation {
  __typename?: 'Mutation';
  exportOrdersCSV: { __typename?: 'ExportOrdersCSVType'; url: string };
}

export type ExportFinanceCsvMutationVariables = Exact<{
  organizerId: Scalars['Float']['input'];
}>;

export interface ExportFinanceCsvMutation {
  __typename?: 'Mutation';
  exportFinanceCSV: { __typename?: 'ExportOrdersCSVType'; url: string };
}

export type DisableOrderMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export interface DisableOrderMutation {
  __typename?: 'Mutation';
  disableOrder: { __typename?: 'OrderType'; uuid: string };
}

export type OrganizerQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export interface OrganizerQuery {
  __typename?: 'Query';
  organizer: {
    __typename?: 'OrganizerType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    id: number;
    image: string;
    name: string;
    query?: string | null;
    description: string;
    telegram: string;
    socials: {
      __typename?: 'OrganizerSocialType';
      image?: string | null;
      text?: string | null;
      vk?: string | null;
      telegram?: string | null;
      facebook?: string | null;
      youtube?: string | null;
      instagram?: string | null;
    };
    supplierInfo: {
      __typename?: 'OrganizerSupplierInfoType';
      name?: string | null;
      inn?: string | null;
      phone?: string | null;
    };
    orderInstructions: {
      __typename?: 'OrganizerOrderInstructionsType';
      transferToProduct: string;
      transferToUser: string;
      refund: string;
    };
  };
}

export type OrganizerFilesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export interface OrganizerFilesQuery {
  __typename?: 'Query';
  organizer: {
    __typename?: 'OrganizerType';
    files: {
      __typename?: 'OrganizerFilesType';
      oferta?: string | null;
      agent?: string | null;
      policy?: string | null;
    };
  };
}

export type UpdateOrganizerMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  input: OrganizerInput;
}>;

export interface UpdateOrganizerMutation {
  __typename?: 'Mutation';
  updateOrganizer: { __typename?: 'OrganizerType'; id: number };
}

export type OrganizerShortcodesQueryVariables = Exact<Record<string, never>>;

export interface OrganizerShortcodesQuery {
  __typename?: 'Query';
  organizerShortcodes: string[];
}

export interface PaymentAccountFieldsFragment {
  __typename?: 'PaymentAccountType';
  createdAt: number;
  updatedAt: number;
  deletedAt?: number | null;
  uuid: string;
  name: string;
  inn: string;
  bik: string;
  accountNumber: string;
}

export type PaymentAccountsListQueryVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
}>;

export interface PaymentAccountsListQuery {
  __typename?: 'Query';
  paymentsByOrganizer: {
    __typename?: 'PaymentAccountType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    uuid: string;
    name: string;
    inn: string;
    bik: string;
    accountNumber: string;
  }[];
}

export type EventsCountQueryVariables = Exact<{
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
}>;

export interface EventsCountQuery {
  __typename?: 'Query';
  productsCount: {
    __typename?: 'ProductCountsType';
    count: number;
    activeCount: number;
    archiveCount: number;
    draftCount: number;
  };
}

export type EventShortcodeQueryVariables = Exact<Record<string, never>>;

export interface EventShortcodeQuery {
  __typename?: 'Query';
  productShortcodes: string[];
}

export type EventsListQueryVariables = Exact<{
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
  status?: InputMaybe<StatusEnum[] | StatusEnum>;
}>;

export interface EventsListQuery {
  __typename?: 'Query';
  productsList: {
    __typename?: 'ProductsResponse';
    count: number;
    rows: {
      __typename?: 'ProductType';
      createdAt: number;
      updatedAt: number;
      uuid: string;
      status: StatusEnum;
      purchasedCount: number;
      visitedCount: number;
      receipts: number;
      name: string;
      query?: string | null;
      startedDate: string;
      endDate: string;
    }[];
  };
}

export type EventQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface EventQuery {
  __typename?: 'Query';
  product: {
    __typename?: 'ProductType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    img: string;
    name: string;
    description: string;
    address?: string | null;
    query?: string | null;
    startedDate: string;
    salesStartedText?: string | null;
    endDate: string;
    status: StatusEnum;
    successBuyText: string;
    tariffsText: string;
    tariffsEmptyText: string;
    tariffsUnavailableText: string;
    activeAcquiring: boolean;
    faq?: string | null;
    invoiceLifetime: any;
    salesStartedDate?: string | null;
    transferOrderToUserSuccessText: string;
    preview: {
      __typename?: 'PreviewProductType';
      active: boolean;
      url?: string | null;
      description?: string | null;
      backButtonText?: string | null;
    };
    route: {
      __typename?: 'RouteProductType';
      active: boolean;
      url?: string | null;
      description?: string | null;
      backButtonText?: string | null;
    };
    featureFlags: {
      __typename?: 'ProductFeatureFlagsType';
      durationBeforeStartForRefundOrder: any;
      durationBeforeStartForTransferToUserOrder: any;
      allowedNumberForTransferToProductOrder: number;
    };
  };
}

export type BaseEventTextsQueryVariables = Exact<Record<string, never>>;

export interface BaseEventTextsQuery {
  __typename?: 'Query';
  baseTexts: {
    __typename?: 'BaseTextsType';
    key: BaseTextsKeysEnum;
    text: string;
  }[];
}

export type ArchiveEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface ArchiveEventMutation {
  __typename?: 'Mutation';
  archiveProduct: any;
}

export type PublishEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface PublishEventMutation {
  __typename?: 'Mutation';
  activateProduct: any;
}

export type RemoveEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface RemoveEventMutation {
  __typename?: 'Mutation';
  removeProduct: any;
}

export type UpdateEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: ProductInput;
}>;

export interface UpdateEventMutation {
  __typename?: 'Mutation';
  updateProduct: { __typename?: 'ProductType'; uuid: string };
}

export type UpdateEventInviteTextsMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: ProductInviteTextsInput;
}>;

export interface UpdateEventInviteTextsMutation {
  __typename?: 'Mutation';
  updateProductInviteTexts: { __typename?: 'ProductType'; uuid: string };
}

export type DuplicateProductMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface DuplicateProductMutation {
  __typename?: 'Mutation';
  duplicateProduct: { __typename?: 'ProductType'; uuid: string };
}

export type CreateEventMutationVariables = Exact<{
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  input: ProductInput;
  organizerId: Scalars['Float']['input'];
}>;

export interface CreateEventMutation {
  __typename?: 'Mutation';
  createProduct: { __typename?: 'ProductType'; uuid: string };
}

export interface SubTariffFieldsFragment {
  __typename?: 'SubTariffType';
  createdAt: number;
  updatedAt: number;
  uuid: string;
  name?: string | null;
  price?: number | null;
  specialPrice?: number | null;
  description?: string | null;
  query?: string | null;
  ordersCount?: number | null;
  startedDate?: string | null;
  endDate?: string | null;
  status: StatusEnum;
  paidOrdersCount: number;
  availableOrdersCount?: number | null;
}

export type SubTariffsListQueryVariables = Exact<{
  productUuid: Scalars['UUID']['input'];
  status?: InputMaybe<StatusEnum[] | StatusEnum>;
  filters?: InputMaybe<SubTariffListFilterInput>;
}>;

export interface SubTariffsListQuery {
  __typename?: 'Query';
  subTariffsList: {
    __typename?: 'SubTariffsResponse';
    rows: {
      __typename?: 'SubTariffType';
      uuid: string;
      name?: string | null;
      startedDate?: string | null;
      endDate?: string | null;
      status: StatusEnum;
      ordersCount?: number | null;
      availableOrdersCount?: number | null;
      paidOrdersCount: number;
      baseTariff?: {
        __typename?: 'BaseTariffType';
        name: string;
        type: TariffTypeEnum;
        ordersCount?: number | null;
      } | null;
    }[];
  };
}

export type SubTariffQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface SubTariffQuery {
  __typename?: 'Query';
  subTariff: {
    __typename?: 'SubTariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name?: string | null;
    price?: number | null;
    specialPrice?: number | null;
    description?: string | null;
    query?: string | null;
    ordersCount?: number | null;
    startedDate?: string | null;
    endDate?: string | null;
    status: StatusEnum;
    paidOrdersCount: number;
    availableOrdersCount?: number | null;
    baseTariff?: {
      __typename?: 'BaseTariffType';
      createdAt: number;
      updatedAt: number;
      uuid: string;
      name: string;
      description?: string | null;
      query?: string | null;
      ordersCount?: number | null;
      price: number;
      visible: boolean;
      status: StatusEnum;
      type: TariffTypeEnum;
      countSubTariffs: number;
      featureFlags: {
        __typename?: 'TariffFeatureFlagsType';
        bonusPay: boolean;
        refundOrder: boolean;
        transferToUserOrder: boolean;
        transferToProductOrder: boolean;
        repeatBuyOrder: boolean;
      };
    } | null;
  };
}

export type CreateTariffMutationVariables = Exact<{
  input: SubTariffInput;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  uuidParentTariff: Scalars['UUID']['input'];
}>;

export interface CreateTariffMutation {
  __typename?: 'Mutation';
  createTariff: {
    __typename?: 'SubTariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name?: string | null;
    price?: number | null;
    specialPrice?: number | null;
    description?: string | null;
    query?: string | null;
    ordersCount?: number | null;
    startedDate?: string | null;
    endDate?: string | null;
    status: StatusEnum;
    paidOrdersCount: number;
    availableOrdersCount?: number | null;
    baseTariff?: {
      __typename?: 'BaseTariffType';
      createdAt: number;
      updatedAt: number;
      uuid: string;
      name: string;
      description?: string | null;
      query?: string | null;
      ordersCount?: number | null;
      price: number;
      visible: boolean;
      status: StatusEnum;
      type: TariffTypeEnum;
      countSubTariffs: number;
      featureFlags: {
        __typename?: 'TariffFeatureFlagsType';
        bonusPay: boolean;
        refundOrder: boolean;
        transferToUserOrder: boolean;
        transferToProductOrder: boolean;
        repeatBuyOrder: boolean;
      };
    } | null;
  };
}

export type UpdateTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: SubTariffInput;
}>;

export interface UpdateTariffMutation {
  __typename?: 'Mutation';
  updateTariff: {
    __typename?: 'SubTariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name?: string | null;
    price?: number | null;
    specialPrice?: number | null;
    description?: string | null;
    query?: string | null;
    ordersCount?: number | null;
    startedDate?: string | null;
    endDate?: string | null;
    status: StatusEnum;
    paidOrdersCount: number;
    availableOrdersCount?: number | null;
    baseTariff?: {
      __typename?: 'BaseTariffType';
      createdAt: number;
      updatedAt: number;
      uuid: string;
      name: string;
      description?: string | null;
      query?: string | null;
      ordersCount?: number | null;
      price: number;
      visible: boolean;
      status: StatusEnum;
      type: TariffTypeEnum;
      countSubTariffs: number;
      featureFlags: {
        __typename?: 'TariffFeatureFlagsType';
        bonusPay: boolean;
        refundOrder: boolean;
        transferToUserOrder: boolean;
        transferToProductOrder: boolean;
        repeatBuyOrder: boolean;
      };
    } | null;
  };
}

export type RemoveTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface RemoveTariffMutation {
  __typename?: 'Mutation';
  removeTariff: any;
}

export type UpdateProductTariffsMutationVariables = Exact<{
  uuids: Scalars['UUID']['input'][] | Scalars['UUID']['input'];
  productUuid: Scalars['UUID']['input'];
}>;

export interface UpdateProductTariffsMutation {
  __typename?: 'Mutation';
  updateProductTariffs: any;
}

export interface UserGroupFieldsFragment {
  __typename?: 'UserGroupType';
  createdAt: number;
  updatedAt: number;
  uuid: string;
  name: string;
  usersCount: number;
}

export interface UserListFieldsFragment {
  __typename?: 'UsersResponse';
  count: number;
  rows: {
    __typename?: 'UserType';
    createdAt: number;
    uuid: string;
    first_name: string;
    last_name?: string | null;
    username?: string | null;
    phone?: string | null;
    email?: string | null;
    gender?: GenderEnum | null;
    receipts: number;
    activatedOrdersCount: number;
    ordersCount: number;
    balance: number;
    isSpecial: boolean;
    isBlocked: boolean;
    hasInviteByProduct: boolean;
    hasOrderByProduct: boolean;
  }[];
}

export type UsersListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Float']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface UsersListQuery {
  __typename?: 'Query';
  users: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      createdAt: number;
      uuid: string;
      first_name: string;
      last_name?: string | null;
      username?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: GenderEnum | null;
      receipts: number;
      activatedOrdersCount: number;
      ordersCount: number;
      balance: number;
      isSpecial: boolean;
      isBlocked: boolean;
      hasInviteByProduct: boolean;
      hasOrderByProduct: boolean;
    }[];
  };
}

export type UsersSpecialListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Float']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface UsersSpecialListQuery {
  __typename?: 'Query';
  specialUsers: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      createdAt: number;
      uuid: string;
      first_name: string;
      last_name?: string | null;
      username?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: GenderEnum | null;
      receipts: number;
      activatedOrdersCount: number;
      ordersCount: number;
      balance: number;
      isSpecial: boolean;
      isBlocked: boolean;
      hasInviteByProduct: boolean;
      hasOrderByProduct: boolean;
    }[];
  };
}

export type UsersBlockedListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Float']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface UsersBlockedListQuery {
  __typename?: 'Query';
  blacklist: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      createdAt: number;
      uuid: string;
      first_name: string;
      last_name?: string | null;
      username?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: GenderEnum | null;
      receipts: number;
      activatedOrdersCount: number;
      ordersCount: number;
      balance: number;
      isSpecial: boolean;
      isBlocked: boolean;
      hasInviteByProduct: boolean;
      hasOrderByProduct: boolean;
    }[];
  };
}

export type UsersRegisteredListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Float']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface UsersRegisteredListQuery {
  __typename?: 'Query';
  users: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      createdAt: number;
      uuid: string;
      first_name: string;
      last_name?: string | null;
      username?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: GenderEnum | null;
      receipts: number;
      activatedOrdersCount: number;
      ordersCount: number;
      balance: number;
      isSpecial: boolean;
      isBlocked: boolean;
      hasInviteByProduct: boolean;
      hasOrderByProduct: boolean;
    }[];
  };
}

export type UsersByGroupListQueryVariables = Exact<{
  filters: UserListFilterInput;
  groupUuid: Scalars['UUID']['input'];
  organizerId: Scalars['Float']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface UsersByGroupListQuery {
  __typename?: 'Query';
  usersByGroup: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      createdAt: number;
      uuid: string;
      first_name: string;
      last_name?: string | null;
      username?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: GenderEnum | null;
      receipts: number;
      activatedOrdersCount: number;
      ordersCount: number;
      balance: number;
      isSpecial: boolean;
      isBlocked: boolean;
      hasInviteByProduct: boolean;
      hasOrderByProduct: boolean;
    }[];
  };
}

export type UsersSpecialCountQueryVariables = Exact<{
  organizerId: Scalars['Float']['input'];
}>;

export interface UsersSpecialCountQuery {
  __typename?: 'Query';
  specialUsers: { __typename?: 'UsersResponse'; count: number };
}

export type UsersRegisteredCountQueryVariables = Exact<{
  organizerId: Scalars['Float']['input'];
}>;

export interface UsersRegisteredCountQuery {
  __typename?: 'Query';
  users: { __typename?: 'UsersResponse'; count: number };
}

export type UsersBlockedCountQueryVariables = Exact<{
  organizerId: Scalars['Float']['input'];
}>;

export interface UsersBlockedCountQuery {
  __typename?: 'Query';
  blacklist: { __typename?: 'UsersResponse'; count: number };
}

export type UserGroupsQueryVariables = Exact<{
  filters?: InputMaybe<UserGroupsListFilterInput>;
  idOrganizer: Scalars['Float']['input'];
}>;

export interface UserGroupsQuery {
  __typename?: 'Query';
  userGroups: {
    __typename?: 'UserGroupsResponse';
    count: number;
    rows: {
      __typename?: 'UserGroupType';
      createdAt: number;
      updatedAt: number;
      uuid: string;
      name: string;
      usersCount: number;
    }[];
  };
}

export type AddGroupUsersMutationVariables = Exact<{
  idOrganizer: Scalars['Float']['input'];
  name: Scalars['String']['input'];
}>;

export interface AddGroupUsersMutation {
  __typename?: 'Mutation';
  crateUserGroup: {
    __typename?: 'UserGroupType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name: string;
    usersCount: number;
  };
}

export type UpdateGroupUsersNameMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
}>;

export interface UpdateGroupUsersNameMutation {
  __typename?: 'Mutation';
  updateUserGroupName: {
    __typename?: 'UserGroupType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name: string;
    usersCount: number;
  };
}

export type RemoveGroupUsersMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface RemoveGroupUsersMutation {
  __typename?: 'Mutation';
  removeUserGroup: any;
}

export type AddUserToGroupMutationVariables = Exact<{
  uuidUser: Scalars['UUID']['input'];
  uuidGroup: Scalars['UUID']['input'];
}>;

export interface AddUserToGroupMutation {
  __typename?: 'Mutation';
  addUserToGroup: any;
}

export type RemoveUserFromGroupMutationVariables = Exact<{
  uuidUser: Scalars['UUID']['input'];
  uuidGroup: Scalars['UUID']['input'];
}>;

export interface RemoveUserFromGroupMutation {
  __typename?: 'Mutation';
  removeUserFromGroup: any;
}

export type AddUserToSpecialMutationVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}>;

export interface AddUserToSpecialMutation {
  __typename?: 'Mutation';
  addUserToSpecial: { __typename?: 'UserSpecialType'; uuid: string };
}

export type RemoveUserFromSpecialMutationVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}>;

export interface RemoveUserFromSpecialMutation {
  __typename?: 'Mutation';
  removeUserFromSpecial: any;
}

export type BlockUserMutationVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}>;

export interface BlockUserMutation {
  __typename?: 'Mutation';
  addUserToBlacklist: any;
}

export type UnblockUserMutationVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}>;

export interface UnblockUserMutation {
  __typename?: 'Mutation';
  removeUserFromBlacklist: any;
}

export type UpdateUsersInGroupMutationVariables = Exact<{
  uuidGroup: Scalars['UUID']['input'];
  uuidUsers: Scalars['UUID']['input'][] | Scalars['UUID']['input'];
}>;

export interface UpdateUsersInGroupMutation {
  __typename?: 'Mutation';
  updateUsersInGroup: any;
}

export type UserQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  organizerId: Scalars['Float']['input'];
}>;

export interface UserQuery {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    uuid: string;
    tgId?: number | null;
    username?: string | null;
    first_name: string;
    last_name?: string | null;
    language: LanguageEnum;
    role: RoleEnum;
    email?: string | null;
    phone?: string | null;
    ordersCount: number;
    activatedOrdersCount: number;
    receipts: number;
    gender?: GenderEnum | null;
    balance: number;
    isSpecial: boolean;
    isBlocked: boolean;
  } | null;
}

export type UsersStatsQueryVariables = Exact<{
  organizerId?: InputMaybe<Scalars['Float']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export interface UsersStatsQuery {
  __typename?: 'Query';
  usersStats: {
    __typename?: 'UsersStatsType';
    count: number;
    maleCount: number;
    femaleCount: number;
  };
}

export type UpdateUserMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateUserInput;
}>;

export interface UpdateUserMutation {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'UserType'; uuid: string };
}

export interface WithdrawalFieldsFragment {
  __typename?: 'WithdrawalType';
  createdAt: number;
  updatedAt: number;
  deletedAt?: number | null;
  uuid: string;
  amount: number;
  status: WithdrawalStatusEnum;
  organizer: { __typename?: 'OrganizerType'; name: string };
}

export type WithdrawalsListQueryVariables = Exact<{
  filter?: InputMaybe<WithdrawalListFilterInput>;
  idOrganizer: Scalars['Int']['input'];
}>;

export interface WithdrawalsListQuery {
  __typename?: 'Query';
  withdrawalsByOrganizer: {
    __typename?: 'WithdrawalsResponse';
    count: number;
    rows: {
      __typename?: 'WithdrawalType';
      createdAt: number;
      updatedAt: number;
      deletedAt?: number | null;
      uuid: string;
      amount: number;
      status: WithdrawalStatusEnum;
      paymentAccount: {
        __typename?: 'PaymentAccountType';
        createdAt: number;
        updatedAt: number;
        deletedAt?: number | null;
        uuid: string;
        name: string;
        inn: string;
        bik: string;
        accountNumber: string;
      };
      organizer: { __typename?: 'OrganizerType'; name: string };
    }[];
  };
}

export type WithdrawalRequestMutationVariables = Exact<{
  input: WithdrawalOrganizerInput;
}>;

export interface WithdrawalRequestMutation {
  __typename?: 'Mutation';
  requestWithdrawal: any;
}

export type CancelWithdrawalMutationVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
}>;

export interface CancelWithdrawalMutation {
  __typename?: 'Mutation';
  cancelWithdrawal: any;
}

export const JwtFieldsFragmentDoc = gql`
  fragment JwtFields on JwtType {
    access_token
    refresh_token
  }
`;
export const BaseTariffFieldsFragmentDoc = gql`
  fragment BaseTariffFields on BaseTariffType {
    createdAt
    updatedAt
    uuid
    name
    description
    query
    ordersCount
    price
    visible
    status
    type
    featureFlags {
      bonusPay
      refundOrder
      transferToUserOrder
      transferToProductOrder
      repeatBuyOrder
    }
    countSubTariffs
  }
`;
export const PaymentAccountFieldsFragmentDoc = gql`
  fragment PaymentAccountFields on PaymentAccountType {
    createdAt
    updatedAt
    deletedAt
    uuid
    name
    inn
    bik
    accountNumber
  }
`;
export const SubTariffFieldsFragmentDoc = gql`
  fragment SubTariffFields on SubTariffType {
    createdAt
    updatedAt
    uuid
    name
    price
    specialPrice
    description
    query
    ordersCount
    startedDate
    endDate
    status
    paidOrdersCount
    availableOrdersCount
  }
`;
export const UserGroupFieldsFragmentDoc = gql`
  fragment UserGroupFields on UserGroupType {
    createdAt
    updatedAt
    uuid
    name
    usersCount
  }
`;
export const UserListFieldsFragmentDoc = gql`
  fragment UserListFields on UsersResponse {
    rows {
      createdAt
      uuid
      first_name
      last_name
      username
      phone
      email
      gender
      receipts
      activatedOrdersCount
      ordersCount
      balance(idOrganizer: $organizerId)
      isSpecial(idOrganizer: $organizerId)
      isBlocked(organizerId: $organizerId)
      hasInviteByProduct(productUuid: $productUuid)
      hasOrderByProduct(productUuid: $productUuid)
    }
    count
  }
`;
export const WithdrawalFieldsFragmentDoc = gql`
  fragment WithdrawalFields on WithdrawalType {
    createdAt
    updatedAt
    deletedAt
    uuid
    amount
    status
    organizer {
      name
    }
  }
`;
export const LoginDocument = gql`
  mutation login($login: String!, $password: String!) {
    login(input: { login: $login, password: $password }) {
      ...JwtFields
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RefreshDocument = gql`
  mutation refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      ...JwtFields
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type RefreshMutationFn = Apollo.MutationFunction<
  RefreshMutation,
  RefreshMutationVariables
>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *   },
 * });
 */
export function useRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshMutation,
    RefreshMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(
    RefreshDocument,
    options,
  );
}
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<
  RefreshMutation,
  RefreshMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      uuid
      role
      username
      controlOrganizerId
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    options,
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const BaseTariffsListDocument = gql`
  query baseTariffsList(
    $filters: BaseTariffListFilterInput
    $status: [StatusEnum!]
    $idOrganizer: Int!
  ) {
    baseTariffsList(
      status: $status
      filters: $filters
      idOrganizer: $idOrganizer
    ) {
      rows {
        ...BaseTariffFields
      }
      count
    }
  }
  ${BaseTariffFieldsFragmentDoc}
`;

/**
 * __useBaseTariffsListQuery__
 *
 * To run a query within a React component, call `useBaseTariffsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseTariffsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseTariffsListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      status: // value for 'status'
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useBaseTariffsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BaseTariffsListQuery,
    BaseTariffsListQueryVariables
  > &
    (
      | { variables: BaseTariffsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BaseTariffsListQuery, BaseTariffsListQueryVariables>(
    BaseTariffsListDocument,
    options,
  );
}
export function useBaseTariffsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BaseTariffsListQuery,
    BaseTariffsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    BaseTariffsListQuery,
    BaseTariffsListQueryVariables
  >(BaseTariffsListDocument, options);
}
export function useBaseTariffsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BaseTariffsListQuery,
    BaseTariffsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    BaseTariffsListQuery,
    BaseTariffsListQueryVariables
  >(BaseTariffsListDocument, options);
}
export type BaseTariffsListQueryHookResult = ReturnType<
  typeof useBaseTariffsListQuery
>;
export type BaseTariffsListLazyQueryHookResult = ReturnType<
  typeof useBaseTariffsListLazyQuery
>;
export type BaseTariffsListSuspenseQueryHookResult = ReturnType<
  typeof useBaseTariffsListSuspenseQuery
>;
export type BaseTariffsListQueryResult = Apollo.QueryResult<
  BaseTariffsListQuery,
  BaseTariffsListQueryVariables
>;
export const BaseTariffDocument = gql`
  query baseTariff($uuid: UUID!) {
    baseTariff(uuid: $uuid) {
      ...BaseTariffFields
    }
  }
  ${BaseTariffFieldsFragmentDoc}
`;

/**
 * __useBaseTariffQuery__
 *
 * To run a query within a React component, call `useBaseTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseTariffQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useBaseTariffQuery(
  baseOptions: Apollo.QueryHookOptions<
    BaseTariffQuery,
    BaseTariffQueryVariables
  > &
    (
      | { variables: BaseTariffQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BaseTariffQuery, BaseTariffQueryVariables>(
    BaseTariffDocument,
    options,
  );
}
export function useBaseTariffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BaseTariffQuery,
    BaseTariffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BaseTariffQuery, BaseTariffQueryVariables>(
    BaseTariffDocument,
    options,
  );
}
export function useBaseTariffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BaseTariffQuery,
    BaseTariffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<BaseTariffQuery, BaseTariffQueryVariables>(
    BaseTariffDocument,
    options,
  );
}
export type BaseTariffQueryHookResult = ReturnType<typeof useBaseTariffQuery>;
export type BaseTariffLazyQueryHookResult = ReturnType<
  typeof useBaseTariffLazyQuery
>;
export type BaseTariffSuspenseQueryHookResult = ReturnType<
  typeof useBaseTariffSuspenseQuery
>;
export type BaseTariffQueryResult = Apollo.QueryResult<
  BaseTariffQuery,
  BaseTariffQueryVariables
>;
export const AllAllowedUsersForBaseTariffDocument = gql`
  query allAllowedUsersForBaseTariff($uuid: UUID!) {
    allAllowedUsersForBaseTariff(uuid: $uuid) {
      uuid
    }
  }
`;

/**
 * __useAllAllowedUsersForBaseTariffQuery__
 *
 * To run a query within a React component, call `useAllAllowedUsersForBaseTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAllowedUsersForBaseTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAllowedUsersForBaseTariffQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAllAllowedUsersForBaseTariffQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllAllowedUsersForBaseTariffQuery,
    AllAllowedUsersForBaseTariffQueryVariables
  > &
    (
      | {
          variables: AllAllowedUsersForBaseTariffQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    AllAllowedUsersForBaseTariffQuery,
    AllAllowedUsersForBaseTariffQueryVariables
  >(AllAllowedUsersForBaseTariffDocument, options);
}
export function useAllAllowedUsersForBaseTariffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAllowedUsersForBaseTariffQuery,
    AllAllowedUsersForBaseTariffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    AllAllowedUsersForBaseTariffQuery,
    AllAllowedUsersForBaseTariffQueryVariables
  >(AllAllowedUsersForBaseTariffDocument, options);
}
export function useAllAllowedUsersForBaseTariffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllAllowedUsersForBaseTariffQuery,
    AllAllowedUsersForBaseTariffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    AllAllowedUsersForBaseTariffQuery,
    AllAllowedUsersForBaseTariffQueryVariables
  >(AllAllowedUsersForBaseTariffDocument, options);
}
export type AllAllowedUsersForBaseTariffQueryHookResult = ReturnType<
  typeof useAllAllowedUsersForBaseTariffQuery
>;
export type AllAllowedUsersForBaseTariffLazyQueryHookResult = ReturnType<
  typeof useAllAllowedUsersForBaseTariffLazyQuery
>;
export type AllAllowedUsersForBaseTariffSuspenseQueryHookResult = ReturnType<
  typeof useAllAllowedUsersForBaseTariffSuspenseQuery
>;
export type AllAllowedUsersForBaseTariffQueryResult = Apollo.QueryResult<
  AllAllowedUsersForBaseTariffQuery,
  AllAllowedUsersForBaseTariffQueryVariables
>;
export const CreateBaseTariffDocument = gql`
  mutation createBaseTariff($input: BaseTariffInput!, $idOrganizer: Int!) {
    createBaseTariff(input: $input, idOrganizer: $idOrganizer) {
      ...BaseTariffFields
    }
  }
  ${BaseTariffFieldsFragmentDoc}
`;
export type CreateBaseTariffMutationFn = Apollo.MutationFunction<
  CreateBaseTariffMutation,
  CreateBaseTariffMutationVariables
>;

/**
 * __useCreateBaseTariffMutation__
 *
 * To run a mutation, you first call `useCreateBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaseTariffMutation, { data, loading, error }] = useCreateBaseTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useCreateBaseTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBaseTariffMutation,
    CreateBaseTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CreateBaseTariffMutation,
    CreateBaseTariffMutationVariables
  >(CreateBaseTariffDocument, options);
}
export type CreateBaseTariffMutationHookResult = ReturnType<
  typeof useCreateBaseTariffMutation
>;
export type CreateBaseTariffMutationResult =
  Apollo.MutationResult<CreateBaseTariffMutation>;
export type CreateBaseTariffMutationOptions = Apollo.BaseMutationOptions<
  CreateBaseTariffMutation,
  CreateBaseTariffMutationVariables
>;
export const UpdateBaseTariffDocument = gql`
  mutation updateBaseTariff($uuid: UUID!, $input: BaseTariffInput!) {
    updateBaseTariff(input: $input, uuid: $uuid) {
      ...BaseTariffFields
    }
  }
  ${BaseTariffFieldsFragmentDoc}
`;
export type UpdateBaseTariffMutationFn = Apollo.MutationFunction<
  UpdateBaseTariffMutation,
  UpdateBaseTariffMutationVariables
>;

/**
 * __useUpdateBaseTariffMutation__
 *
 * To run a mutation, you first call `useUpdateBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBaseTariffMutation, { data, loading, error }] = useUpdateBaseTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBaseTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBaseTariffMutation,
    UpdateBaseTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateBaseTariffMutation,
    UpdateBaseTariffMutationVariables
  >(UpdateBaseTariffDocument, options);
}
export type UpdateBaseTariffMutationHookResult = ReturnType<
  typeof useUpdateBaseTariffMutation
>;
export type UpdateBaseTariffMutationResult =
  Apollo.MutationResult<UpdateBaseTariffMutation>;
export type UpdateBaseTariffMutationOptions = Apollo.BaseMutationOptions<
  UpdateBaseTariffMutation,
  UpdateBaseTariffMutationVariables
>;
export const DuplicateBaseTariffDocument = gql`
  mutation duplicateBaseTariff($uuid: UUID!) {
    duplicateBaseTariff(uuid: $uuid) {
      ...BaseTariffFields
    }
  }
  ${BaseTariffFieldsFragmentDoc}
`;
export type DuplicateBaseTariffMutationFn = Apollo.MutationFunction<
  DuplicateBaseTariffMutation,
  DuplicateBaseTariffMutationVariables
>;

/**
 * __useDuplicateBaseTariffMutation__
 *
 * To run a mutation, you first call `useDuplicateBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateBaseTariffMutation, { data, loading, error }] = useDuplicateBaseTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDuplicateBaseTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateBaseTariffMutation,
    DuplicateBaseTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    DuplicateBaseTariffMutation,
    DuplicateBaseTariffMutationVariables
  >(DuplicateBaseTariffDocument, options);
}
export type DuplicateBaseTariffMutationHookResult = ReturnType<
  typeof useDuplicateBaseTariffMutation
>;
export type DuplicateBaseTariffMutationResult =
  Apollo.MutationResult<DuplicateBaseTariffMutation>;
export type DuplicateBaseTariffMutationOptions = Apollo.BaseMutationOptions<
  DuplicateBaseTariffMutation,
  DuplicateBaseTariffMutationVariables
>;
export const RemoveBaseTariffDocument = gql`
  mutation removeBaseTariff($uuid: UUID!) {
    removeBaseTariff(uuid: $uuid)
  }
`;
export type RemoveBaseTariffMutationFn = Apollo.MutationFunction<
  RemoveBaseTariffMutation,
  RemoveBaseTariffMutationVariables
>;

/**
 * __useRemoveBaseTariffMutation__
 *
 * To run a mutation, you first call `useRemoveBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBaseTariffMutation, { data, loading, error }] = useRemoveBaseTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveBaseTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBaseTariffMutation,
    RemoveBaseTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    RemoveBaseTariffMutation,
    RemoveBaseTariffMutationVariables
  >(RemoveBaseTariffDocument, options);
}
export type RemoveBaseTariffMutationHookResult = ReturnType<
  typeof useRemoveBaseTariffMutation
>;
export type RemoveBaseTariffMutationResult =
  Apollo.MutationResult<RemoveBaseTariffMutation>;
export type RemoveBaseTariffMutationOptions = Apollo.BaseMutationOptions<
  RemoveBaseTariffMutation,
  RemoveBaseTariffMutationVariables
>;
export const UpdateUsersBaseTariffDocument = gql`
  mutation updateUsersBaseTariff($uuid: UUID!, $userUuids: [UUID!]!) {
    updateUsersBaseTariff(uuid: $uuid, usersUuid: $userUuids)
  }
`;
export type UpdateUsersBaseTariffMutationFn = Apollo.MutationFunction<
  UpdateUsersBaseTariffMutation,
  UpdateUsersBaseTariffMutationVariables
>;

/**
 * __useUpdateUsersBaseTariffMutation__
 *
 * To run a mutation, you first call `useUpdateUsersBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersBaseTariffMutation, { data, loading, error }] = useUpdateUsersBaseTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      userUuids: // value for 'userUuids'
 *   },
 * });
 */
export function useUpdateUsersBaseTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUsersBaseTariffMutation,
    UpdateUsersBaseTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateUsersBaseTariffMutation,
    UpdateUsersBaseTariffMutationVariables
  >(UpdateUsersBaseTariffDocument, options);
}
export type UpdateUsersBaseTariffMutationHookResult = ReturnType<
  typeof useUpdateUsersBaseTariffMutation
>;
export type UpdateUsersBaseTariffMutationResult =
  Apollo.MutationResult<UpdateUsersBaseTariffMutation>;
export type UpdateUsersBaseTariffMutationOptions = Apollo.BaseMutationOptions<
  UpdateUsersBaseTariffMutation,
  UpdateUsersBaseTariffMutationVariables
>;
export const UploadImagesDocument = gql`
  mutation uploadImages($images: [Upload!]!) {
    uploadImages(image: $images) {
      url
    }
  }
`;
export type UploadImagesMutationFn = Apollo.MutationFunction<
  UploadImagesMutation,
  UploadImagesMutationVariables
>;

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      images: // value for 'images'
 *   },
 * });
 */
export function useUploadImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadImagesMutation,
    UploadImagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UploadImagesMutation,
    UploadImagesMutationVariables
  >(UploadImagesDocument, options);
}
export type UploadImagesMutationHookResult = ReturnType<
  typeof useUploadImagesMutation
>;
export type UploadImagesMutationResult =
  Apollo.MutationResult<UploadImagesMutation>;
export type UploadImagesMutationOptions = Apollo.BaseMutationOptions<
  UploadImagesMutation,
  UploadImagesMutationVariables
>;
export const FinanceStatsDocument = gql`
  query financeStats($organizerId: Int!) {
    paymentsList(
      filters: { pagination: { page: 1, pageSize: 1 } }
      args: { organizerId: $organizerId }
    ) {
      count
    }
    financeStats(organizerId: $organizerId) {
      profit
      totalCommission
      totalReceived
      totalWithdrawals
    }
    organizer(id: $organizerId) {
      balance
    }
  }
`;

/**
 * __useFinanceStatsQuery__
 *
 * To run a query within a React component, call `useFinanceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useFinanceStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinanceStatsQuery,
    FinanceStatsQueryVariables
  > &
    (
      | { variables: FinanceStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(
    FinanceStatsDocument,
    options,
  );
}
export function useFinanceStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinanceStatsQuery,
    FinanceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(
    FinanceStatsDocument,
    options,
  );
}
export function useFinanceStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FinanceStatsQuery,
    FinanceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(
    FinanceStatsDocument,
    options,
  );
}
export type FinanceStatsQueryHookResult = ReturnType<
  typeof useFinanceStatsQuery
>;
export type FinanceStatsLazyQueryHookResult = ReturnType<
  typeof useFinanceStatsLazyQuery
>;
export type FinanceStatsSuspenseQueryHookResult = ReturnType<
  typeof useFinanceStatsSuspenseQuery
>;
export type FinanceStatsQueryResult = Apollo.QueryResult<
  FinanceStatsQuery,
  FinanceStatsQueryVariables
>;
export const PurchaseStatsDocument = gql`
  query purchaseStats($organizerId: Float!, $uuidProduct: UUID) {
    purchasesStats(organizerId: $organizerId, uuidProduct: $uuidProduct) {
      usersCounts {
        all
        female
        male
      }
      profit
    }
  }
`;

/**
 * __usePurchaseStatsQuery__
 *
 * To run a query within a React component, call `usePurchaseStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function usePurchaseStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  > &
    (
      | { variables: PurchaseStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PurchaseStatsQuery, PurchaseStatsQueryVariables>(
    PurchaseStatsDocument,
    options,
  );
}
export function usePurchaseStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PurchaseStatsQuery, PurchaseStatsQueryVariables>(
    PurchaseStatsDocument,
    options,
  );
}
export function usePurchaseStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  >(PurchaseStatsDocument, options);
}
export type PurchaseStatsQueryHookResult = ReturnType<
  typeof usePurchaseStatsQuery
>;
export type PurchaseStatsLazyQueryHookResult = ReturnType<
  typeof usePurchaseStatsLazyQuery
>;
export type PurchaseStatsSuspenseQueryHookResult = ReturnType<
  typeof usePurchaseStatsSuspenseQuery
>;
export type PurchaseStatsQueryResult = Apollo.QueryResult<
  PurchaseStatsQuery,
  PurchaseStatsQueryVariables
>;
export const PaymentsListDocument = gql`
  query paymentsList(
    $filter: PaymentsListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $status: [PaymentStatusEnum!] = [Success]
  ) {
    paymentsList(
      filters: $filter
      args: { organizerId: $organizerId, uuidUser: $uuidUser }
      status: $status
    ) {
      count
      rows {
        uuid
        price
        createdAt
        payment_uuid
        status
        user {
          first_name
          last_name
          email
          username
          uuid
        }
        order {
          id
          status
          fixedHoldPercentage
          product {
            name
            startedDate
            endDate
          }
          userBalanceWithdrawal {
            amount
          }
          tariff {
            name
          }
          pairOrder {
            user {
              first_name
              last_name
              email
              username
              uuid
              phone
            }
          }
        }
      }
    }
  }
`;

/**
 * __usePaymentsListQuery__
 *
 * To run a query within a React component, call `usePaymentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePaymentsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentsListQuery,
    PaymentsListQueryVariables
  > &
    (
      | { variables: PaymentsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PaymentsListQuery, PaymentsListQueryVariables>(
    PaymentsListDocument,
    options,
  );
}
export function usePaymentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentsListQuery,
    PaymentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PaymentsListQuery, PaymentsListQueryVariables>(
    PaymentsListDocument,
    options,
  );
}
export function usePaymentsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaymentsListQuery,
    PaymentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<PaymentsListQuery, PaymentsListQueryVariables>(
    PaymentsListDocument,
    options,
  );
}
export type PaymentsListQueryHookResult = ReturnType<
  typeof usePaymentsListQuery
>;
export type PaymentsListLazyQueryHookResult = ReturnType<
  typeof usePaymentsListLazyQuery
>;
export type PaymentsListSuspenseQueryHookResult = ReturnType<
  typeof usePaymentsListSuspenseQuery
>;
export type PaymentsListQueryResult = Apollo.QueryResult<
  PaymentsListQuery,
  PaymentsListQueryVariables
>;
export const FinanceListsCountDocument = gql`
  query financeListsCount($idOrganizer: Int!) {
    paymentsList(
      args: { organizerId: $idOrganizer }
      filters: { pagination: { pageSize: 1, page: 1 } }
    ) {
      count
    }
    withdrawalsByOrganizer(
      idOrganizer: $idOrganizer
      filters: { pagination: { pageSize: 1, page: 1 } }
    ) {
      count
    }
  }
`;

/**
 * __useFinanceListsCountQuery__
 *
 * To run a query within a React component, call `useFinanceListsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceListsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceListsCountQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useFinanceListsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  > &
    (
      | { variables: FinanceListsCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >(FinanceListsCountDocument, options);
}
export function useFinanceListsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >(FinanceListsCountDocument, options);
}
export function useFinanceListsCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >(FinanceListsCountDocument, options);
}
export type FinanceListsCountQueryHookResult = ReturnType<
  typeof useFinanceListsCountQuery
>;
export type FinanceListsCountLazyQueryHookResult = ReturnType<
  typeof useFinanceListsCountLazyQuery
>;
export type FinanceListsCountSuspenseQueryHookResult = ReturnType<
  typeof useFinanceListsCountSuspenseQuery
>;
export type FinanceListsCountQueryResult = Apollo.QueryResult<
  FinanceListsCountQuery,
  FinanceListsCountQueryVariables
>;
export const OrdersListDocument = gql`
  query ordersList(
    $filter: OrdersListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $status: [OrderStatusEnum!]
  ) {
    ordersList(
      filters: $filter
      args: {
        organizerId: $organizerId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: $status }
    ) {
      count
      rows {
        createdAt
        id
        status
        fixedHoldPercentage
        payment {
          price
          status
        }
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
        product {
          name
          startedDate
          endDate
        }
        userBalanceWithdrawal {
          amount
        }
        tariff {
          name
        }
        pairOrder {
          user {
            first_name
            last_name
            email
            username
            uuid
            phone
          }
        }
      }
    }
  }
`;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrdersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  > &
    (
      | { variables: OrdersListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export function useOrdersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export function useOrdersListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<
  typeof useOrdersListLazyQuery
>;
export type OrdersListSuspenseQueryHookResult = ReturnType<
  typeof useOrdersListSuspenseQuery
>;
export type OrdersListQueryResult = Apollo.QueryResult<
  OrdersListQuery,
  OrdersListQueryVariables
>;
export const OrdersCountDocument = gql`
  query ordersCount(
    $filter: OrdersListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $status: [OrderStatusEnum!]
  ) {
    ordersList(
      filters: $filter
      args: {
        organizerId: $organizerId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: $status }
    ) {
      count
    }
  }
`;

/**
 * __useOrdersCountQuery__
 *
 * To run a query within a React component, call `useOrdersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrdersCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersCountQuery,
    OrdersCountQueryVariables
  > &
    (
      | { variables: OrdersCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrdersCountQuery, OrdersCountQueryVariables>(
    OrdersCountDocument,
    options,
  );
}
export function useOrdersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersCountQuery,
    OrdersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrdersCountQuery, OrdersCountQueryVariables>(
    OrdersCountDocument,
    options,
  );
}
export function useOrdersCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrdersCountQuery,
    OrdersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<OrdersCountQuery, OrdersCountQueryVariables>(
    OrdersCountDocument,
    options,
  );
}
export type OrdersCountQueryHookResult = ReturnType<typeof useOrdersCountQuery>;
export type OrdersCountLazyQueryHookResult = ReturnType<
  typeof useOrdersCountLazyQuery
>;
export type OrdersCountSuspenseQueryHookResult = ReturnType<
  typeof useOrdersCountSuspenseQuery
>;
export type OrdersCountQueryResult = Apollo.QueryResult<
  OrdersCountQuery,
  OrdersCountQueryVariables
>;
export const GiveOrdersDocument = gql`
  mutation giveOrders(
    $usersUuids: [UUID!]!
    $tariffUuid: UUID!
    $productUuid: UUID!
    $params: OrdersActionNotifyParams
  ) {
    giveOrders(
      usersUuids: $usersUuids
      tariffUuid: $tariffUuid
      productUuid: $productUuid
      params: $params
    ) {
      status
      uuidUser
      error
    }
  }
`;
export type GiveOrdersMutationFn = Apollo.MutationFunction<
  GiveOrdersMutation,
  GiveOrdersMutationVariables
>;

/**
 * __useGiveOrdersMutation__
 *
 * To run a mutation, you first call `useGiveOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveOrdersMutation, { data, loading, error }] = useGiveOrdersMutation({
 *   variables: {
 *      usersUuids: // value for 'usersUuids'
 *      tariffUuid: // value for 'tariffUuid'
 *      productUuid: // value for 'productUuid'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGiveOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GiveOrdersMutation,
    GiveOrdersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<GiveOrdersMutation, GiveOrdersMutationVariables>(
    GiveOrdersDocument,
    options,
  );
}
export type GiveOrdersMutationHookResult = ReturnType<
  typeof useGiveOrdersMutation
>;
export type GiveOrdersMutationResult =
  Apollo.MutationResult<GiveOrdersMutation>;
export type GiveOrdersMutationOptions = Apollo.BaseMutationOptions<
  GiveOrdersMutation,
  GiveOrdersMutationVariables
>;
export const EventInviteTextsDocument = gql`
  query eventInviteTexts($uuid: UUID!) {
    product(uuid: $uuid) {
      inviteOrderSuccessText(disableShortcode: true)
      inviteOrderText(disableShortcode: true)
    }
  }
`;

/**
 * __useEventInviteTextsQuery__
 *
 * To run a query within a React component, call `useEventInviteTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventInviteTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventInviteTextsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEventInviteTextsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventInviteTextsQuery,
    EventInviteTextsQueryVariables
  > &
    (
      | { variables: EventInviteTextsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventInviteTextsQuery, EventInviteTextsQueryVariables>(
    EventInviteTextsDocument,
    options,
  );
}
export function useEventInviteTextsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventInviteTextsQuery,
    EventInviteTextsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    EventInviteTextsQuery,
    EventInviteTextsQueryVariables
  >(EventInviteTextsDocument, options);
}
export function useEventInviteTextsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventInviteTextsQuery,
    EventInviteTextsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    EventInviteTextsQuery,
    EventInviteTextsQueryVariables
  >(EventInviteTextsDocument, options);
}
export type EventInviteTextsQueryHookResult = ReturnType<
  typeof useEventInviteTextsQuery
>;
export type EventInviteTextsLazyQueryHookResult = ReturnType<
  typeof useEventInviteTextsLazyQuery
>;
export type EventInviteTextsSuspenseQueryHookResult = ReturnType<
  typeof useEventInviteTextsSuspenseQuery
>;
export type EventInviteTextsQueryResult = Apollo.QueryResult<
  EventInviteTextsQuery,
  EventInviteTextsQueryVariables
>;
export const CreateInvitesOrdersDocument = gql`
  mutation createInvitesOrders(
    $usersUuids: [UUID!]!
    $tariffUuid: UUID!
    $productUuid: UUID!
    $params: OrdersActionNotifyParams
  ) {
    createInviteOrders(
      usersUuids: $usersUuids
      tariffUuid: $tariffUuid
      productUuid: $productUuid
      params: $params
    ) {
      status
      uuidUser
      error
    }
  }
`;
export type CreateInvitesOrdersMutationFn = Apollo.MutationFunction<
  CreateInvitesOrdersMutation,
  CreateInvitesOrdersMutationVariables
>;

/**
 * __useCreateInvitesOrdersMutation__
 *
 * To run a mutation, you first call `useCreateInvitesOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitesOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitesOrdersMutation, { data, loading, error }] = useCreateInvitesOrdersMutation({
 *   variables: {
 *      usersUuids: // value for 'usersUuids'
 *      tariffUuid: // value for 'tariffUuid'
 *      productUuid: // value for 'productUuid'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateInvitesOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvitesOrdersMutation,
    CreateInvitesOrdersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CreateInvitesOrdersMutation,
    CreateInvitesOrdersMutationVariables
  >(CreateInvitesOrdersDocument, options);
}
export type CreateInvitesOrdersMutationHookResult = ReturnType<
  typeof useCreateInvitesOrdersMutation
>;
export type CreateInvitesOrdersMutationResult =
  Apollo.MutationResult<CreateInvitesOrdersMutation>;
export type CreateInvitesOrdersMutationOptions = Apollo.BaseMutationOptions<
  CreateInvitesOrdersMutation,
  CreateInvitesOrdersMutationVariables
>;
export const ExportOrdersCsvDocument = gql`
  mutation exportOrdersCSV($productUuid: UUID!) {
    exportOrdersCSV(productUuid: $productUuid) {
      url
    }
  }
`;
export type ExportOrdersCsvMutationFn = Apollo.MutationFunction<
  ExportOrdersCsvMutation,
  ExportOrdersCsvMutationVariables
>;

/**
 * __useExportOrdersCsvMutation__
 *
 * To run a mutation, you first call `useExportOrdersCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportOrdersCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportOrdersCsvMutation, { data, loading, error }] = useExportOrdersCsvMutation({
 *   variables: {
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useExportOrdersCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportOrdersCsvMutation,
    ExportOrdersCsvMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    ExportOrdersCsvMutation,
    ExportOrdersCsvMutationVariables
  >(ExportOrdersCsvDocument, options);
}
export type ExportOrdersCsvMutationHookResult = ReturnType<
  typeof useExportOrdersCsvMutation
>;
export type ExportOrdersCsvMutationResult =
  Apollo.MutationResult<ExportOrdersCsvMutation>;
export type ExportOrdersCsvMutationOptions = Apollo.BaseMutationOptions<
  ExportOrdersCsvMutation,
  ExportOrdersCsvMutationVariables
>;
export const ExportFinanceCsvDocument = gql`
  mutation exportFinanceCSV($organizerId: Float!) {
    exportFinanceCSV(organizerId: $organizerId) {
      url
    }
  }
`;
export type ExportFinanceCsvMutationFn = Apollo.MutationFunction<
  ExportFinanceCsvMutation,
  ExportFinanceCsvMutationVariables
>;

/**
 * __useExportFinanceCsvMutation__
 *
 * To run a mutation, you first call `useExportFinanceCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportFinanceCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportFinanceCsvMutation, { data, loading, error }] = useExportFinanceCsvMutation({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useExportFinanceCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportFinanceCsvMutation,
    ExportFinanceCsvMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    ExportFinanceCsvMutation,
    ExportFinanceCsvMutationVariables
  >(ExportFinanceCsvDocument, options);
}
export type ExportFinanceCsvMutationHookResult = ReturnType<
  typeof useExportFinanceCsvMutation
>;
export type ExportFinanceCsvMutationResult =
  Apollo.MutationResult<ExportFinanceCsvMutation>;
export type ExportFinanceCsvMutationOptions = Apollo.BaseMutationOptions<
  ExportFinanceCsvMutation,
  ExportFinanceCsvMutationVariables
>;
export const DisableOrderDocument = gql`
  mutation disableOrder($id: Int!) {
    disableOrder(id: $id) {
      uuid
    }
  }
`;
export type DisableOrderMutationFn = Apollo.MutationFunction<
  DisableOrderMutation,
  DisableOrderMutationVariables
>;

/**
 * __useDisableOrderMutation__
 *
 * To run a mutation, you first call `useDisableOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableOrderMutation, { data, loading, error }] = useDisableOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableOrderMutation,
    DisableOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    DisableOrderMutation,
    DisableOrderMutationVariables
  >(DisableOrderDocument, options);
}
export type DisableOrderMutationHookResult = ReturnType<
  typeof useDisableOrderMutation
>;
export type DisableOrderMutationResult =
  Apollo.MutationResult<DisableOrderMutation>;
export type DisableOrderMutationOptions = Apollo.BaseMutationOptions<
  DisableOrderMutation,
  DisableOrderMutationVariables
>;
export const OrganizerDocument = gql`
  query organizer($id: Int!) {
    organizer(id: $id) {
      createdAt
      updatedAt
      deletedAt
      id
      image
      name
      query
      description
      telegram
      socials(disableShortcode: true) {
        image
        text
        vk
        telegram
        facebook
        youtube
        instagram
      }
      supplierInfo {
        name
        inn
        phone
      }
      orderInstructions {
        transferToProduct
        transferToUser
        refund
      }
    }
  }
`;

/**
 * __useOrganizerQuery__
 *
 * To run a query within a React component, call `useOrganizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizerQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizerQuery,
    OrganizerQueryVariables
  > &
    (
      | { variables: OrganizerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganizerQuery, OrganizerQueryVariables>(
    OrganizerDocument,
    options,
  );
}
export function useOrganizerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizerQuery,
    OrganizerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganizerQuery, OrganizerQueryVariables>(
    OrganizerDocument,
    options,
  );
}
export function useOrganizerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrganizerQuery,
    OrganizerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<OrganizerQuery, OrganizerQueryVariables>(
    OrganizerDocument,
    options,
  );
}
export type OrganizerQueryHookResult = ReturnType<typeof useOrganizerQuery>;
export type OrganizerLazyQueryHookResult = ReturnType<
  typeof useOrganizerLazyQuery
>;
export type OrganizerSuspenseQueryHookResult = ReturnType<
  typeof useOrganizerSuspenseQuery
>;
export type OrganizerQueryResult = Apollo.QueryResult<
  OrganizerQuery,
  OrganizerQueryVariables
>;
export const OrganizerFilesDocument = gql`
  query organizerFiles($id: Int!) {
    organizer(id: $id) {
      files {
        oferta
        agent
        policy
      }
    }
  }
`;

/**
 * __useOrganizerFilesQuery__
 *
 * To run a query within a React component, call `useOrganizerFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizerFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizerFilesQuery,
    OrganizerFilesQueryVariables
  > &
    (
      | { variables: OrganizerFilesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganizerFilesQuery, OrganizerFilesQueryVariables>(
    OrganizerFilesDocument,
    options,
  );
}
export function useOrganizerFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizerFilesQuery,
    OrganizerFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganizerFilesQuery, OrganizerFilesQueryVariables>(
    OrganizerFilesDocument,
    options,
  );
}
export function useOrganizerFilesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrganizerFilesQuery,
    OrganizerFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    OrganizerFilesQuery,
    OrganizerFilesQueryVariables
  >(OrganizerFilesDocument, options);
}
export type OrganizerFilesQueryHookResult = ReturnType<
  typeof useOrganizerFilesQuery
>;
export type OrganizerFilesLazyQueryHookResult = ReturnType<
  typeof useOrganizerFilesLazyQuery
>;
export type OrganizerFilesSuspenseQueryHookResult = ReturnType<
  typeof useOrganizerFilesSuspenseQuery
>;
export type OrganizerFilesQueryResult = Apollo.QueryResult<
  OrganizerFilesQuery,
  OrganizerFilesQueryVariables
>;
export const UpdateOrganizerDocument = gql`
  mutation updateOrganizer($id: Float!, $input: OrganizerInput!) {
    updateOrganizer(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateOrganizerMutationFn = Apollo.MutationFunction<
  UpdateOrganizerMutation,
  UpdateOrganizerMutationVariables
>;

/**
 * __useUpdateOrganizerMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizerMutation, { data, loading, error }] = useUpdateOrganizerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizerMutation,
    UpdateOrganizerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateOrganizerMutation,
    UpdateOrganizerMutationVariables
  >(UpdateOrganizerDocument, options);
}
export type UpdateOrganizerMutationHookResult = ReturnType<
  typeof useUpdateOrganizerMutation
>;
export type UpdateOrganizerMutationResult =
  Apollo.MutationResult<UpdateOrganizerMutation>;
export type UpdateOrganizerMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizerMutation,
  UpdateOrganizerMutationVariables
>;
export const OrganizerShortcodesDocument = gql`
  query organizerShortcodes {
    organizerShortcodes
  }
`;

/**
 * __useOrganizerShortcodesQuery__
 *
 * To run a query within a React component, call `useOrganizerShortcodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerShortcodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerShortcodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizerShortcodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizerShortcodesQuery,
    OrganizerShortcodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    OrganizerShortcodesQuery,
    OrganizerShortcodesQueryVariables
  >(OrganizerShortcodesDocument, options);
}
export function useOrganizerShortcodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizerShortcodesQuery,
    OrganizerShortcodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    OrganizerShortcodesQuery,
    OrganizerShortcodesQueryVariables
  >(OrganizerShortcodesDocument, options);
}
export function useOrganizerShortcodesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrganizerShortcodesQuery,
    OrganizerShortcodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    OrganizerShortcodesQuery,
    OrganizerShortcodesQueryVariables
  >(OrganizerShortcodesDocument, options);
}
export type OrganizerShortcodesQueryHookResult = ReturnType<
  typeof useOrganizerShortcodesQuery
>;
export type OrganizerShortcodesLazyQueryHookResult = ReturnType<
  typeof useOrganizerShortcodesLazyQuery
>;
export type OrganizerShortcodesSuspenseQueryHookResult = ReturnType<
  typeof useOrganizerShortcodesSuspenseQuery
>;
export type OrganizerShortcodesQueryResult = Apollo.QueryResult<
  OrganizerShortcodesQuery,
  OrganizerShortcodesQueryVariables
>;
export const PaymentAccountsListDocument = gql`
  query paymentAccountsList($idOrganizer: Int!) {
    paymentsByOrganizer(idOrganizer: $idOrganizer) {
      ...PaymentAccountFields
    }
  }
  ${PaymentAccountFieldsFragmentDoc}
`;

/**
 * __usePaymentAccountsListQuery__
 *
 * To run a query within a React component, call `usePaymentAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentAccountsListQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function usePaymentAccountsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  > &
    (
      | { variables: PaymentAccountsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >(PaymentAccountsListDocument, options);
}
export function usePaymentAccountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >(PaymentAccountsListDocument, options);
}
export function usePaymentAccountsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >(PaymentAccountsListDocument, options);
}
export type PaymentAccountsListQueryHookResult = ReturnType<
  typeof usePaymentAccountsListQuery
>;
export type PaymentAccountsListLazyQueryHookResult = ReturnType<
  typeof usePaymentAccountsListLazyQuery
>;
export type PaymentAccountsListSuspenseQueryHookResult = ReturnType<
  typeof usePaymentAccountsListSuspenseQuery
>;
export type PaymentAccountsListQueryResult = Apollo.QueryResult<
  PaymentAccountsListQuery,
  PaymentAccountsListQueryVariables
>;
export const EventsCountDocument = gql`
  query eventsCount($idOrganizer: Float) {
    productsCount(idOrganizer: $idOrganizer) {
      count
      activeCount
      archiveCount
      draftCount
    }
  }
`;

/**
 * __useEventsCountQuery__
 *
 * To run a query within a React component, call `useEventsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsCountQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useEventsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventsCountQuery,
    EventsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventsCountQuery, EventsCountQueryVariables>(
    EventsCountDocument,
    options,
  );
}
export function useEventsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventsCountQuery,
    EventsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EventsCountQuery, EventsCountQueryVariables>(
    EventsCountDocument,
    options,
  );
}
export function useEventsCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventsCountQuery,
    EventsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<EventsCountQuery, EventsCountQueryVariables>(
    EventsCountDocument,
    options,
  );
}
export type EventsCountQueryHookResult = ReturnType<typeof useEventsCountQuery>;
export type EventsCountLazyQueryHookResult = ReturnType<
  typeof useEventsCountLazyQuery
>;
export type EventsCountSuspenseQueryHookResult = ReturnType<
  typeof useEventsCountSuspenseQuery
>;
export type EventsCountQueryResult = Apollo.QueryResult<
  EventsCountQuery,
  EventsCountQueryVariables
>;
export const EventShortcodeDocument = gql`
  query eventShortcode {
    productShortcodes
  }
`;

/**
 * __useEventShortcodeQuery__
 *
 * To run a query within a React component, call `useEventShortcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventShortcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventShortcodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventShortcodeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventShortcodeQuery,
    EventShortcodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventShortcodeQuery, EventShortcodeQueryVariables>(
    EventShortcodeDocument,
    options,
  );
}
export function useEventShortcodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventShortcodeQuery,
    EventShortcodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EventShortcodeQuery, EventShortcodeQueryVariables>(
    EventShortcodeDocument,
    options,
  );
}
export function useEventShortcodeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventShortcodeQuery,
    EventShortcodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    EventShortcodeQuery,
    EventShortcodeQueryVariables
  >(EventShortcodeDocument, options);
}
export type EventShortcodeQueryHookResult = ReturnType<
  typeof useEventShortcodeQuery
>;
export type EventShortcodeLazyQueryHookResult = ReturnType<
  typeof useEventShortcodeLazyQuery
>;
export type EventShortcodeSuspenseQueryHookResult = ReturnType<
  typeof useEventShortcodeSuspenseQuery
>;
export type EventShortcodeQueryResult = Apollo.QueryResult<
  EventShortcodeQuery,
  EventShortcodeQueryVariables
>;
export const EventsListDocument = gql`
  query eventsList(
    $filters: ProductListFilterInput
    $params: ProductFilter
    $status: [StatusEnum!]
  ) {
    productsList(filters: $filters, params: $params, status: $status) {
      rows {
        createdAt
        updatedAt
        uuid
        status
        purchasedCount
        visitedCount
        receipts
        name
        query
        startedDate
        endDate
      }
      count
    }
  }
`;

/**
 * __useEventsListQuery__
 *
 * To run a query within a React component, call `useEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      params: // value for 'params'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEventsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventsListQuery,
    EventsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventsListQuery, EventsListQueryVariables>(
    EventsListDocument,
    options,
  );
}
export function useEventsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventsListQuery,
    EventsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EventsListQuery, EventsListQueryVariables>(
    EventsListDocument,
    options,
  );
}
export function useEventsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventsListQuery,
    EventsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<EventsListQuery, EventsListQueryVariables>(
    EventsListDocument,
    options,
  );
}
export type EventsListQueryHookResult = ReturnType<typeof useEventsListQuery>;
export type EventsListLazyQueryHookResult = ReturnType<
  typeof useEventsListLazyQuery
>;
export type EventsListSuspenseQueryHookResult = ReturnType<
  typeof useEventsListSuspenseQuery
>;
export type EventsListQueryResult = Apollo.QueryResult<
  EventsListQuery,
  EventsListQueryVariables
>;
export const EventDocument = gql`
  query event($uuid: UUID!) {
    product(uuid: $uuid) {
      createdAt
      updatedAt
      uuid
      img
      name
      description
      address
      query
      startedDate
      salesStartedText(disableShortcode: true)
      endDate
      status
      preview {
        active
        url
        description
        backButtonText
      }
      route {
        active
        url
        description
        backButtonText
      }
      featureFlags {
        durationBeforeStartForRefundOrder
        durationBeforeStartForTransferToUserOrder
        allowedNumberForTransferToProductOrder
      }
      successBuyText(disableShortcode: true)
      tariffsText(disableShortcode: true)
      tariffsEmptyText(disableShortcode: true)
      tariffsUnavailableText(disableShortcode: true)
      activeAcquiring
      faq(disableShortcode: true)
      invoiceLifetime
      salesStartedDate
      transferOrderToUserSuccessText(disableShortcode: true)
    }
  }
`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEventQuery(
  baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables> &
    ({ variables: EventQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export function useEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export function useEventSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventQuery,
    EventQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventSuspenseQueryHookResult = ReturnType<
  typeof useEventSuspenseQuery
>;
export type EventQueryResult = Apollo.QueryResult<
  EventQuery,
  EventQueryVariables
>;
export const BaseEventTextsDocument = gql`
  query baseEventTexts {
    baseTexts(
      keys: [
        ProductSalesStartedText
        ProductSuccessBuyText
        ProductTariffsText
        ProductTariffsEmptyText
        ProductTariffsUnavailableText
      ]
    ) {
      key
      text
    }
  }
`;

/**
 * __useBaseEventTextsQuery__
 *
 * To run a query within a React component, call `useBaseEventTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseEventTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseEventTextsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBaseEventTextsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BaseEventTextsQuery,
    BaseEventTextsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BaseEventTextsQuery, BaseEventTextsQueryVariables>(
    BaseEventTextsDocument,
    options,
  );
}
export function useBaseEventTextsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BaseEventTextsQuery,
    BaseEventTextsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BaseEventTextsQuery, BaseEventTextsQueryVariables>(
    BaseEventTextsDocument,
    options,
  );
}
export function useBaseEventTextsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BaseEventTextsQuery,
    BaseEventTextsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    BaseEventTextsQuery,
    BaseEventTextsQueryVariables
  >(BaseEventTextsDocument, options);
}
export type BaseEventTextsQueryHookResult = ReturnType<
  typeof useBaseEventTextsQuery
>;
export type BaseEventTextsLazyQueryHookResult = ReturnType<
  typeof useBaseEventTextsLazyQuery
>;
export type BaseEventTextsSuspenseQueryHookResult = ReturnType<
  typeof useBaseEventTextsSuspenseQuery
>;
export type BaseEventTextsQueryResult = Apollo.QueryResult<
  BaseEventTextsQuery,
  BaseEventTextsQueryVariables
>;
export const ArchiveEventDocument = gql`
  mutation archiveEvent($uuid: UUID!) {
    archiveProduct(uuid: $uuid)
  }
`;
export type ArchiveEventMutationFn = Apollo.MutationFunction<
  ArchiveEventMutation,
  ArchiveEventMutationVariables
>;

/**
 * __useArchiveEventMutation__
 *
 * To run a mutation, you first call `useArchiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveEventMutation, { data, loading, error }] = useArchiveEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useArchiveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveEventMutation,
    ArchiveEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    ArchiveEventMutation,
    ArchiveEventMutationVariables
  >(ArchiveEventDocument, options);
}
export type ArchiveEventMutationHookResult = ReturnType<
  typeof useArchiveEventMutation
>;
export type ArchiveEventMutationResult =
  Apollo.MutationResult<ArchiveEventMutation>;
export type ArchiveEventMutationOptions = Apollo.BaseMutationOptions<
  ArchiveEventMutation,
  ArchiveEventMutationVariables
>;
export const PublishEventDocument = gql`
  mutation publishEvent($uuid: UUID!) {
    activateProduct(uuid: $uuid)
  }
`;
export type PublishEventMutationFn = Apollo.MutationFunction<
  PublishEventMutation,
  PublishEventMutationVariables
>;

/**
 * __usePublishEventMutation__
 *
 * To run a mutation, you first call `usePublishEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEventMutation, { data, loading, error }] = usePublishEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishEventMutation,
    PublishEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    PublishEventMutation,
    PublishEventMutationVariables
  >(PublishEventDocument, options);
}
export type PublishEventMutationHookResult = ReturnType<
  typeof usePublishEventMutation
>;
export type PublishEventMutationResult =
  Apollo.MutationResult<PublishEventMutation>;
export type PublishEventMutationOptions = Apollo.BaseMutationOptions<
  PublishEventMutation,
  PublishEventMutationVariables
>;
export const RemoveEventDocument = gql`
  mutation removeEvent($uuid: UUID!) {
    removeProduct(uuid: $uuid)
  }
`;
export type RemoveEventMutationFn = Apollo.MutationFunction<
  RemoveEventMutation,
  RemoveEventMutationVariables
>;

/**
 * __useRemoveEventMutation__
 *
 * To run a mutation, you first call `useRemoveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventMutation, { data, loading, error }] = useRemoveEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEventMutation,
    RemoveEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RemoveEventMutation, RemoveEventMutationVariables>(
    RemoveEventDocument,
    options,
  );
}
export type RemoveEventMutationHookResult = ReturnType<
  typeof useRemoveEventMutation
>;
export type RemoveEventMutationResult =
  Apollo.MutationResult<RemoveEventMutation>;
export type RemoveEventMutationOptions = Apollo.BaseMutationOptions<
  RemoveEventMutation,
  RemoveEventMutationVariables
>;
export const UpdateEventDocument = gql`
  mutation updateEvent($uuid: UUID!, $input: ProductInput!) {
    updateProduct(input: $input, uuid: $uuid) {
      uuid
    }
  }
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventMutation,
    UpdateEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(
    UpdateEventDocument,
    options,
  );
}
export type UpdateEventMutationHookResult = ReturnType<
  typeof useUpdateEventMutation
>;
export type UpdateEventMutationResult =
  Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export const UpdateEventInviteTextsDocument = gql`
  mutation updateEventInviteTexts(
    $uuid: UUID!
    $input: ProductInviteTextsInput!
  ) {
    updateProductInviteTexts(input: $input, uuid: $uuid) {
      uuid
    }
  }
`;
export type UpdateEventInviteTextsMutationFn = Apollo.MutationFunction<
  UpdateEventInviteTextsMutation,
  UpdateEventInviteTextsMutationVariables
>;

/**
 * __useUpdateEventInviteTextsMutation__
 *
 * To run a mutation, you first call `useUpdateEventInviteTextsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventInviteTextsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventInviteTextsMutation, { data, loading, error }] = useUpdateEventInviteTextsMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventInviteTextsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventInviteTextsMutation,
    UpdateEventInviteTextsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateEventInviteTextsMutation,
    UpdateEventInviteTextsMutationVariables
  >(UpdateEventInviteTextsDocument, options);
}
export type UpdateEventInviteTextsMutationHookResult = ReturnType<
  typeof useUpdateEventInviteTextsMutation
>;
export type UpdateEventInviteTextsMutationResult =
  Apollo.MutationResult<UpdateEventInviteTextsMutation>;
export type UpdateEventInviteTextsMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventInviteTextsMutation,
  UpdateEventInviteTextsMutationVariables
>;
export const DuplicateProductDocument = gql`
  mutation duplicateProduct($uuid: UUID!) {
    duplicateProduct(uuid: $uuid) {
      uuid
    }
  }
`;
export type DuplicateProductMutationFn = Apollo.MutationFunction<
  DuplicateProductMutation,
  DuplicateProductMutationVariables
>;

/**
 * __useDuplicateProductMutation__
 *
 * To run a mutation, you first call `useDuplicateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProductMutation, { data, loading, error }] = useDuplicateProductMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDuplicateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateProductMutation,
    DuplicateProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    DuplicateProductMutation,
    DuplicateProductMutationVariables
  >(DuplicateProductDocument, options);
}
export type DuplicateProductMutationHookResult = ReturnType<
  typeof useDuplicateProductMutation
>;
export type DuplicateProductMutationResult =
  Apollo.MutationResult<DuplicateProductMutation>;
export type DuplicateProductMutationOptions = Apollo.BaseMutationOptions<
  DuplicateProductMutation,
  DuplicateProductMutationVariables
>;
export const CreateEventDocument = gql`
  mutation createEvent(
    $publish: Boolean
    $input: ProductInput!
    $organizerId: Float!
  ) {
    createProduct(publish: $publish, input: $input, organizerId: $organizerId) {
      uuid
    }
  }
`;
export type CreateEventMutationFn = Apollo.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      publish: // value for 'publish'
 *      input: // value for 'input'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventMutation,
    CreateEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
    options,
  );
}
export type CreateEventMutationHookResult = ReturnType<
  typeof useCreateEventMutation
>;
export type CreateEventMutationResult =
  Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const SubTariffsListDocument = gql`
  query subTariffsList(
    $productUuid: UUID!
    $status: [StatusEnum!]
    $filters: SubTariffListFilterInput
  ) {
    subTariffsList(
      productUuid: $productUuid
      status: $status
      filters: $filters
    ) {
      rows {
        uuid
        name
        startedDate
        endDate
        status
        ordersCount
        availableOrdersCount
        paidOrdersCount
        baseTariff {
          name
          type
          ordersCount
        }
      }
    }
  }
`;

/**
 * __useSubTariffsListQuery__
 *
 * To run a query within a React component, call `useSubTariffsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubTariffsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubTariffsListQuery({
 *   variables: {
 *      productUuid: // value for 'productUuid'
 *      status: // value for 'status'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSubTariffsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubTariffsListQuery,
    SubTariffsListQueryVariables
  > &
    (
      | { variables: SubTariffsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<SubTariffsListQuery, SubTariffsListQueryVariables>(
    SubTariffsListDocument,
    options,
  );
}
export function useSubTariffsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubTariffsListQuery,
    SubTariffsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<SubTariffsListQuery, SubTariffsListQueryVariables>(
    SubTariffsListDocument,
    options,
  );
}
export function useSubTariffsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SubTariffsListQuery,
    SubTariffsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    SubTariffsListQuery,
    SubTariffsListQueryVariables
  >(SubTariffsListDocument, options);
}
export type SubTariffsListQueryHookResult = ReturnType<
  typeof useSubTariffsListQuery
>;
export type SubTariffsListLazyQueryHookResult = ReturnType<
  typeof useSubTariffsListLazyQuery
>;
export type SubTariffsListSuspenseQueryHookResult = ReturnType<
  typeof useSubTariffsListSuspenseQuery
>;
export type SubTariffsListQueryResult = Apollo.QueryResult<
  SubTariffsListQuery,
  SubTariffsListQueryVariables
>;
export const SubTariffDocument = gql`
  query subTariff($uuid: UUID!) {
    subTariff(uuid: $uuid) {
      ...SubTariffFields
      baseTariff {
        ...BaseTariffFields
      }
    }
  }
  ${SubTariffFieldsFragmentDoc}
  ${BaseTariffFieldsFragmentDoc}
`;

/**
 * __useSubTariffQuery__
 *
 * To run a query within a React component, call `useSubTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubTariffQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSubTariffQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubTariffQuery,
    SubTariffQueryVariables
  > &
    (
      | { variables: SubTariffQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<SubTariffQuery, SubTariffQueryVariables>(
    SubTariffDocument,
    options,
  );
}
export function useSubTariffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubTariffQuery,
    SubTariffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<SubTariffQuery, SubTariffQueryVariables>(
    SubTariffDocument,
    options,
  );
}
export function useSubTariffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SubTariffQuery,
    SubTariffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<SubTariffQuery, SubTariffQueryVariables>(
    SubTariffDocument,
    options,
  );
}
export type SubTariffQueryHookResult = ReturnType<typeof useSubTariffQuery>;
export type SubTariffLazyQueryHookResult = ReturnType<
  typeof useSubTariffLazyQuery
>;
export type SubTariffSuspenseQueryHookResult = ReturnType<
  typeof useSubTariffSuspenseQuery
>;
export type SubTariffQueryResult = Apollo.QueryResult<
  SubTariffQuery,
  SubTariffQueryVariables
>;
export const CreateTariffDocument = gql`
  mutation createTariff(
    $input: SubTariffInput!
    $uuidProduct: UUID
    $uuidParentTariff: UUID!
  ) {
    createTariff(
      input: $input
      uuidParentTariff: $uuidParentTariff
      uuidProduct: $uuidProduct
    ) {
      ...SubTariffFields
      baseTariff {
        ...BaseTariffFields
      }
    }
  }
  ${SubTariffFieldsFragmentDoc}
  ${BaseTariffFieldsFragmentDoc}
`;
export type CreateTariffMutationFn = Apollo.MutationFunction<
  CreateTariffMutation,
  CreateTariffMutationVariables
>;

/**
 * __useCreateTariffMutation__
 *
 * To run a mutation, you first call `useCreateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffMutation, { data, loading, error }] = useCreateTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *      uuidProduct: // value for 'uuidProduct'
 *      uuidParentTariff: // value for 'uuidParentTariff'
 *   },
 * });
 */
export function useCreateTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTariffMutation,
    CreateTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CreateTariffMutation,
    CreateTariffMutationVariables
  >(CreateTariffDocument, options);
}
export type CreateTariffMutationHookResult = ReturnType<
  typeof useCreateTariffMutation
>;
export type CreateTariffMutationResult =
  Apollo.MutationResult<CreateTariffMutation>;
export type CreateTariffMutationOptions = Apollo.BaseMutationOptions<
  CreateTariffMutation,
  CreateTariffMutationVariables
>;
export const UpdateTariffDocument = gql`
  mutation updateTariff($uuid: UUID!, $input: SubTariffInput!) {
    updateTariff(input: $input, uuid: $uuid) {
      ...SubTariffFields
      baseTariff {
        ...BaseTariffFields
      }
    }
  }
  ${SubTariffFieldsFragmentDoc}
  ${BaseTariffFieldsFragmentDoc}
`;
export type UpdateTariffMutationFn = Apollo.MutationFunction<
  UpdateTariffMutation,
  UpdateTariffMutationVariables
>;

/**
 * __useUpdateTariffMutation__
 *
 * To run a mutation, you first call `useUpdateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffMutation, { data, loading, error }] = useUpdateTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTariffMutation,
    UpdateTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateTariffMutation,
    UpdateTariffMutationVariables
  >(UpdateTariffDocument, options);
}
export type UpdateTariffMutationHookResult = ReturnType<
  typeof useUpdateTariffMutation
>;
export type UpdateTariffMutationResult =
  Apollo.MutationResult<UpdateTariffMutation>;
export type UpdateTariffMutationOptions = Apollo.BaseMutationOptions<
  UpdateTariffMutation,
  UpdateTariffMutationVariables
>;
export const RemoveTariffDocument = gql`
  mutation removeTariff($uuid: UUID!) {
    removeTariff(uuid: $uuid)
  }
`;
export type RemoveTariffMutationFn = Apollo.MutationFunction<
  RemoveTariffMutation,
  RemoveTariffMutationVariables
>;

/**
 * __useRemoveTariffMutation__
 *
 * To run a mutation, you first call `useRemoveTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTariffMutation, { data, loading, error }] = useRemoveTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTariffMutation,
    RemoveTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    RemoveTariffMutation,
    RemoveTariffMutationVariables
  >(RemoveTariffDocument, options);
}
export type RemoveTariffMutationHookResult = ReturnType<
  typeof useRemoveTariffMutation
>;
export type RemoveTariffMutationResult =
  Apollo.MutationResult<RemoveTariffMutation>;
export type RemoveTariffMutationOptions = Apollo.BaseMutationOptions<
  RemoveTariffMutation,
  RemoveTariffMutationVariables
>;
export const UpdateProductTariffsDocument = gql`
  mutation updateProductTariffs($uuids: [UUID!]!, $productUuid: UUID!) {
    updateProductTariffs(uuids: $uuids, productUuid: $productUuid)
  }
`;
export type UpdateProductTariffsMutationFn = Apollo.MutationFunction<
  UpdateProductTariffsMutation,
  UpdateProductTariffsMutationVariables
>;

/**
 * __useUpdateProductTariffsMutation__
 *
 * To run a mutation, you first call `useUpdateProductTariffsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductTariffsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductTariffsMutation, { data, loading, error }] = useUpdateProductTariffsMutation({
 *   variables: {
 *      uuids: // value for 'uuids'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUpdateProductTariffsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductTariffsMutation,
    UpdateProductTariffsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateProductTariffsMutation,
    UpdateProductTariffsMutationVariables
  >(UpdateProductTariffsDocument, options);
}
export type UpdateProductTariffsMutationHookResult = ReturnType<
  typeof useUpdateProductTariffsMutation
>;
export type UpdateProductTariffsMutationResult =
  Apollo.MutationResult<UpdateProductTariffsMutation>;
export type UpdateProductTariffsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductTariffsMutation,
  UpdateProductTariffsMutationVariables
>;
export const UsersListDocument = gql`
  query usersList(
    $filters: UserListFilterInput
    $organizerId: Float!
    $isActive: Boolean
    $productUuid: UUID
  ) {
    users(
      filters: $filters
      params: { organizerId: $organizerId }
      args: { isActive: $isActive }
    ) {
      ...UserListFields
    }
  }
  ${UserListFieldsFragmentDoc}
`;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      isActive: // value for 'isActive'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  > &
    (
      | { variables: UsersListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export function useUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export function useUsersListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<
  typeof useUsersListLazyQuery
>;
export type UsersListSuspenseQueryHookResult = ReturnType<
  typeof useUsersListSuspenseQuery
>;
export type UsersListQueryResult = Apollo.QueryResult<
  UsersListQuery,
  UsersListQueryVariables
>;
export const UsersSpecialListDocument = gql`
  query usersSpecialList(
    $filters: UserListFilterInput
    $organizerId: Float!
    $productUuid: UUID
  ) {
    specialUsers(organizerId: $organizerId, filters: $filters) {
      ...UserListFields
    }
  }
  ${UserListFieldsFragmentDoc}
`;

/**
 * __useUsersSpecialListQuery__
 *
 * To run a query within a React component, call `useUsersSpecialListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersSpecialListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSpecialListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersSpecialListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  > &
    (
      | { variables: UsersSpecialListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersSpecialListQuery, UsersSpecialListQueryVariables>(
    UsersSpecialListDocument,
    options,
  );
}
export function useUsersSpecialListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  >(UsersSpecialListDocument, options);
}
export function useUsersSpecialListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  >(UsersSpecialListDocument, options);
}
export type UsersSpecialListQueryHookResult = ReturnType<
  typeof useUsersSpecialListQuery
>;
export type UsersSpecialListLazyQueryHookResult = ReturnType<
  typeof useUsersSpecialListLazyQuery
>;
export type UsersSpecialListSuspenseQueryHookResult = ReturnType<
  typeof useUsersSpecialListSuspenseQuery
>;
export type UsersSpecialListQueryResult = Apollo.QueryResult<
  UsersSpecialListQuery,
  UsersSpecialListQueryVariables
>;
export const UsersBlockedListDocument = gql`
  query usersBlockedList(
    $filters: UserListFilterInput
    $organizerId: Float!
    $productUuid: UUID
  ) {
    blacklist(organizerId: $organizerId, filters: $filters) {
      ...UserListFields
    }
  }
  ${UserListFieldsFragmentDoc}
`;

/**
 * __useUsersBlockedListQuery__
 *
 * To run a query within a React component, call `useUsersBlockedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersBlockedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersBlockedListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersBlockedListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersBlockedListQuery,
    UsersBlockedListQueryVariables
  > &
    (
      | { variables: UsersBlockedListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersBlockedListQuery, UsersBlockedListQueryVariables>(
    UsersBlockedListDocument,
    options,
  );
}
export function useUsersBlockedListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersBlockedListQuery,
    UsersBlockedListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersBlockedListQuery,
    UsersBlockedListQueryVariables
  >(UsersBlockedListDocument, options);
}
export function useUsersBlockedListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersBlockedListQuery,
    UsersBlockedListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersBlockedListQuery,
    UsersBlockedListQueryVariables
  >(UsersBlockedListDocument, options);
}
export type UsersBlockedListQueryHookResult = ReturnType<
  typeof useUsersBlockedListQuery
>;
export type UsersBlockedListLazyQueryHookResult = ReturnType<
  typeof useUsersBlockedListLazyQuery
>;
export type UsersBlockedListSuspenseQueryHookResult = ReturnType<
  typeof useUsersBlockedListSuspenseQuery
>;
export type UsersBlockedListQueryResult = Apollo.QueryResult<
  UsersBlockedListQuery,
  UsersBlockedListQueryVariables
>;
export const UsersRegisteredListDocument = gql`
  query usersRegisteredList(
    $filters: UserListFilterInput
    $organizerId: Float!
    $productUuid: UUID
  ) {
    users(
      filters: $filters
      params: { organizerId: $organizerId }
      args: { isRegistered: true }
    ) {
      ...UserListFields
    }
  }
  ${UserListFieldsFragmentDoc}
`;

/**
 * __useUsersRegisteredListQuery__
 *
 * To run a query within a React component, call `useUsersRegisteredListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersRegisteredListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersRegisteredListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersRegisteredListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersRegisteredListQuery,
    UsersRegisteredListQueryVariables
  > &
    (
      | { variables: UsersRegisteredListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    UsersRegisteredListQuery,
    UsersRegisteredListQueryVariables
  >(UsersRegisteredListDocument, options);
}
export function useUsersRegisteredListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersRegisteredListQuery,
    UsersRegisteredListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersRegisteredListQuery,
    UsersRegisteredListQueryVariables
  >(UsersRegisteredListDocument, options);
}
export function useUsersRegisteredListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersRegisteredListQuery,
    UsersRegisteredListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersRegisteredListQuery,
    UsersRegisteredListQueryVariables
  >(UsersRegisteredListDocument, options);
}
export type UsersRegisteredListQueryHookResult = ReturnType<
  typeof useUsersRegisteredListQuery
>;
export type UsersRegisteredListLazyQueryHookResult = ReturnType<
  typeof useUsersRegisteredListLazyQuery
>;
export type UsersRegisteredListSuspenseQueryHookResult = ReturnType<
  typeof useUsersRegisteredListSuspenseQuery
>;
export type UsersRegisteredListQueryResult = Apollo.QueryResult<
  UsersRegisteredListQuery,
  UsersRegisteredListQueryVariables
>;
export const UsersByGroupListDocument = gql`
  query usersByGroupList(
    $filters: UserListFilterInput!
    $groupUuid: UUID!
    $organizerId: Float!
    $productUuid: UUID
  ) {
    usersByGroup(filters: $filters, groupUuid: $groupUuid) {
      ...UserListFields
    }
  }
  ${UserListFieldsFragmentDoc}
`;

/**
 * __useUsersByGroupListQuery__
 *
 * To run a query within a React component, call `useUsersByGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByGroupListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      groupUuid: // value for 'groupUuid'
 *      organizerId: // value for 'organizerId'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersByGroupListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersByGroupListQuery,
    UsersByGroupListQueryVariables
  > &
    (
      | { variables: UsersByGroupListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersByGroupListQuery, UsersByGroupListQueryVariables>(
    UsersByGroupListDocument,
    options,
  );
}
export function useUsersByGroupListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersByGroupListQuery,
    UsersByGroupListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersByGroupListQuery,
    UsersByGroupListQueryVariables
  >(UsersByGroupListDocument, options);
}
export function useUsersByGroupListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersByGroupListQuery,
    UsersByGroupListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersByGroupListQuery,
    UsersByGroupListQueryVariables
  >(UsersByGroupListDocument, options);
}
export type UsersByGroupListQueryHookResult = ReturnType<
  typeof useUsersByGroupListQuery
>;
export type UsersByGroupListLazyQueryHookResult = ReturnType<
  typeof useUsersByGroupListLazyQuery
>;
export type UsersByGroupListSuspenseQueryHookResult = ReturnType<
  typeof useUsersByGroupListSuspenseQuery
>;
export type UsersByGroupListQueryResult = Apollo.QueryResult<
  UsersByGroupListQuery,
  UsersByGroupListQueryVariables
>;
export const UsersSpecialCountDocument = gql`
  query usersSpecialCount($organizerId: Float!) {
    specialUsers(
      organizerId: $organizerId
      filters: { pagination: { page: 1, pageSize: 1 } }
    ) {
      count
    }
  }
`;

/**
 * __useUsersSpecialCountQuery__
 *
 * To run a query within a React component, call `useUsersSpecialCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersSpecialCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSpecialCountQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUsersSpecialCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  > &
    (
      | { variables: UsersSpecialCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >(UsersSpecialCountDocument, options);
}
export function useUsersSpecialCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >(UsersSpecialCountDocument, options);
}
export function useUsersSpecialCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >(UsersSpecialCountDocument, options);
}
export type UsersSpecialCountQueryHookResult = ReturnType<
  typeof useUsersSpecialCountQuery
>;
export type UsersSpecialCountLazyQueryHookResult = ReturnType<
  typeof useUsersSpecialCountLazyQuery
>;
export type UsersSpecialCountSuspenseQueryHookResult = ReturnType<
  typeof useUsersSpecialCountSuspenseQuery
>;
export type UsersSpecialCountQueryResult = Apollo.QueryResult<
  UsersSpecialCountQuery,
  UsersSpecialCountQueryVariables
>;
export const UsersRegisteredCountDocument = gql`
  query usersRegisteredCount($organizerId: Float!) {
    users(
      filters: { pagination: { page: 1, pageSize: 1 } }
      params: { organizerId: $organizerId }
      args: { isRegistered: true }
    ) {
      count
    }
  }
`;

/**
 * __useUsersRegisteredCountQuery__
 *
 * To run a query within a React component, call `useUsersRegisteredCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersRegisteredCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersRegisteredCountQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUsersRegisteredCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersRegisteredCountQuery,
    UsersRegisteredCountQueryVariables
  > &
    (
      | { variables: UsersRegisteredCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    UsersRegisteredCountQuery,
    UsersRegisteredCountQueryVariables
  >(UsersRegisteredCountDocument, options);
}
export function useUsersRegisteredCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersRegisteredCountQuery,
    UsersRegisteredCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersRegisteredCountQuery,
    UsersRegisteredCountQueryVariables
  >(UsersRegisteredCountDocument, options);
}
export function useUsersRegisteredCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersRegisteredCountQuery,
    UsersRegisteredCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersRegisteredCountQuery,
    UsersRegisteredCountQueryVariables
  >(UsersRegisteredCountDocument, options);
}
export type UsersRegisteredCountQueryHookResult = ReturnType<
  typeof useUsersRegisteredCountQuery
>;
export type UsersRegisteredCountLazyQueryHookResult = ReturnType<
  typeof useUsersRegisteredCountLazyQuery
>;
export type UsersRegisteredCountSuspenseQueryHookResult = ReturnType<
  typeof useUsersRegisteredCountSuspenseQuery
>;
export type UsersRegisteredCountQueryResult = Apollo.QueryResult<
  UsersRegisteredCountQuery,
  UsersRegisteredCountQueryVariables
>;
export const UsersBlockedCountDocument = gql`
  query usersBlockedCount($organizerId: Float!) {
    blacklist(
      organizerId: $organizerId
      filters: { pagination: { page: 1, pageSize: 1 } }
    ) {
      count
    }
  }
`;

/**
 * __useUsersBlockedCountQuery__
 *
 * To run a query within a React component, call `useUsersBlockedCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersBlockedCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersBlockedCountQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUsersBlockedCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersBlockedCountQuery,
    UsersBlockedCountQueryVariables
  > &
    (
      | { variables: UsersBlockedCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    UsersBlockedCountQuery,
    UsersBlockedCountQueryVariables
  >(UsersBlockedCountDocument, options);
}
export function useUsersBlockedCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersBlockedCountQuery,
    UsersBlockedCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersBlockedCountQuery,
    UsersBlockedCountQueryVariables
  >(UsersBlockedCountDocument, options);
}
export function useUsersBlockedCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersBlockedCountQuery,
    UsersBlockedCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersBlockedCountQuery,
    UsersBlockedCountQueryVariables
  >(UsersBlockedCountDocument, options);
}
export type UsersBlockedCountQueryHookResult = ReturnType<
  typeof useUsersBlockedCountQuery
>;
export type UsersBlockedCountLazyQueryHookResult = ReturnType<
  typeof useUsersBlockedCountLazyQuery
>;
export type UsersBlockedCountSuspenseQueryHookResult = ReturnType<
  typeof useUsersBlockedCountSuspenseQuery
>;
export type UsersBlockedCountQueryResult = Apollo.QueryResult<
  UsersBlockedCountQuery,
  UsersBlockedCountQueryVariables
>;
export const UserGroupsDocument = gql`
  query userGroups($filters: UserGroupsListFilterInput, $idOrganizer: Float!) {
    userGroups(filters: $filters, idOrganizer: $idOrganizer) {
      rows {
        ...UserGroupFields
      }
      count
    }
  }
  ${UserGroupFieldsFragmentDoc}
`;

/**
 * __useUserGroupsQuery__
 *
 * To run a query within a React component, call `useUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useUserGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserGroupsQuery,
    UserGroupsQueryVariables
  > &
    (
      | { variables: UserGroupsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    UserGroupsDocument,
    options,
  );
}
export function useUserGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserGroupsQuery,
    UserGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    UserGroupsDocument,
    options,
  );
}
export function useUserGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserGroupsQuery,
    UserGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    UserGroupsDocument,
    options,
  );
}
export type UserGroupsQueryHookResult = ReturnType<typeof useUserGroupsQuery>;
export type UserGroupsLazyQueryHookResult = ReturnType<
  typeof useUserGroupsLazyQuery
>;
export type UserGroupsSuspenseQueryHookResult = ReturnType<
  typeof useUserGroupsSuspenseQuery
>;
export type UserGroupsQueryResult = Apollo.QueryResult<
  UserGroupsQuery,
  UserGroupsQueryVariables
>;
export const AddGroupUsersDocument = gql`
  mutation addGroupUsers($idOrganizer: Float!, $name: String!) {
    crateUserGroup(idOrganizer: $idOrganizer, name: $name) {
      ...UserGroupFields
    }
  }
  ${UserGroupFieldsFragmentDoc}
`;
export type AddGroupUsersMutationFn = Apollo.MutationFunction<
  AddGroupUsersMutation,
  AddGroupUsersMutationVariables
>;

/**
 * __useAddGroupUsersMutation__
 *
 * To run a mutation, you first call `useAddGroupUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupUsersMutation, { data, loading, error }] = useAddGroupUsersMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddGroupUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupUsersMutation,
    AddGroupUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    AddGroupUsersMutation,
    AddGroupUsersMutationVariables
  >(AddGroupUsersDocument, options);
}
export type AddGroupUsersMutationHookResult = ReturnType<
  typeof useAddGroupUsersMutation
>;
export type AddGroupUsersMutationResult =
  Apollo.MutationResult<AddGroupUsersMutation>;
export type AddGroupUsersMutationOptions = Apollo.BaseMutationOptions<
  AddGroupUsersMutation,
  AddGroupUsersMutationVariables
>;
export const UpdateGroupUsersNameDocument = gql`
  mutation updateGroupUsersName($uuid: UUID!, $name: String!) {
    updateUserGroupName(uuid: $uuid, name: $name) {
      ...UserGroupFields
    }
  }
  ${UserGroupFieldsFragmentDoc}
`;
export type UpdateGroupUsersNameMutationFn = Apollo.MutationFunction<
  UpdateGroupUsersNameMutation,
  UpdateGroupUsersNameMutationVariables
>;

/**
 * __useUpdateGroupUsersNameMutation__
 *
 * To run a mutation, you first call `useUpdateGroupUsersNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupUsersNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupUsersNameMutation, { data, loading, error }] = useUpdateGroupUsersNameMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateGroupUsersNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupUsersNameMutation,
    UpdateGroupUsersNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateGroupUsersNameMutation,
    UpdateGroupUsersNameMutationVariables
  >(UpdateGroupUsersNameDocument, options);
}
export type UpdateGroupUsersNameMutationHookResult = ReturnType<
  typeof useUpdateGroupUsersNameMutation
>;
export type UpdateGroupUsersNameMutationResult =
  Apollo.MutationResult<UpdateGroupUsersNameMutation>;
export type UpdateGroupUsersNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupUsersNameMutation,
  UpdateGroupUsersNameMutationVariables
>;
export const RemoveGroupUsersDocument = gql`
  mutation removeGroupUsers($uuid: UUID!) {
    removeUserGroup(uuid: $uuid)
  }
`;
export type RemoveGroupUsersMutationFn = Apollo.MutationFunction<
  RemoveGroupUsersMutation,
  RemoveGroupUsersMutationVariables
>;

/**
 * __useRemoveGroupUsersMutation__
 *
 * To run a mutation, you first call `useRemoveGroupUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupUsersMutation, { data, loading, error }] = useRemoveGroupUsersMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveGroupUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupUsersMutation,
    RemoveGroupUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    RemoveGroupUsersMutation,
    RemoveGroupUsersMutationVariables
  >(RemoveGroupUsersDocument, options);
}
export type RemoveGroupUsersMutationHookResult = ReturnType<
  typeof useRemoveGroupUsersMutation
>;
export type RemoveGroupUsersMutationResult =
  Apollo.MutationResult<RemoveGroupUsersMutation>;
export type RemoveGroupUsersMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupUsersMutation,
  RemoveGroupUsersMutationVariables
>;
export const AddUserToGroupDocument = gql`
  mutation addUserToGroup($uuidUser: UUID!, $uuidGroup: UUID!) {
    addUserToGroup(uuidUser: $uuidUser, uuidGroup: $uuidGroup)
  }
`;
export type AddUserToGroupMutationFn = Apollo.MutationFunction<
  AddUserToGroupMutation,
  AddUserToGroupMutationVariables
>;

/**
 * __useAddUserToGroupMutation__
 *
 * To run a mutation, you first call `useAddUserToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToGroupMutation, { data, loading, error }] = useAddUserToGroupMutation({
 *   variables: {
 *      uuidUser: // value for 'uuidUser'
 *      uuidGroup: // value for 'uuidGroup'
 *   },
 * });
 */
export function useAddUserToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToGroupMutation,
    AddUserToGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    AddUserToGroupMutation,
    AddUserToGroupMutationVariables
  >(AddUserToGroupDocument, options);
}
export type AddUserToGroupMutationHookResult = ReturnType<
  typeof useAddUserToGroupMutation
>;
export type AddUserToGroupMutationResult =
  Apollo.MutationResult<AddUserToGroupMutation>;
export type AddUserToGroupMutationOptions = Apollo.BaseMutationOptions<
  AddUserToGroupMutation,
  AddUserToGroupMutationVariables
>;
export const RemoveUserFromGroupDocument = gql`
  mutation removeUserFromGroup($uuidUser: UUID!, $uuidGroup: UUID!) {
    removeUserFromGroup(uuidUser: $uuidUser, uuidGroup: $uuidGroup)
  }
`;
export type RemoveUserFromGroupMutationFn = Apollo.MutationFunction<
  RemoveUserFromGroupMutation,
  RemoveUserFromGroupMutationVariables
>;

/**
 * __useRemoveUserFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromGroupMutation, { data, loading, error }] = useRemoveUserFromGroupMutation({
 *   variables: {
 *      uuidUser: // value for 'uuidUser'
 *      uuidGroup: // value for 'uuidGroup'
 *   },
 * });
 */
export function useRemoveUserFromGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserFromGroupMutation,
    RemoveUserFromGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    RemoveUserFromGroupMutation,
    RemoveUserFromGroupMutationVariables
  >(RemoveUserFromGroupDocument, options);
}
export type RemoveUserFromGroupMutationHookResult = ReturnType<
  typeof useRemoveUserFromGroupMutation
>;
export type RemoveUserFromGroupMutationResult =
  Apollo.MutationResult<RemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserFromGroupMutation,
  RemoveUserFromGroupMutationVariables
>;
export const AddUserToSpecialDocument = gql`
  mutation addUserToSpecial($organizerId: Float!, $userUuid: UUID!) {
    addUserToSpecial(organizerId: $organizerId, userUuid: $userUuid) {
      uuid
    }
  }
`;
export type AddUserToSpecialMutationFn = Apollo.MutationFunction<
  AddUserToSpecialMutation,
  AddUserToSpecialMutationVariables
>;

/**
 * __useAddUserToSpecialMutation__
 *
 * To run a mutation, you first call `useAddUserToSpecialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToSpecialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToSpecialMutation, { data, loading, error }] = useAddUserToSpecialMutation({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useAddUserToSpecialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToSpecialMutation,
    AddUserToSpecialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    AddUserToSpecialMutation,
    AddUserToSpecialMutationVariables
  >(AddUserToSpecialDocument, options);
}
export type AddUserToSpecialMutationHookResult = ReturnType<
  typeof useAddUserToSpecialMutation
>;
export type AddUserToSpecialMutationResult =
  Apollo.MutationResult<AddUserToSpecialMutation>;
export type AddUserToSpecialMutationOptions = Apollo.BaseMutationOptions<
  AddUserToSpecialMutation,
  AddUserToSpecialMutationVariables
>;
export const RemoveUserFromSpecialDocument = gql`
  mutation removeUserFromSpecial($organizerId: Float!, $userUuid: UUID!) {
    removeUserFromSpecial(organizerId: $organizerId, userUuid: $userUuid)
  }
`;
export type RemoveUserFromSpecialMutationFn = Apollo.MutationFunction<
  RemoveUserFromSpecialMutation,
  RemoveUserFromSpecialMutationVariables
>;

/**
 * __useRemoveUserFromSpecialMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromSpecialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromSpecialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromSpecialMutation, { data, loading, error }] = useRemoveUserFromSpecialMutation({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useRemoveUserFromSpecialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserFromSpecialMutation,
    RemoveUserFromSpecialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    RemoveUserFromSpecialMutation,
    RemoveUserFromSpecialMutationVariables
  >(RemoveUserFromSpecialDocument, options);
}
export type RemoveUserFromSpecialMutationHookResult = ReturnType<
  typeof useRemoveUserFromSpecialMutation
>;
export type RemoveUserFromSpecialMutationResult =
  Apollo.MutationResult<RemoveUserFromSpecialMutation>;
export type RemoveUserFromSpecialMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserFromSpecialMutation,
  RemoveUserFromSpecialMutationVariables
>;
export const BlockUserDocument = gql`
  mutation blockUser($organizerId: Float!, $userUuid: UUID!) {
    addUserToBlacklist(organizerId: $organizerId, uuid: $userUuid)
  }
`;
export type BlockUserMutationFn = Apollo.MutationFunction<
  BlockUserMutation,
  BlockUserMutationVariables
>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useBlockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockUserMutation,
    BlockUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(
    BlockUserDocument,
    options,
  );
}
export type BlockUserMutationHookResult = ReturnType<
  typeof useBlockUserMutation
>;
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<
  BlockUserMutation,
  BlockUserMutationVariables
>;
export const UnblockUserDocument = gql`
  mutation unblockUser($organizerId: Float!, $userUuid: UUID!) {
    removeUserFromBlacklist(organizerId: $organizerId, uuid: $userUuid)
  }
`;
export type UnblockUserMutationFn = Apollo.MutationFunction<
  UnblockUserMutation,
  UnblockUserMutationVariables
>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useUnblockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnblockUserMutation,
    UnblockUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(
    UnblockUserDocument,
    options,
  );
}
export type UnblockUserMutationHookResult = ReturnType<
  typeof useUnblockUserMutation
>;
export type UnblockUserMutationResult =
  Apollo.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = Apollo.BaseMutationOptions<
  UnblockUserMutation,
  UnblockUserMutationVariables
>;
export const UpdateUsersInGroupDocument = gql`
  mutation updateUsersInGroup($uuidGroup: UUID!, $uuidUsers: [UUID!]!) {
    updateUsersInGroup(uuidGroup: $uuidGroup, uuidUsers: $uuidUsers)
  }
`;
export type UpdateUsersInGroupMutationFn = Apollo.MutationFunction<
  UpdateUsersInGroupMutation,
  UpdateUsersInGroupMutationVariables
>;

/**
 * __useUpdateUsersInGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUsersInGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersInGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersInGroupMutation, { data, loading, error }] = useUpdateUsersInGroupMutation({
 *   variables: {
 *      uuidGroup: // value for 'uuidGroup'
 *      uuidUsers: // value for 'uuidUsers'
 *   },
 * });
 */
export function useUpdateUsersInGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUsersInGroupMutation,
    UpdateUsersInGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateUsersInGroupMutation,
    UpdateUsersInGroupMutationVariables
  >(UpdateUsersInGroupDocument, options);
}
export type UpdateUsersInGroupMutationHookResult = ReturnType<
  typeof useUpdateUsersInGroupMutation
>;
export type UpdateUsersInGroupMutationResult =
  Apollo.MutationResult<UpdateUsersInGroupMutation>;
export type UpdateUsersInGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateUsersInGroupMutation,
  UpdateUsersInGroupMutationVariables
>;
export const UserDocument = gql`
  query User($uuid: UUID!, $organizerId: Float!) {
    user(uuid: $uuid) {
      createdAt
      updatedAt
      deletedAt
      uuid
      tgId
      username
      first_name
      last_name
      language
      role
      email
      phone
      ordersCount
      activatedOrdersCount
      receipts
      gender
      balance(idOrganizer: $organizerId)
      isSpecial(idOrganizer: $organizerId)
      isBlocked(organizerId: $organizerId)
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> &
    ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export function useUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<
  typeof useUserSuspenseQuery
>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersStatsDocument = gql`
  query usersStats($organizerId: Float, $isActive: Boolean) {
    usersStats(
      params: { organizerId: $organizerId }
      args: { isActive: $isActive }
    ) {
      count
      maleCount
      femaleCount
    }
  }
`;

/**
 * __useUsersStatsQuery__
 *
 * To run a query within a React component, call `useUsersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUsersStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UsersStatsQuery,
    UsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersStatsQuery, UsersStatsQueryVariables>(
    UsersStatsDocument,
    options,
  );
}
export function useUsersStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersStatsQuery,
    UsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UsersStatsQuery, UsersStatsQueryVariables>(
    UsersStatsDocument,
    options,
  );
}
export function useUsersStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersStatsQuery,
    UsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UsersStatsQuery, UsersStatsQueryVariables>(
    UsersStatsDocument,
    options,
  );
}
export type UsersStatsQueryHookResult = ReturnType<typeof useUsersStatsQuery>;
export type UsersStatsLazyQueryHookResult = ReturnType<
  typeof useUsersStatsLazyQuery
>;
export type UsersStatsSuspenseQueryHookResult = ReturnType<
  typeof useUsersStatsSuspenseQuery
>;
export type UsersStatsQueryResult = Apollo.QueryResult<
  UsersStatsQuery,
  UsersStatsQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($uuid: UUID!, $input: UpdateUserInput!) {
    updateUser(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const WithdrawalsListDocument = gql`
  query withdrawalsList(
    $filter: WithdrawalListFilterInput
    $idOrganizer: Int!
  ) {
    withdrawalsByOrganizer(idOrganizer: $idOrganizer, filters: $filter) {
      count
      rows {
        ...WithdrawalFields
        paymentAccount {
          ...PaymentAccountFields
        }
      }
    }
  }
  ${WithdrawalFieldsFragmentDoc}
  ${PaymentAccountFieldsFragmentDoc}
`;

/**
 * __useWithdrawalsListQuery__
 *
 * To run a query within a React component, call `useWithdrawalsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithdrawalsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useWithdrawalsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  > &
    (
      | { variables: WithdrawalsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<WithdrawalsListQuery, WithdrawalsListQueryVariables>(
    WithdrawalsListDocument,
    options,
  );
}
export function useWithdrawalsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >(WithdrawalsListDocument, options);
}
export function useWithdrawalsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >(WithdrawalsListDocument, options);
}
export type WithdrawalsListQueryHookResult = ReturnType<
  typeof useWithdrawalsListQuery
>;
export type WithdrawalsListLazyQueryHookResult = ReturnType<
  typeof useWithdrawalsListLazyQuery
>;
export type WithdrawalsListSuspenseQueryHookResult = ReturnType<
  typeof useWithdrawalsListSuspenseQuery
>;
export type WithdrawalsListQueryResult = Apollo.QueryResult<
  WithdrawalsListQuery,
  WithdrawalsListQueryVariables
>;
export const WithdrawalRequestDocument = gql`
  mutation withdrawalRequest($input: WithdrawalOrganizerInput!) {
    requestWithdrawal(input: $input)
  }
`;
export type WithdrawalRequestMutationFn = Apollo.MutationFunction<
  WithdrawalRequestMutation,
  WithdrawalRequestMutationVariables
>;

/**
 * __useWithdrawalRequestMutation__
 *
 * To run a mutation, you first call `useWithdrawalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawalRequestMutation, { data, loading, error }] = useWithdrawalRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawalRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawalRequestMutation,
    WithdrawalRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    WithdrawalRequestMutation,
    WithdrawalRequestMutationVariables
  >(WithdrawalRequestDocument, options);
}
export type WithdrawalRequestMutationHookResult = ReturnType<
  typeof useWithdrawalRequestMutation
>;
export type WithdrawalRequestMutationResult =
  Apollo.MutationResult<WithdrawalRequestMutation>;
export type WithdrawalRequestMutationOptions = Apollo.BaseMutationOptions<
  WithdrawalRequestMutation,
  WithdrawalRequestMutationVariables
>;
export const CancelWithdrawalDocument = gql`
  mutation cancelWithdrawal($idOrganizer: Int!, $uuid: UUID!) {
    cancelWithdrawal(idOrganizer: $idOrganizer, uuid: $uuid)
  }
`;
export type CancelWithdrawalMutationFn = Apollo.MutationFunction<
  CancelWithdrawalMutation,
  CancelWithdrawalMutationVariables
>;

/**
 * __useCancelWithdrawalMutation__
 *
 * To run a mutation, you first call `useCancelWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWithdrawalMutation, { data, loading, error }] = useCancelWithdrawalMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCancelWithdrawalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelWithdrawalMutation,
    CancelWithdrawalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CancelWithdrawalMutation,
    CancelWithdrawalMutationVariables
  >(CancelWithdrawalDocument, options);
}
export type CancelWithdrawalMutationHookResult = ReturnType<
  typeof useCancelWithdrawalMutation
>;
export type CancelWithdrawalMutationResult =
  Apollo.MutationResult<CancelWithdrawalMutation>;
export type CancelWithdrawalMutationOptions = Apollo.BaseMutationOptions<
  CancelWithdrawalMutation,
  CancelWithdrawalMutationVariables
>;
export const namedOperations = {
  Fragment: {
    BaseTariffFields: 'BaseTariffFields',
    JwtFields: 'JwtFields',
    PaymentAccountFields: 'PaymentAccountFields',
    SubTariffFields: 'SubTariffFields',
    UserGroupFields: 'UserGroupFields',
    UserListFields: 'UserListFields',
    WithdrawalFields: 'WithdrawalFields',
  },
  Mutation: {
    addGroupUsers: 'addGroupUsers',
    addUserToGroup: 'addUserToGroup',
    addUserToSpecial: 'addUserToSpecial',
    archiveEvent: 'archiveEvent',
    blockUser: 'blockUser',
    cancelWithdrawal: 'cancelWithdrawal',
    createBaseTariff: 'createBaseTariff',
    createEvent: 'createEvent',
    createInvitesOrders: 'createInvitesOrders',
    createTariff: 'createTariff',
    disableOrder: 'disableOrder',
    duplicateBaseTariff: 'duplicateBaseTariff',
    duplicateProduct: 'duplicateProduct',
    exportFinanceCSV: 'exportFinanceCSV',
    exportOrdersCSV: 'exportOrdersCSV',
    giveOrders: 'giveOrders',
    login: 'login',
    publishEvent: 'publishEvent',
    refresh: 'refresh',
    removeBaseTariff: 'removeBaseTariff',
    removeEvent: 'removeEvent',
    removeGroupUsers: 'removeGroupUsers',
    removeTariff: 'removeTariff',
    removeUserFromGroup: 'removeUserFromGroup',
    removeUserFromSpecial: 'removeUserFromSpecial',
    unblockUser: 'unblockUser',
    updateBaseTariff: 'updateBaseTariff',
    updateEvent: 'updateEvent',
    updateEventInviteTexts: 'updateEventInviteTexts',
    updateGroupUsersName: 'updateGroupUsersName',
    updateOrganizer: 'updateOrganizer',
    updateProductTariffs: 'updateProductTariffs',
    updateTariff: 'updateTariff',
    updateUser: 'updateUser',
    updateUsersBaseTariff: 'updateUsersBaseTariff',
    updateUsersInGroup: 'updateUsersInGroup',
    uploadImages: 'uploadImages',
    withdrawalRequest: 'withdrawalRequest',
  },
  Query: {
    User: 'User',
    allAllowedUsersForBaseTariff: 'allAllowedUsersForBaseTariff',
    baseEventTexts: 'baseEventTexts',
    baseTariff: 'baseTariff',
    baseTariffsList: 'baseTariffsList',
    event: 'event',
    eventInviteTexts: 'eventInviteTexts',
    eventShortcode: 'eventShortcode',
    eventsCount: 'eventsCount',
    eventsList: 'eventsList',
    financeListsCount: 'financeListsCount',
    financeStats: 'financeStats',
    me: 'me',
    ordersCount: 'ordersCount',
    ordersList: 'ordersList',
    organizer: 'organizer',
    organizerFiles: 'organizerFiles',
    organizerShortcodes: 'organizerShortcodes',
    paymentAccountsList: 'paymentAccountsList',
    paymentsList: 'paymentsList',
    purchaseStats: 'purchaseStats',
    subTariff: 'subTariff',
    subTariffsList: 'subTariffsList',
    userGroups: 'userGroups',
    usersBlockedCount: 'usersBlockedCount',
    usersBlockedList: 'usersBlockedList',
    usersByGroupList: 'usersByGroupList',
    usersList: 'usersList',
    usersRegisteredCount: 'usersRegisteredCount',
    usersRegisteredList: 'usersRegisteredList',
    usersSpecialCount: 'usersSpecialCount',
    usersSpecialList: 'usersSpecialList',
    usersStats: 'usersStats',
    withdrawalsList: 'withdrawalsList',
  },
};

export interface PossibleTypesResultData {
  possibleTypes: Record<string, string[]>;
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BaseGraphModel: [
      'BaseTariffType',
      'OrderInviteType',
      'OrderType',
      'OrganizerType',
      'PaymentAccountType',
      'PaymentType',
      'ProductType',
      'SubTariffType',
      'TariffType',
      'UserBalanceDepositType',
      'UserBalanceType',
      'UserBalanceWithdrawalType',
      'UserGroupType',
      'UserSpecialType',
      'UserType',
      'WithdrawalType',
    ],
    NodeIdType: ['OrderType', 'OrganizerType'],
    NodeUuidType: [
      'BaseTariffType',
      'OrderInviteType',
      'PaymentAccountType',
      'PaymentType',
      'ProductType',
      'SubTariffType',
      'TariffType',
      'UserBalanceDepositType',
      'UserBalanceType',
      'UserBalanceWithdrawalType',
      'UserGroupType',
      'UserSpecialType',
      'UserType',
      'WithdrawalType',
    ],
  },
};

export default result;
