import React, { FC } from 'react';

import { ArrowLeftIcon, Button, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface HeadProps {
  title: string | JSX.Element;
  onBack?(): void;
  rightSlot?: JSX.Element;
}
export const Head: FC<HeadProps> = ({ onBack, rightSlot, title }) => (
  <div className={styles.head}>
    <div className={styles.head__left}>
      {onBack && (
        <Button
          icon
          prependIcon={ArrowLeftIcon}
          onClick={onBack}
          color={'secondary'}
        />
      )}

      <div>
        <Typography
          className={styles.head__title}
          variant="head-32"
          color="on-surface-primary-1">
          {title}
        </Typography>
      </div>
    </div>

    {rightSlot && <div className={styles.head__right}>{rightSlot}</div>}
  </div>
);
