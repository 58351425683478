import { useEffect } from 'react';

import {
  UpdateUsersBaseTariffMutation,
  UpdateUsersBaseTariffMutationVariables,
  useUpdateUsersBaseTariffMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

export const useAccessTariffUsers = (): [
  (vars: UpdateUsersBaseTariffMutationVariables) => Promise<void>,
  MutationResult<UpdateUsersBaseTariffMutation>,
] => {
  const [updateUsersTariff, result] = useUpdateUsersBaseTariffMutation();

  const onUpdateUsersTariff = async (
    variables: UpdateUsersBaseTariffMutationVariables,
  ) => {
    await updateUsersTariff({
      variables,
    });
    result.client.cache.evict({ fieldName: 'allAllowedUsersForTariff' });
  };

  useEffect(() => {
    if (result.error) {
      toaster.error({
        title: 'Ошибка обновления доступов пользователей к тарифу',
      });
    }
  }, [result]);

  return [onUpdateUsersTariff, result];
};
