import { FC, useEffect, useMemo, useState } from 'react';

import {
  Maybe,
  OrderType,
  PaymentsListQuery,
  PaymentSortKeys,
  PaymentStatusEnum,
  usePaymentsListQuery,
} from '@/apolloGenerated';
import {
  calculateProfit,
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { OrderEventInfoCell, PaymentStatusCell } from '@entities/Purchases';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

const headers = [
  { hide: true, key: true, value: 'id' },
  { sortable: true, title: 'Дата', value: 'createdAt', width: 160 },
  {
    key: true,
    sortable: true,
    title: 'Операция',
    value: 'operation',
    width: 240,
  },
  { title: 'Сведения', value: 'info' },
  { title: 'Оборот, ₽', value: 'turnover' },
  { title: 'Комиссия, ₽', value: 'commission' },
  { title: 'Прибыль, ₽ ', value: 'profit' },
  { title: 'Статус', value: 'status' },
];
const pageSize = 10;
const defaultPage = 1;

export interface UserPaymentsTableProps {
  uuidUser: string;
}
export const UserPaymentsTable: FC<UserPaymentsTableProps> = ({ uuidUser }) => {
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(defaultPage);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<PaymentSortKeys>(
    PaymentSortKeys.CreatedAt,
  );

  useEffect(() => {
    setPage(defaultPage);
  }, [uuidUser]);

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = usePaymentsListQuery({
    skip: !organizerId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      organizerId,
      status: [
        PaymentStatusEnum.Success,
        PaymentStatusEnum.Overdue,
        PaymentStatusEnum.Failed,
      ],
      uuidUser,
    },
  });
  const data = curData || previousData;

  const rowTemplate = ({
    createdAt,
    order,
    payment_uuid,
    price,
    status,
  }: PaymentsListQuery['paymentsList']['rows'][0]) => {
    const { commission, profit, turnover } = calculateProfit(
      price,
      order.fixedHoldPercentage,
    );
    const isSuccess = status === PaymentStatusEnum.Success;

    return {
      commission: isSuccess ? formatAmount(commission) : '-',
      createdAt: renderCellDate({ time: true, timestamp: createdAt }),
      info: <OrderEventInfoCell order={order as OrderType} showPair />,
      operation: renderCellItemValue({
        label: `ID: ${order.id}`,
        value: payment_uuid,
      }),
      profit: isSuccess ? formatAmount(profit) : '-',
      status: <PaymentStatusCell status={status} />,
      turnover: isSuccess ? formatAmount(turnover) : '-',
    };
  };
  const items = useMemo(
    () => (data?.paymentsList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        total={data?.paymentsList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as PaymentSortKeys);
        }}
        onRefetch={error && refetch}
        notResetPage
      />
    </div>
  );
};
