import React, { FC, useState } from 'react';

import { UserType } from '@/apolloGenerated';
import { Container, FloatDriver } from '@/shared';
import {
  ContactListTabs,
  ContactTabs,
  ContactTabsEnum,
  EditedGroup,
} from '@entities/Contact';
import {
  ContactTable,
  ContactTableHeader,
  useUpdateUsersGroup,
} from '@features/Contact';
import { useUsersList } from '@features/Contact/libs/hooks/useUsersList';
import { Divider, Maybe, Spacer } from '@letsdance/ui-kit';
import { ContactGroupEdit, GroupSettings } from '@widgets/Contact';

export const ContactLists: FC = () => {
  const [tab, setTab] = useState<ContactTabs>(ContactTabsEnum.All);
  const [editedGroup, setEditedGroup] = useState<Maybe<EditedGroup>>(null);

  const { data, error, loading, onUpdatePayload, payload, refetch } =
    useUsersList({
      tab,
    });

  const [updateGroup] = useUpdateUsersGroup();

  const onSave = (e: GroupSettings) => {
    updateGroup(
      { ...e, uuid: editedGroup?.uuid },
      { updateName: e.name !== editedGroup?.name },
    );
    setEditedGroup(null);
  };

  return (
    <>
      <Container>
        <ContactListTabs onChange={setTab} onEditGroup={setEditedGroup} />
      </Container>
      <Divider />
      <Spacer size={12} />
      <Container>
        <ContactTable
          hasError={!!error}
          tab={tab}
          data={(data?.rows as UserType[]) || []}
          headers={[
            ContactTableHeader.Icon,
            ContactTableHeader.CreatedAt,
            ContactTableHeader.Name,
            ContactTableHeader.Data,
            ContactTableHeader.Orders,
            ContactTableHeader.Visited,
            ContactTableHeader.Receipts,
            ContactTableHeader.BonusAmount,
            ContactTableHeader.Settings,
          ]}
          loading={loading}
          total={data?.count || 0}
          payload={payload}
          clickedRow
          onChangePayload={onUpdatePayload}
          onRefetch={error && refetch}
        />
      </Container>
      {editedGroup && (
        <FloatDriver
          isOpen={!!editedGroup}
          onClose={() => setEditedGroup(null)}>
          <ContactGroupEdit
            onClose={() => setEditedGroup(null)}
            onSave={onSave}
            curName={editedGroup.name}
            uuid={editedGroup.uuid}
          />
        </FloatDriver>
      )}
    </>
  );
};
