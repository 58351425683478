import { useEffect, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  CreateEventMutation,
  CreateEventMutationVariables,
  useCreateEventMutation,
  useUpdateProductTariffsMutation,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { MutationResult } from '@apollo/client';
import { RouterName } from '@app/router';
import { getEventInput } from '@entities/Event/libs/hooks/getEventInput';
import { toaster } from '@letsdance/ui-kit';

export const useCreateEvent = (): [
  (
    vars: Pick<CreateEventMutationVariables, 'input' | 'publish'>,
  ) => Promise<void>,
  MutationResult<CreateEventMutation>,
  { setTariffsUuids(tariffs: string[]): void; tariffsUuids: string[] },
] => {
  const [tariffsUuids, setTariffsUuids] = useState<string[]>([]);
  const { navigate } = useNavigate();
  const organizerId = useOrganizerId()!;
  const [createEvent, result] = useCreateEventMutation();
  const [addTariffs] = useUpdateProductTariffsMutation();

  const onCreateEvent = async ({
    input,
    publish,
  }: Pick<CreateEventMutationVariables, 'input' | 'publish'>) => {
    if (tariffsUuids.length === 0 && publish) {
      toaster.error({
        title: 'Для публикации события необходимо добавить хотя бы один тариф',
      });

      return;
    }
    createEvent({
      variables: {
        input: getEventInput(input),
        organizerId,
        publish,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      if (tariffsUuids.length > 0) {
        addTariffs({
          variables: {
            productUuid: result.data.createProduct.uuid,
            uuids: tariffsUuids,
          },
        });
      }
      const { client, data } = result;

      client.cache.evict({ fieldName: 'productsList' });
      client.cache.evict({ fieldName: 'productsCount' });
      navigate(RouterName.AdminEventEdit, {
        uuid: data.createProduct.uuid,
      });
      toaster.success({ title: 'Событие успешно создано' });
    }
  }, [result.data, result.error]);

  return [onCreateEvent, result, { setTariffsUuids, tariffsUuids }];
};
