import React, { FC, useEffect, useState } from 'react';

import {
  StatusEnum,
  TariffTypeEnum,
  useGiveOrdersMutation,
} from '@/apolloGenerated';
import { ActionButtons, declension, FormSection } from '@/shared';
import { ContactOrderInviteSelect } from '@features/Contact';
import { EventPicker } from '@features/Event';
import { TariffPicker } from '@features/Tariff';
import { Maybe, Spacer, Switch, toaster, Typography } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface GiveOrderUsersProps {
  productUuid?: Maybe<string>;
  onCancel(): void;
}
export const GiveOrderUsers: FC<GiveOrderUsersProps> = ({
  onCancel,
  productUuid: initProductUuid,
}) => {
  const [sendEmailNotify, setSendEmailNotify] = useState<boolean>(true);
  const [sendBotNotify, setSendBotNotify] = useState<boolean>(true);
  const [productUuid, setProductUuid] = useState<Maybe<string>>(
    initProductUuid || null,
  );
  const [tariffUuid, setTariffUuid] = useState<Maybe<string>>(null);
  const [usersUuids, setUsersUuids] = useState<string[]>([]);
  const isDisabled = !productUuid || !tariffUuid || usersUuids.length === 0;
  const [giveOrders, { client, data }] = useGiveOrdersMutation();

  const handleSubmit = () => {
    if (!tariffUuid || !productUuid || usersUuids.length === 0) {
      return;
    }
    giveOrders({
      variables: {
        params: {
          sendNotifyToBot: sendBotNotify,
          sendNotifyToEmail: sendEmailNotify,
        },
        productUuid,
        tariffUuid,
        usersUuids,
      },
    });
  };

  useEffect(() => {
    if (data) {
      const hasErrors = data.giveOrders.some((el) => !el.status);

      if (hasErrors) {
        for (const el of data.giveOrders) {
          if (!el.status) {
            toaster.error({
              contentSlot: (
                <Typography variant="body-16">{el.error}</Typography>
              ),
              title: `Ошибка создантя билета для пользователья ${el.uuidUser}`,
            });
          }
        }
      } else {
        toaster.success({ title: 'Билеты успешно отправлены' });
      }
      client.cache.evict({ fieldName: 'specialUsers' });
      client.cache.evict({ fieldName: 'users' });
      onCancel();
    }
  }, [data]);

  return (
    <div>
      <Head title="Оформить билет" />
      <FormSection title="Выбрать мероприятие">
        <EventPicker
          initValue={productUuid}
          onChange={setProductUuid}
          status={[StatusEnum.Active]}
          autoSetFirst
          hideAll
        />
      </FormSection>
      <FormSection title="Выбрать тариф">
        {!!productUuid && (
          <TariffPicker
            onChange={setTariffUuid}
            productUuid={productUuid}
            status={[StatusEnum.Active, StatusEnum.Draft]}
            hideAll
            autoSetFirst
            hideTariffType={[TariffTypeEnum.Pair]}
          />
        )}
      </FormSection>
      <FormSection title="Уведомления">
        <Switch
          label="Отправлять уведомление на почту"
          checked={sendEmailNotify}
          onChange={(e) => setSendEmailNotify(e.target.checked)}
        />
        <Spacer size={10} />
        <Switch
          label="Отправлять уведомление в бота"
          checked={sendBotNotify}
          onChange={(e) => setSendBotNotify(e.target.checked)}
        />
      </FormSection>

      <FormSection title={`Список получателей (${usersUuids.length})`}>
        <ContactOrderInviteSelect
          tariffUuid={tariffUuid}
          productUuid={productUuid}
          onChange={setUsersUuids}
          requiredRegistration
        />
      </FormSection>
      <ActionButtons
        actions={[
          { handler: onCancel, label: 'Отменить', size: 'large' },
          {
            color: 'primary',
            disabled: isDisabled,
            handler: handleSubmit,
            label: `Оформить ${usersUuids.length} ${declension(usersUuids.length, ['билет', 'билета', 'билетов'])}`,
            size: 'large',
          },
        ]}
        hideDivider
      />
    </div>
  );
};
