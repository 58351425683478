import { gql } from '@apollo/client';

export const user = gql`
  query User($uuid: UUID!, $organizerId: Float!) {
    user(uuid: $uuid) {
      createdAt
      updatedAt
      deletedAt
      uuid
      tgId
      username
      first_name
      last_name
      language
      role
      email
      phone
      ordersCount
      activatedOrdersCount
      receipts
      gender
      balance(idOrganizer: $organizerId)
      isSpecial(idOrganizer: $organizerId)
      isBlocked(organizerId: $organizerId)
    }
  }
`;

export const usersStats = gql`
  query usersStats($organizerId: Float, $isActive: Boolean) {
    usersStats(
      params: { organizerId: $organizerId }
      args: { isActive: $isActive }
    ) {
      count
      maleCount
      femaleCount
    }
  }
`;
export const updateUser = gql`
  mutation updateUser($uuid: UUID!, $input: UpdateUserInput!) {
    updateUser(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;
