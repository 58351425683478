import React, { FC, useEffect } from 'react';
import moment from 'moment-timezone';

import {
  PreviewProductInput,
  ProductFeatureFlagsInput,
  ProductInput,
  ProductType,
  RouteProductInput,
  useEventShortcodeQuery,
} from '@/apolloGenerated';
import {
  DeeplinkCopy,
  FormSection,
  handleNumberInput,
  handleQueryInput,
  TextareaField,
  timezone,
  useImageUploader,
} from '@/shared';
import {
  DateTimePicker,
  ImageUploader,
  Maybe,
  NestedKeys,
  PeriodTextField,
  Spacer,
  Switch,
  TextField,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventFormProps {
  initValues: ProductType;
  onChange(val: ProductInput): void;
  errors?: Record<NestedKeys<ProductInput>, string[]>;
  readonly?: boolean;
  tariffsSlot?: JSX.Element | false;
}
const getInfoForBeforeStart = (
  startedDate?: Maybe<string>,
  duration?: Maybe<string>,
) =>
  startedDate && duration
    ? ` (до ${moment(startedDate).tz(timezone).subtract(duration).format('DD.MM.YY HH:mm')})`
    : '';

export const EventForm: FC<EventFormProps> = ({
  errors,
  initValues,
  onChange,
  readonly,
  tariffsSlot,
}) => {
  const { data: shortcodes } = useEventShortcodeQuery();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const uploadImage = useImageUploader();
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<ProductInput>(console.log, initValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updateFeatureFlags = function <
    T extends keyof ProductFeatureFlagsInput,
  >(key: T, val: ProductFeatureFlagsInput[T]) {
    setBatchValues({
      featureFlags: {
        ...values.featureFlags,
        [key]: val,
      },
    });
  };
  // const updatePreview = function <T extends keyof PreviewProductInput>(
  //   key: T,
  //   value: PreviewProductInput[T],
  // ) {
  //   setBatchValues({
  //     preview: {
  //       ...values.preview,
  //       [key]: value,
  //     },
  //   });
  // };
  const updateRoute = function <T extends keyof RouteProductInput>(
    key: T,
    value: PreviewProductInput[T],
  ) {
    setBatchValues({
      route: {
        ...values.route,
        [key]: value,
      },
    });
  };

  return (
    <div className={styles.eventForm}>
      <FormSection title="Основная информация">
        <div className={styles.eventForm__mainInfo}>
          <ImageUploader
            initValue={values.img}
            onChange={(val) => setBatchValues({ img: val })}
            disabled={readonly}
            error={errors?.img}
            uploadImageCallback={uploadImage}
            size={300}
          />
          <div>
            <TextField
              label="Название"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.name}
              name="name"
              initValue={values.name}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <div className="grid-2">
              <DateTimePicker
                initValue={new Date(values.startedDate)}
                label={`Дата начала вечеринки (${timeZone})`}
                fullWidth
                size="large"
                onChange={(val) =>
                  setBatchValues({ startedDate: val?.toISOString() })
                }
                error={errors?.startedDate}
                disabled={readonly}
                minDate={new Date()}
              />
              <DateTimePicker
                initValue={new Date(values.endDate)}
                label={`Дата окончания вечеринки (${timeZone})`}
                fullWidth
                size="large"
                onChange={(val) =>
                  setBatchValues({ endDate: val?.toISOString() })
                }
                error={errors?.endDate}
                disabled={readonly || !values?.startedDate}
                minDate={new Date()}
              />
            </div>
            <Spacer size={10} />
            <TextField
              label="Место проведения"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.address}
              name="address"
              initValue={values.address!}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <TextField
              label="Ключ диплинка (латиница)"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.query}
              name="query"
              initValue={values.query!}
              onKeyDown={handleQueryInput}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <DeeplinkCopy to="event" value={values.query} />
          </div>
        </div>
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Описание"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.description}
          name="description"
          initValue={values.description}
          onChange={onChangeValue}
        />
      </FormSection>
      <FormSection title="Способ оплаты">
        <div className="grid-2">
          <DateTimePicker
            initValue={
              values.salesStartedDate ? new Date(values.salesStartedDate) : null
            }
            label={`Дата старта продаж (${timeZone})`}
            fullWidth
            size="large"
            onChange={(val) =>
              setBatchValues({
                salesStartedDate: val ? val?.toISOString() : null,
              })
            }
            error={errors?.startedDate}
            disabled={readonly}
            minDate={new Date()}
            clearable
          />
          <PeriodTextField
            label="Минут на оплату билета"
            size="large"
            disabled={readonly || !values.activeAcquiring}
            fullWidth
            error={errors?.invoiceLifetime}
            name="invoiceLifetime"
            initValue={values.invoiceLifetime!}
            onChange={(e) =>
              setBatchValues({ invoiceLifetime: e.target.value })
            }
            period="minute"
          />
        </div>
        <Spacer size={10} />
        <TextareaField
          label="Текст до старта продаж"
          disabled={readonly}
          error={errors?.salesStartedText}
          name="salesStartedText"
          initValue={values.salesStartedText!}
          onChange={onChangeValue}
          shortcodes={shortcodes?.productShortcodes}
        />
        {/*<RadioButtons*/}
        {/*  name="activeAcquiring"*/}
        {/*  checked={values.activeAcquiring}*/}
        {/*  label="Онлайн-платеж по реквизитам, указанным в профиле"*/}
        {/*  onChange={onChangeValue}*/}
        {/*  disabled={readonly}*/}
        {/*/>*/}
        {/*<Spacer size={6} />*/}
        {/*<Spacer size={10} />*/}
        {/*<RadioButtons*/}
        {/*  name="activeAcquiring"*/}
        {/*  checked={!values.activeAcquiring}*/}
        {/*  label="Оплата по инструкции"*/}
        {/*  onChange={(e) => {*/}
        {/*    setBatchValues({ activeAcquiring: !e.target.checked });*/}
        {/*  }}*/}
        {/*  disabled={readonly}*/}
        {/*/>*/}
        {/*<Spacer size={6} />*/}
        {/*<TextField*/}
        {/*  tag="textarea"*/}
        {/*  label="Инструкция"*/}
        {/*  size="large"*/}
        {/*  style={{ height: 200 }}*/}
        {/*  disabled={readonly || values.activeAcquiring}*/}
        {/*  fullWidth*/}
        {/*  error={errors?.manualBuyText}*/}
        {/*  name="manualBuyText"*/}
        {/*  initValue={values.manualBuyText!}*/}
        {/*  onChange={onChangeValue}*/}
        {/*/>*/}
      </FormSection>
      {/*<FormSection title="Превью">*/}
      {/*  <Switch*/}
      {/*    label="Активно"*/}
      {/*    checked={values.preview.active}*/}
      {/*    disabled={readonly}*/}
      {/*    onChange={(e) => updatePreview('active', e.target.checked)}*/}
      {/*  />*/}
      {/*  {values.preview.active && (*/}
      {/*    <>*/}
      {/*      <Spacer size={10} />*/}
      {/*      <div className={styles.eventForm__secondaryInfo}>*/}
      {/*        <ImageUploader*/}
      {/*          initValue={values.preview.url}*/}
      {/*          onChange={(val) => updatePreview('url', val)}*/}
      {/*          error={errors?.['preview.url']}*/}
      {/*          disabled={readonly}*/}
      {/*          uploadImageCallback={uploadImage}*/}
      {/*        />*/}
      {/*        <div>*/}
      {/*          <TextField*/}
      {/*            label="Текст кнопки назад"*/}
      {/*            size="large"*/}
      {/*            disabled={readonly}*/}
      {/*            fullWidth*/}
      {/*            error={errors?.['preview.backButtonText']}*/}
      {/*            name="back_button_text"*/}
      {/*            initValue={values.preview.backButtonText!}*/}
      {/*            onChange={(e) =>*/}
      {/*              updatePreview('backButtonText', e.target.value)*/}
      {/*            }*/}
      {/*          />*/}
      {/*          <Spacer size={10} />*/}
      {/*          <TextField*/}
      {/*            tag="textarea"*/}
      {/*            label="Описание"*/}
      {/*            size="large"*/}
      {/*            style={{ height: 190 }}*/}
      {/*            disabled={readonly}*/}
      {/*            fullWidth*/}
      {/*            name="description"*/}
      {/*            error={errors?.['preview.description']}*/}
      {/*            initValue={values.preview.description!}*/}
      {/*            onChange={(e) => updatePreview('description', e.target.value)}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</FormSection>*/}
      <FormSection title="Как добраться?">
        <Switch
          label="Активно"
          checked={values.route.active}
          disabled={readonly}
          onChange={(e) => updateRoute('active', e.target.checked)}
        />
        {values.route.active && (
          <>
            <Spacer size={10} />
            <div className={styles.eventForm__secondaryInfo}>
              <ImageUploader
                initValue={values.route.url}
                onChange={(val) => updateRoute('url', val)}
                error={errors?.['route.url']}
                disabled={readonly}
                uploadImageCallback={uploadImage}
              />
              <div>
                <TextField
                  label="Текст кнопки назад"
                  size="large"
                  disabled={readonly}
                  fullWidth
                  error={errors?.['route.backButtonText']}
                  name="back_button_text"
                  initValue={values.route.backButtonText!}
                  onChange={(e) =>
                    updateRoute('backButtonText', e.target.value)
                  }
                />
                <Spacer size={10} />
                <TextField
                  tag="textarea"
                  label="Описание"
                  size="large"
                  style={{ height: 190 }}
                  disabled={readonly}
                  fullWidth
                  name="description"
                  error={errors?.['route.description']}
                  initValue={values.route.description!}
                  onChange={(e) => updateRoute('description', e.target.value)}
                />
              </div>
            </div>
          </>
        )}
      </FormSection>
      {tariffsSlot && <FormSection title="Тарифы">{tariffsSlot}</FormSection>}
      <FormSection title="Фичи">
        <div className="grid-2">
          <div>
            <PeriodTextField
              label={
                'Отключение переноса в часах до начала' +
                getInfoForBeforeStart(
                  values.startedDate,
                  values.featureFlags.durationBeforeStartForTransferToUserOrder,
                )
              }
              size="large"
              disabled={readonly}
              fullWidth
              error={
                errors?.[
                  'featureFlags.durationBeforeStartForTransferToUserOrder'
                ]
              }
              name="durationBeforeStartForTransferToUserOrder"
              initValue={
                values.featureFlags.durationBeforeStartForTransferToUserOrder!
              }
              onChange={(e) =>
                updateFeatureFlags(
                  'durationBeforeStartForTransferToUserOrder',
                  e.target.value,
                )
              }
              period="hour"
            />
            <Spacer size={10} />
            <PeriodTextField
              label={
                'Отключение возврата в часах до начала' +
                getInfoForBeforeStart(
                  values.startedDate,
                  values.featureFlags.durationBeforeStartForRefundOrder,
                )
              }
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.['featureFlags.durationBeforeStartForRefundOrder']}
              name="durationBeforeStartForRefundOrder"
              initValue={values.featureFlags.durationBeforeStartForRefundOrder!}
              onChange={(e) =>
                updateFeatureFlags(
                  'durationBeforeStartForRefundOrder',
                  e.target.value,
                )
              }
              period="hour"
            />
          </div>
          <div>
            <TextField
              label="Разрешенное кол-во переносов билета"
              size="large"
              disabled={readonly}
              fullWidth
              error={
                errors?.['featureFlags.allowedNumberForTransferToProductOrder']
              }
              onKeyPress={handleNumberInput}
              name="allowedNumberForTransferToProductOrder"
              initValue={String(
                values.featureFlags.allowedNumberForTransferToProductOrder,
              )}
              onChange={(e) =>
                updateFeatureFlags(
                  'allowedNumberForTransferToProductOrder',
                  +e.target.value || 0,
                )
              }
            />
          </div>
        </div>
      </FormSection>
      <FormSection title="Тексты">
        <TextareaField
          label="FAQ"
          disabled={readonly}
          error={errors?.faq}
          name="faq"
          initValue={values.faq!}
          onChange={onChangeValue}
          shortcodes={shortcodes?.productShortcodes}
        />
        <Spacer size={10} />
        <TextareaField
          label="Текст успешной покупки"
          disabled={readonly}
          error={errors?.successBuyText}
          name="successBuyText"
          initValue={values.successBuyText!}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <TextareaField
          label="Текст успешной передачи билета"
          disabled={readonly}
          error={errors?.transferOrderToUserSuccessText}
          name="transferOrderToUserSuccessText"
          initValue={values.transferOrderToUserSuccessText!}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <TextareaField
          label="Глобальный текст тарифов"
          disabled={readonly}
          error={errors?.tariffsText}
          name="tariffsText"
          initValue={values.tariffsText!}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <TextareaField
          label="Текст при отсутствии активных тарифов"
          disabled={readonly}
          error={errors?.tariffsEmptyText}
          name="tariffsEmptyText"
          initValue={values.tariffsEmptyText!}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <TextareaField
          label="Текст при отсутствии доступных билетов у тарифов"
          disabled={readonly}
          error={errors?.tariffsUnavailableText}
          name="tariffsUnavailableText"
          initValue={values.tariffsUnavailableText!}
          onChange={onChangeValue}
        />
      </FormSection>
    </div>
  );
};
