import React, { FC, useEffect } from 'react';

import { useDisableOrderMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface DisableOrderModalProps {
  id: number;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    disableOrder: DisableOrderModalProps;
  }
}
export const DisableOrderModal: FC<WithCloseModal<DisableOrderModalProps>> = ({
  id,
  onClose,
  onSuccess,
}): JSX.Element => {
  const [disableOrder, { client, data, error, loading }] =
    useDisableOrderMutation();

  const handleSubmit = () => {
    disableOrder({ variables: { id: +id } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Билет успешно деактивирован',
      });
      client.cache.evict({ fieldName: 'organizer' });
      client.cache.evict({ fieldName: 'ordersList' });
      client.cache.evict({ fieldName: 'purchasesStats' });
      client.cache.evict({ fieldName: 'financeStats' });
      client.cache.evict({ fieldName: 'paymentsList' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка деактивации. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Деактивировать билет?"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button fullWidth onClick={handleSubmit} disabled={loading}>
                Подтвердить
              </Button>
            </div>
          }
        />
      }>
      <Typography variant="body-16" color="on-surface-primary-1">
        После деактивации бонусные баллы будут возвращены на баланс
        пользователя, если они использовались при оформлении билета
      </Typography>
    </Dialog>
  );
};
